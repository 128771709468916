import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import {CircleMarker, GeoJSON as GeoJSONLayer, Map as LeafletMap} from 'react-leaflet'
import styled from 'styled-components'
import area from '@turf/area'

import BingBasemap from './BingBasemap'

const MapContainer = styled.div`
  display: flex;
  height: 100%;

  #itemLocationMap {
    width: 100%;
    border: 1px solid #2980b9;
    margin: 20px;
  }
`

const MINIMUM_ALLOWED_AREA = 30000

function ItemLocationMap(props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [mapProps, setMapProps] = useState({latLngBounds: null})
  const [itemGeometry, setItemGeometry] = useState(<></>)
  const {geometry} = props
  const previewLocationMapRef = useRef()

  useEffect(() => {
    let zoom = 5
    const {type, coordinates} = geometry

    if (type.toLocaleLowerCase() === 'multipolygon') {
      const multiPolygonCenters = []
      let mpLat = 0
      let mpLng = 0

      let globalMaxLat
      let globalMaxLng
      let globalMinLat
      let globalMinLng

      for (let i = 0; i < coordinates[0].length; i++) {
        let maxLat = coordinates[0][i][1]
        let minLat = coordinates[0][i][1]
        let maxLong = coordinates[0][i][0]
        let minLong = coordinates[0][i][0]

        for (let z = 0; z < coordinates[0][i].length; z++) {
          const [x, y] = coordinates[0][i][z]
          if (isNaN(minLat)) {
            minLong = x
            maxLong = x
            minLat = y
            maxLat = y
          }
          if (x < minLong) minLong = x
          if (x > maxLong) maxLong = x
          if (y < minLat) minLat = y
          if (y > maxLat) maxLat = y
        }

        const singlePolygonCenter = [(minLong + maxLong) / 2, (minLat + maxLat) / 2]
        multiPolygonCenters.push(singlePolygonCenter)
        if (
          isNaN(globalMaxLat) &&
          isNaN(globalMaxLng) &&
          isNaN(globalMinLat) &&
          isNaN(globalMinLng)
        ) {
          globalMaxLat = maxLat
          globalMaxLng = maxLong
          globalMinLat = minLat
          globalMinLng = minLong
        } else {
          if (minLong < globalMinLng) globalMinLng = minLong
          if (maxLong > globalMaxLng) globalMaxLng = maxLong
          if (minLat < globalMinLat) globalMinLat = minLat
          if (maxLat > globalMinLng) globalMinLng = maxLat
        }
      }

      for (let m = 0; m < multiPolygonCenters.length; m++) {
        mpLat += multiPolygonCenters[m][1]
        mpLng += multiPolygonCenters[m][0]
      }
      const center = [mpLat / multiPolygonCenters.length, mpLng / multiPolygonCenters.length]
      if (Math.abs(center[0]) > 90) zoom = 1

      if (area(geometry) > MINIMUM_ALLOWED_AREA) {
        setItemGeometry(<GeoJSONLayer color="#2980b9" data={geometry} />)
        setMapProps({
          center,
          zoom,
          latLngBounds: [[globalMinLat, globalMinLng], [globalMaxLat, globalMaxLng]],
        })
      } else {
        if (Math.abs(center[0]) > 90) zoom = 1

        setItemGeometry(<CircleMarker color="#2980b9" radius="5" center={center} />)

        setMapProps({
          center,
          zoom,
          latLngBounds: null,
        })
      }

      setLoading(false)
    } else if (type.toLocaleLowerCase() === 'polygon') {
      let maxLong = coordinates[0][0]
      let minLong = coordinates[0][0]
      let maxLat = coordinates[0][1]
      let minLat = coordinates[0][1]

      for (let i = 0; i < coordinates[0].length; i++) {
        const [y, x] = coordinates[0][i]

        if (isNaN(minLat)) {
          minLong = x
          maxLong = x
          minLat = y
          maxLat = y
        }

        if (x < minLong) minLong = x
        if (x > maxLong) maxLong = x
        if (y < minLat) minLat = y
        if (y > maxLat) maxLat = y
      }

      const center = [(minLong + maxLong) / 2, (minLat + maxLat) / 2]
      if (Math.abs(center[0]) > 90) zoom = 1

      if (area(geometry) > MINIMUM_ALLOWED_AREA) {
        setItemGeometry(<GeoJSONLayer color="#2980b9" data={geometry} />)

        setMapProps({
          center,
          zoom,
          latLngBounds: [[minLong, minLat], [maxLong, maxLat]],
        })
      } else {
        if (Math.abs(center[0]) > 90) zoom = 1

        setItemGeometry(<CircleMarker color="#2980b9" radius="5" center={center} />)

        setMapProps({
          center,
          zoom,
          latLngBounds: null,
        })
      }

      setLoading(false)
    } else if (type.toLocaleLowerCase() === 'point') {
      const [long, lat] = coordinates

      const center = [lat, long]

      if (Math.abs(center[0]) > 90) zoom = 1

      setItemGeometry(<CircleMarker color="#2980b9" radius="5" center={center} />)

      setMapProps({
        center,
        zoom,
        latLngBounds: null,
      })
      setLoading(false)
    } else {
      setError(true)
    }
  }, [])

  useEffect(() => {
    if (previewLocationMapRef.current) {
      const mapElement = previewLocationMapRef.current.leafletElement
      if (mapProps.latLngBounds) {
        mapElement.fitBounds(mapProps.latLngBounds)
      }
    }
  }, [previewLocationMapRef.current, mapProps])

  if (loading) return <></>
  if (error) {
    return <p>Unable to display map. Please contact Location360 Support.</p>
  }

  return (
    <MapContainer>
      <LeafletMap id="itemLocationMap" ref={previewLocationMapRef} {...mapProps} maxZoom={15}>
        <BingBasemap />
        {itemGeometry}
      </LeafletMap>
    </MapContainer>
  )
}

ItemLocationMap.propTypes = {
  /** Must be a valid geojson object */
  geometry: PropTypes.shape({}).isRequired,
}

export default ItemLocationMap
