import React, {createRef, useEffect, useReducer} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {CircularProgress, Typography} from '@mui/material'

import {addAvailableWorkflowStages, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import useExperimentalValidateTrigger from '../hooks/useExperimentalValidateTrigger'
import useToastNotification from '../hooks/useToastNotification'
import {nonDropdownProperties, dropdownProperties} from '../utilities/experimentalFormElements'

import {DropArea, DropInputLabel} from './UploaderExperimentalFileSelector'
import UploaderExperimentalMetadataFieldSelect from './UploaderExperimentalMetadataFieldSelect'
import UploaderExperimentalMetadataFieldString from './UploaderExperimentalMetadataFieldString'

const FormContainer = styled('div')`
  width: 830px;

  padding: 10px 30px;
  display: grid;
  grid-row-gap: 20px;

  margin: 20px auto 0 auto;
`

const defaultState = {
  loading: true,
  values: {},
  options: {},
  optionDescriptions: {},
  errors: {},
  refs: {},
  disabled: {},
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-initial-properties':
      return {
        ...state,
        refs: action.refs,
        values: action.values,
        options: action.options,
        optionDescriptions: action.optionDescriptions,
        loading: false,
        errors: {},
      }
    case 'update-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.key]: action.value,
        },
      }
    case 'remove-disabled-message':
      return {...state, disabled: {...state.disabled, [action.key]: null}}
    case 'update-field-error-indicators':
      return {
        ...state,
        errors: action.errors,
      }
    default:
      throw new Error(
        `Unknown reducer call "${action.type}" encountered in UploaderExperimentalMetadataForm component`
      )
  }
}

function generateInitialState(selectedProperties) {
  const values = {}
  const options = {}
  const refs = {}

  dropdownProperties.forEach(property => {
    values[property.name] = property.type === 'autocomplete-array-string' ? [] : ''
    if (selectedProperties[property.name]) values[property.name] = selectedProperties[property.name]
    options[property.name] = []
  })

  nonDropdownProperties.forEach(({name, type, minimum}) => {
    if (type === 'integer') {
      values[name] = selectedProperties[name] ?? minimum
    } else if (type === 'string') {
      values[name] = selectedProperties[name] ?? ''
    } else if (type === 'array-string') {
      values[name] = (selectedProperties[name] && selectedProperties[name].split(',')) || []
    } else if (type === 'date') {
      values[name] = selectedProperties[name]
    } else if (type === 'boolean') {
      values[name] = selectedProperties[name] ?? false
    } else {
      throw new Error(
        'Unsupported data type provided by nonDropdownProperties array in functon generateInitialState'
      )
    }
  })

  Object.keys(values).forEach(key => {
    refs[key] = createRef()
  })

  return {values, options, refs}
}

/*
 *
 * All of the metadata form elements for the experimental form
 *
 * Source of truth: https://github.platforms.engineering/location-360/experimental-image-uploader/blob/main/src/Metadata.ts#L183-L188
 *
 * See: interface MetadataProperties
 *
 *
 */
function UploaderExperimentalMetadataForm(props) {
  const {metadata} = props

  const centralDispatch = useDispatch()

  const {errorNotification, warningNotification, successNotification} = useToastNotification()
  const [experimentalValidating, setExperimentalValidating] = useExperimentalValidateTrigger()
  const [state, dispatch] = useReducer(reducer, defaultState)

  const setFieldValue = ({key, value}) => {
    if (!key) return
    // We must use React State in order to allow form components to see change in values
    dispatch({
      type: 'update-field-value',
      key,
      value,
    })
  }

  /** Set up initial values and dropdown options */
  useEffect(() => {
    async function initialLoad() {
      const selectedProperties = {
        ...metadata.getProperties(),
      }

      const {options, values, refs} = generateInitialState(selectedProperties)

      const organization = await metadata.organizations()

      dispatch({
        type: 'set-initial-properties',
        options: {
          ...options,
          organization,
        },
        optionDescriptions: {},
        values,
        refs,
      })
    }

    if (metadata) {
      initialLoad()
    } else if (metadata === null) {
      centralDispatch(addAvailableWorkflowStages([1, 2]))
      centralDispatch(setUploaderWorkflowStage(2))
    }
  }, [metadata])

  // Validates on change of state of trigger state
  useEffect(() => {
    async function validate() {
      const errors = await metadata.validateProperties(state.values)

      dispatch({
        errors: errors || {},
        type: 'update-field-error-indicators',
      })

      const criticalErrorKeys =
        errors &&
        Object.entries(errors)
          .filter(error => error[1].level === 'error')
          .map(i => i[0])

      if (errors && criticalErrorKeys.length > 0) {
        const scrollToFirstError = () => {
          for (const key of criticalErrorKeys) {
            if (state.refs[key].current) {
              state.refs[key].current.scrollIntoView()
              return
            }
          }
        }
        scrollToFirstError()

        errorNotification(
          'Validation failed, update the affected fields, and validate again to progress'
        )
        console.warn('Errors were found in the validation. ', state.values)
      } else if (errors) {
        warningNotification('Validation passed, but warnings are present')
        centralDispatch(addAvailableWorkflowStages([1, 2]))
      } else {
        successNotification('Validation passed')
        metadata.setProperties(state.values)
        centralDispatch(addAvailableWorkflowStages([1, 2]))
      }

      setExperimentalValidating(false)
    }

    if (experimentalValidating) validate()
  }, [experimentalValidating])

  // Returns the elements at the last point of the file selector for consistency
  if (state.loading) {
    return (
      <DropArea>
        <DropInputLabel>
          <div className="icon-container">
            <CircularProgress />
          </div>
          <Typography use="subtitle1" style={{cursor: 'pointer'}}>
            Uploader initializing
          </Typography>
        </DropInputLabel>
      </DropArea>
    )
  }

  return (
    <FormContainer $numberFields={nonDropdownProperties.length + dropdownProperties.length}>
      <UploaderExperimentalMetadataFieldSelect
        dropdownOptions={state.options.organization}
        label="Organization"
        helperText="The organization you belong to"
        reactRef={state.refs.organization}
        value={state.values.organization}
        setValue={value =>
          setFieldValue({
            key: 'organization',
            value,
          })
        }
        errorLevel={state.errors.organization?.level}
        errorMessage={state.errors.organization?.message}
      />
      <UploaderExperimentalMetadataFieldString
        label="Experiment Name"
        helperText="The name that will identify these files"
        reactRef={state.refs.experimentName}
        value={state.values.experimentName}
        setValue={value => {
          setFieldValue({
            key: 'experimentName',
            value,
          })
        }}
        errorLevel={state.errors.experimentName?.level}
        errorMessage={state.errors.experimentName?.message}
      />
    </FormContainer>
  )
}

UploaderExperimentalMetadataForm.propTypes = {
  /** the metadata provided by the experimental uploader sdk */
  metadata: PropTypes.shape({}),
}

export default UploaderExperimentalMetadataForm
