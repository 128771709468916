import React, {useContext, useState} from 'react'
import {Tooltip} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'

import {galleryPageContext} from '../context/galleryPageContext'
import DialogDeleteSingleItem from './DialogDeleteSingleItem'
import StyledIconButton from './styled/StyledIconButton'
import {galleryItemContext} from '../context/galleryItemContext'

/** Triggers item removal dialog, calls delete function */
function DialogDeleteItemGalleryPage() {
  const {canDelete, item} = useContext(galleryItemContext)
  const {removeItem} = useContext(galleryPageContext)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Tooltip title={canDelete ? 'Delete item' : 'Insufficent entitlement'}>
        <span>
          <StyledIconButton
            aria-label="Delete item"
            onClick={() => setOpen(true)}
            size="small"
            edge="end"
            disabled={!canDelete}
            color={canDelete ? 'primary' : 'inherit'}
          >
            <DeleteIcon />
          </StyledIconButton>
        </span>
      </Tooltip>
      {open && (
        <DialogDeleteSingleItem
          closeDialogFn={() => setOpen(false)}
          removeImageFn={() => removeItem(item)}
        />
      )}
    </>
  )
}

export default DialogDeleteItemGalleryPage
