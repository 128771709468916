import moment from 'moment/moment'

export function isDateLike(value) {
  if (!value) return false
  if (value instanceof Date) return true
  const date = new Date(value)
  if (Number.isNaN(date.valueOf())) return false
  if (/^\d{4}-\d{2}-\d{2}($|T)/.test(value)) return true
  return false
}

export const setDateRangeValues = (date, dateOption, modifyCriteria, index) => {
  switch (dateOption) {
    case 'on':
      modifyCriteria(index, 'from', date)
      modifyCriteria(index, 'to', date.clone().add(24, 'hours'))
      break
    case 'after':
      modifyCriteria(index, 'to', moment())
      modifyCriteria(index, 'from', date)
      break
    case 'between':
      modifyCriteria(index, 'from', date)
      break
  }
}

export const translateTimePeriodToDateRange = (
  timeUnit,
  setTimeRangeOptions,
  modifyCriteria,
  index
) => {
  setTimeRangeOptions(timeUnit)
  modifyCriteria(index, 'criteriaType', 'BETWEEN')
  const currentMoment = moment()
  switch (timeUnit) {
    case 'custom':
      modifyCriteria(index, 'from', currentMoment)
      modifyCriteria(index, 'to', currentMoment)
      break
    default:
      const newStartDate = currentMoment.clone().subtract(1, timeUnit) // eslint-disable-line
      modifyCriteria(index, 'from', newStartDate)
      modifyCriteria(index, 'to', currentMoment)
  }
}

export const formatIfDate = value => (moment.isMoment(value) ? value.format('YYYY-MM-DD') : value)

export default {formatIfDate, setDateRangeValues, translateTimePeriodToDateRange}
