import React, {useContext, useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableRow,
} from '@mui/material'
import {AddBox as AddBoxIcon, HighlightOff as RemoveTagIcon} from '@mui/icons-material'

import {itemContext} from '../context/itemContext'
import useToastNotification from '../hooks/useToastNotification'
import ItemMetaDataAddTagDialog from './ItemMetadataAddTagDialog'
import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'
import {handleSentryError} from '../utilities/sentryErrors'
import {useNavigate} from 'react-router-dom'

function ItemMetadataTags() {
  const context = useContext(itemContext)
  const navigate = useNavigate()

  const {
    itemMetadata: {
      item,
      metadata,
      permissions: {publish: editPermission},
    },
  } = context

  const tags = metadata['tags:tags']

  const [deleteDialog, setDeleteDialog] = useState(null)
  const [createDialog, setCreateDialog] = useState(false)
  const [tagList, setTagList] = useState([])

  useEffect(() => {
    if (!tags) {
      return
    }
    setTagList(tags)
  }, [tags])

  const {successNotification, errorNotification} = useToastNotification()

  function deleteTag() {
    const newTags = [...tagList]

    // Array indexes are incremented by one as 0 is treated as falsey in JS
    const [tagForRemoval] = newTags.splice(deleteDialog - 1, 1)

    item
      .updateMetadata({properties: {['tags:tags']: newTags}})
      .then(() => {
        successNotification(`Tag "${tagForRemoval}" removed`)
        setTagList(newTags)
      })
      .catch(e => {
        const additionalErrorDetails = `Unable to remove tag "${tagForRemoval}".`
        handleSentryError(e, additionalErrorDetails)
        errorNotification(e.message)
      })
      .finally(() => setDeleteDialog(null))
  }

  const removeTagHandler = index => {
    setDeleteDialog(index + 1)
  }

  return (
    <>
      <ItemMetadataHeaderAccordion header="Tags">
        <div>
          <Table style={{minWidth: '450px'}} aria-label="simple table">
            <tbody>
              {tagList.length > 0 ? (
                tagList.map((tag, index) => (
                  <TableRow key={`${tag}-${index}`}>
                    <TableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => navigate(`/advanced-search?tags=${tag}`)}
                    >
                      {tag}
                    </TableCell>
                    <TableCell sx={{paddingTop: 0, paddingBottom: 0, width: '10px'}}>
                      {editPermission && (
                        // Adding one to index so as not to catch an implicit 0
                        <IconButton onClick={() => removeTagHandler(index)} size="large">
                          <RemoveTagIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No tags present</TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
          {editPermission && (
            <Button
              sx={{marginTop: '10px', width: '100%'}}
              variant="outlined"
              color="primary"
              startIcon={<AddBoxIcon />}
              onClick={() => setCreateDialog(true)}
            >
              Add Tag to Item
            </Button>
          )}
        </div>
        <ItemMetaDataAddTagDialog
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}
          setTags={setTagList}
        />
        <Dialog open={deleteDialog || false} onClose={() => setDeleteDialog(false)}>
          <DialogTitle>Remove Tag from Item?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tags are used to assist users to find items. Removing this make it more difficult for
              users to find this item.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={deleteTag} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default ItemMetadataTags
