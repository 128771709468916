import createClient from '@monsantoit/preferences-client'
import {isProd} from './serviceBindings'

async function initializeClient() {
  const client = createClient(isProd ? 'imagine-ui' : 'imagine-ui-np')
  await client.promise()
  return client
}

const client = initializeClient()

export async function getPreferencesValue(key) {
  return (await client).get(key)
}

export async function setPreferencesValue(key, value) {
  const profileClient = await client

  profileClient.put(key, value)
}
