import React, {useContext, useState} from 'react'
import {Button} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {itemContext} from '../context/itemContext'
import useToastNotification from '../hooks/useToastNotification'

import DialogDeleteSingleItem from './DialogDeleteSingleItem'

const Container = styled('div')`
  overflow: hidden;
  background: white;
  button {
    width: 100%;
    color: var(--mdc-theme-error);

    border: 1px solid var(--mdc-theme-error);
  }
`

/*
 * A button that when triggered engages the delete modal, and excutes the deletion once confirmed.
 *
 */

function ItemLayoutDeleteButton() {
  const context = useContext(itemContext)
  const navigate = useNavigate()
  const {
    itemMetadata: {
      id,
      project,
      thumbnail,
      item,
      permissions: {delete: deletePermission},
    },
  } = context

  const disabled = !deletePermission

  const [deletionModalOpen, setDeletionModalOpen] = useState(false)
  const {errorNotification, successNotification} = useToastNotification()

  function transitionPage() {
    navigate(-1)
  }

  const deleteItem = () => {
    item
      .remove()
      .then(() => {
        successNotification('Item deleted')
        setTimeout(transitionPage, 2500)
      })
      .catch(e => {
        const readOnlyRe = /The Collection 'imagine_archive' is read-only for API users/
        const message = readOnlyRe.test(e.message)
          ? 'The Collection "imagine_archive" is read-only'
          : e.message

        errorNotification(`Error: ${message}`)
        setDeletionModalOpen(false)
        throw e
      })
  }

  if (!item)
    return (
      <Button variant="outlined" disabled color="primary" startIcon={<DeleteForeverIcon />}>
        Delete Item
      </Button>
    )

  return (
    <>
      <Container>
        <Button
          onClick={() => setDeletionModalOpen(true)}
          variant="outlined"
          disabled={disabled}
          startIcon={<DeleteForeverIcon />}
        >
          Delete Item
        </Button>
      </Container>
      {/* Deletion */}
      {deletionModalOpen && (
        <DialogDeleteSingleItem
          imageData={{
            id,
            url: thumbnail,
            project,
          }}
          removeImageFn={deleteItem}
          closeDialogFn={() => setDeletionModalOpen(false)}
          open
        />
      )}
    </>
  )
}

export default ItemLayoutDeleteButton
