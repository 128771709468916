import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {PhotoLibrary} from '@mui/icons-material'

import styled from 'styled-components'

import {galleryPageContext} from '../context/galleryPageContext'
import {imagineServiceContext} from '../context/imagineServiceContext'
import GalleryPageDialog from './GalleryPageDialog'
import {handleSentryError} from '../utilities/sentryErrors'

function extractMetadataFromStacExtension(ext, metadataDisplayData) {
  const properties = ext.jsonSchema.properties.properties.properties
  const names = Object.keys(properties)
  names.forEach(name => {
    const valueType = properties[name].type
    let itemType = undefined
    metadataDisplayData[name] = {type: valueType}
    if (valueType === 'array') {
      itemType = properties[name].items.type
      metadataDisplayData[name].items = {type: itemType}
    }
  })
  return metadataDisplayData
}

function extractMetadataFromStacExtensions(exts) {
  let metadataDisplayData = {}
  exts.forEach(ext => {
    try {
      extractMetadataFromStacExtension(ext, metadataDisplayData)
    } catch (err) {
      handleSentryError(err, `Error extracting metadata from extension ${ext?.id}`)
    }
  })
  return metadataDisplayData
}

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`

/**
 * Allows users to determine what schema they wish visible for items of a given collection
 */
function GalleryVisibleSchemaDialog(props) {
  const {open, onClose} = props
  const [loading, setLoading] = useState(true)
  const [schema, setSchema] = useState({})

  const {imagineSdk} = useContext(imagineServiceContext)
  const {visibleSchemaElements, addSchemaElement, removeSchemaElement, visibleCollections} =
    useContext(galleryPageContext)

  useEffect(() => {
    async function retrieveSchema() {
      const visibleSchema = {}

      for (const collectionName of visibleCollections) {
        const collection = await imagineSdk.fetchCollection({id: collectionName}).catch(e => {
          const additionalErrorDetails = `Unable to fetch the collection ${collectionName}.`
          handleSentryError(e, additionalErrorDetails)
        })
        const exts = await collection.itemStacExtensions()
        const schema = extractMetadataFromStacExtensions(exts)
        const title = collection.title || collection.id

        visibleSchema[collectionName] = {title, schema}
      }

      setSchema(visibleSchema)
      setLoading(false)
    }

    setLoading(true)
    if (visibleCollections.length > 0) retrieveSchema()
  }, [visibleCollections])

  const noCheckboxAttrs = ['datetime']
  const ignoreAttr = (attr, type) =>
    noCheckboxAttrs.indexOf(attr) !== -1 || type === '(unused extension)'

  const DialogSchemaTableRows = props => {
    const {collectionName} = props

    return !schema[collectionName]?.schema ? (
      <></>
    ) : (
      Object.entries(schema[collectionName].schema).map(([key, content], index) => {
        let entryType = content.type

        const schemaElements = visibleSchemaElements[collectionName] ?? []

        const selectedRow = schemaElements.indexOf(key) !== -1

        function toggleSchemaElement() {
          const elements = new Set(schemaElements)

          if (elements.has(key)) {
            removeSchemaElement(collectionName, key)
          } else {
            addSchemaElement(collectionName, key)
          }
        }

        if (content.type === 'array') {
          entryType = `array of ${content.items.type}`
        } else if (content.type === '(unused extension)') {
          entryType = <em style={{color: 'rgba(0,0,0,0.35)'}}>{content.type}</em>
        }

        return (
          <TableRow
            key={`collection-schema-${key}-${index}`}
            onClick={() => {
              if (!ignoreAttr(key, content.type)) toggleSchemaElement()
            }}
          >
            <TableCell style={{minWidth: 50}} align="center">
              {!ignoreAttr(key, content.type) && <Checkbox color="default" checked={selectedRow} />}
            </TableCell>
            <TableCell className="wide-cell">{key}</TableCell>
            <TableCell>{entryType}</TableCell>
          </TableRow>
        )
      })
    )
  }

  if (loading) return <></>

  return (
    <GalleryPageDialog onClose={onClose} open={open} title="Customize Visible Gallery Metadata">
      {visibleCollections.length > 1 ? (
        <Card>
          <CardContent>
            <Typography variant="body1">
              To start modifying visible preferences, select a collection
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}

      {visibleCollections.length > 1 ? (
        visibleCollections.map(collectionName =>
          schema[collectionName] ? (
            <Accordion key={`dialog-collection-mod-${collectionName}`}>
              <AccordionSummary>
                <StyledTypography variant="h6" color="primary">
                  <PhotoLibrary /> {schema[collectionName].title}
                </StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell component="th">Name</TableCell>
                        <TableCell component="th">Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <DialogSchemaTableRows collectionName={collectionName} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ) : (
            <React.Fragment key={`dialog-collection-mod-${collectionName}`}></React.Fragment>
          )
        )
      ) : (
        <Accordion expanded>
          <AccordionSummary>
            <Typography variant="h6" color="primary">
              Collection: {schema[visibleCollections[0]].title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell component="th">Name</TableCell>
                    <TableCell component="th">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <DialogSchemaTableRows collectionName={visibleCollections[0]} />
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      )}
    </GalleryPageDialog>
  )
}

GalleryVisibleSchemaDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default GalleryVisibleSchemaDialog
