import React, {useState, useMemo, useEffect} from 'react'
import {Button, FormGroup, FormControlLabel, Switch} from '@mui/material'
import {Link} from 'react-router-dom'

import styled from 'styled-components'

import loc360Logo from '../assets/loc360-logo.jpeg'
import DirectoryGridContent from '../components/DirectoryGridContent'
import DirectoryGridFilter from '../components/DirectoryGridFilter'
import CardGenericError from '../components/CardGenericError'
import LoadingSpinner from '../components/LoadingSpinner'
import ButtonCreateNewAlbum from '../components/ButtonCreateNewAlbum'
import TextIndicatorDirectoryLoadState from '../components/TextIndicatorDirectoryLoadState'
import useCollectionPin from '../hooks/useCollectionPin'

const MainDisplay = styled('div')`
  height: calc(95vh - 207px);
  width: 100vw;
  margin: 0 auto;

  overflow-x: hidden;
  overflow-y: scroll;

  .count-placeholder {
    height: 44px;
    width: 100%;
  }

  .notification-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterd-projects-count {
    width: 100%;
    padding: 10px;
    min-height: 40px;
  }
`

const DirectoryFooter = styled.footer`
  display: grid;
  grid-template-columns: 105px 116px 98px 1fr 175px;
  background: white;
  width: 100vw;
  position: absolute;
  bottom: 0;

  grid-column-gap: 12px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin: 0 auto;

  align-items: center;

  .toggle {
    padding: inherit 5px;
    width: 100%;
  }

  button {
    height: 50px;
    width: 100%;
  }
`

const ToggleButton = styled(Button)`
  background-color: ${props => (props.disableElevation ? '#2980b9' : '#2196f3')} !important;
  color: white !important;
`

/** Common layout for all three directory pages */
function DirectoryLayout(props) {
  const {filteredContent, contentType, loading, error, populating, numPossibleElements, maxCount} =
    props

  const [hideEmptyContent, setHideEmptyContent] = useState(false)
  const [userPinnedCollections, setUserPinnedCollections] = useState([])

  const {getPinnedCollections} = useCollectionPin()

  useEffect(() => {
    getPinnedCollections().then(pinnedCollections => {
      setUserPinnedCollections(pinnedCollections)
    })
  }, [])

  // Clear memory to reset page of last project/collection/etc user was last on
  useEffect(() => {
    localStorage.removeItem('projectName')
    localStorage.removeItem('projectPage')
    localStorage.removeItem('collectionName')
    localStorage.removeItem('collectionPage')
    localStorage.removeItem('albumName')
    localStorage.removeItem('albumPage')
    localStorage.removeItem('searchTermString')
    localStorage.removeItem('searchTermPage')
    localStorage.removeItem('advancedSearchTerm')
    localStorage.removeItem('advancedSearchPage')
  }, [])

  if (error)
    return (
      <div className="notification-container">
        <CardGenericError
          title="There was a problem connecting to Imagine"
          description="Please try again. If this issue continues, contact Location360 Support."
          caption={error ?? 'Initial content load failure'}
        />
      </div>
    )

  const nonEmptyContent = useMemo(() => {
    return filteredContent.filter(i => i.count)
  }, [filteredContent])

  const showEmptyContent = event => {
    setHideEmptyContent(event.target.checked)
  }

  const labelForHideEmptyContentToggle = hideEmptyContent
    ? `Hide empty ${contentType} - ${
        filteredContent.length - nonEmptyContent.length
      } ${contentType} hidden`
    : `Hide empty ${contentType}`

  return (
    <>
      <MainDisplay isProjectList={contentType === 'projects'}>
        {loading ? (
          <LoadingSpinner message="Loading Imagine UI" />
        ) : (
          <>
            {filteredContent && (
              <>
                <div className="filterd-projects-count">
                  <TextIndicatorDirectoryLoadState
                    loading={!!populating}
                    count={
                      populating
                        ? {current: numPossibleElements, total: maxCount}
                        : {
                            current: hideEmptyContent
                              ? nonEmptyContent.length
                              : filteredContent?.length,
                            total: numPossibleElements ?? maxCount,
                          }
                    }
                    contentType={contentType}
                  />
                  {(contentType === 'collections' || contentType === 'albums') && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={hideEmptyContent} onChange={showEmptyContent} />}
                        label={labelForHideEmptyContentToggle}
                      />
                    </FormGroup>
                  )}
                </div>
              </>
            )}
            <DirectoryGridContent
              filteredContent={hideEmptyContent ? nonEmptyContent : filteredContent}
              contentType={contentType}
              populating={populating}
              userPinnedCollections={userPinnedCollections}
            />
          </>
        )}
        {contentType === 'albums' && <ButtonCreateNewAlbum />}
      </MainDisplay>

      <DirectoryFooter>
        <div className="toggle">
          <Link to="/directory/projects">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'projects'}
              disabled={loading}
            >
              Projects
            </ToggleButton>
          </Link>
        </div>
        <div className="toggle">
          <Link to="/directory/collections">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'collections'}
              disabled={loading}
            >
              Collections
            </ToggleButton>
          </Link>
        </div>

        <div className="toggle">
          <Link to="/directory/albums">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'albums'}
              disabled={loading}
            >
              Albums
            </ToggleButton>
          </Link>
        </div>

        <DirectoryGridFilter />
        <img src={loc360Logo} alt="Location 360 Imagine UI" height="55" />
      </DirectoryFooter>
    </>
  )
}

export default DirectoryLayout
