import React from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

/**
 * Common layout elements for the following routes:
 * - project
 * - collection
 * - search
 * - advanced search
 */

const ViewGridSection = styled('section')`
  display: flex;
  flex-direction: column;

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .infinite-scroll-container {
    /* Prevents above accordions in search from overlapping on close */
    z-index: 1;

    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    min-height: 700px;
  }
`

/**
 * A display layout for gallery pages
 */
function GalleryPageLayout(props) {
  const {summaryComponent, specialContent, children} = props

  return (
    <ViewGridSection>
      {summaryComponent && <div className="container">{summaryComponent}</div>}
      {specialContent && <div className="container">{specialContent}</div>}
      <div className="infinite-scroll-container">{children}</div>
    </ViewGridSection>
  )
}

GalleryPageLayout.propTypes = {
  // the summary of the gallery that lies above all the items in a gallery page
  summaryComponent: PropTypes.node,
  // All the items!
  children: PropTypes.node.isRequired,
  // An optional section that lies between the summary component, and the gallery content.
  specialContent: PropTypes.node,
}

GalleryPageLayout.defaultProps = {
  summaryComponent: null,
  specialContent: null,
}
export default GalleryPageLayout
