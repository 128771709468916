import React from 'react'
import {PropTypes} from 'prop-types'

import styled from 'styled-components'

import DirectoryCard from './DirectoryCard'
import DirectoryCardLoadingSkeleton from './DirectoryCardLoadingSkeleton'
import CardGenericError from './CardGenericError'

import useDirectoryPageState from '../hooks/useDirectoryPageState'

const StyledContainer = styled.div`
  display: grid;

  grid-auto-rows: 175px;
  grid-template-columns: repeat(auto-fit, 530px);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 1625px) {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
`

/** Loads content into a styled column. Provides a skeleton if loading... */
const DirectoryGridContent = ({
  filteredContent,
  contentType,
  populating,
  userPinnedCollections,
}) => {
  const checkNoEntitlement = (type, entitlements) => entitlements.indexOf(type) === -1
  const {
    state: {filter},
  } = useDirectoryPageState()

  if (filter && (!filteredContent || filteredContent.length === 0)) {
    return (
      <CardGenericError
        title="No Content Found"
        description="The Imagine API did not return any results for your query."
        caption="No content was found. If you believe this to be incorrect, please contact Location360 Support."
      />
    )
  }

  function sortPinnedCollectionsFirst(a, b) {
    const aPinned = userPinnedCollections.includes(a.name)
    const bPinned = userPinnedCollections.includes(b.name)
    if (aPinned && bPinned) {
      return a.name.localeCompare(b.name)
    } else if (aPinned) {
      return -1
    } else if (bPinned) {
      return 1
    }
    return a.name.localeCompare(b.name)
  }

  return (
    <StyledContainer>
      {filteredContent.sort(sortPinnedCollectionsFirst).map(p => (
        <DirectoryCard
          name={p.name}
          title={p.title}
          thumbnail={p.thumbnail}
          parent={p}
          count={p.count}
          lastUpdated={p.lastUpdated}
          key={`project-card-${p.id}-${p.name}`}
          notDelete={checkNoEntitlement('DELETE', p.entitlements)}
          notPublish={checkNoEntitlement('PUBLISH', p.entitlements)}
          notDownload={checkNoEntitlement('DOWNLOAD', p.entitlements)}
          contentType={contentType}
          description={p.description}
          userPinnedCollections={userPinnedCollections}
        />
      ))}
      {populating && <DirectoryCardLoadingSkeleton contentType={contentType} />}
    </StyledContainer>
  )
}

DirectoryGridContent.propTypes = {
  contentType: PropTypes.string.isRequired,
  filteredContent: PropTypes.arrayOf(PropTypes.shape({})),
  populating: PropTypes.bool.isRequired,
  userPinnedCollections: PropTypes.arrayOf(PropTypes.string),
}

DirectoryGridContent.defaultProps = {
  filteredContent: [],
}

export default DirectoryGridContent
