import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

function UploaderExperimentalMetadataFieldSelect(props) {
  const {
    disabledMessage,
    errorLevel,
    errorMessage,
    dropdownOptions,
    helperText,
    label,
    optionDescriptions,
    reactRef,
    setValue,
    value,
  } = props

  const emptyDropdown = options =>
    options && (options.length === 0 || (options.length === 1 && options[0] === ' '))

  const SurroundElement = ({children}) => (
    <FormControl
      ref={reactRef}
      key={`dropdown-field-${label}`}
      error={errorLevel === 'error'}
      className={`dropdown-variant-${errorLevel === 'warning' ? 'warning' : 'normal'}`}
    >
      {children}
    </FormControl>
  )

  if (disabledMessage) {
    return (
      <SurroundElement>
        <InputLabel>{label}</InputLabel>
        <Select value={disabledMessage} disabled>
          <option value={disabledMessage}>{disabledMessage}</option>
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </SurroundElement>
    )
  }

  let dropdownValue = value
  if (!value) dropdownValue = ' '
  if (value && dropdownOptions.indexOf(value) === -1) dropdownValue = ' '

  return (
    <SurroundElement>
      <InputLabel>{label}</InputLabel>
      <Select
        value={dropdownValue}
        onChange={event => {
          setValue(event.target.value)
        }}
        disabled={emptyDropdown(dropdownOptions)}
      >
        {dropdownOptions.map(element => (
          <MenuItem value={element} key={`dropdown-${name}-${element}`}>
            {optionDescriptions[element] ? (
              <div>
                <Typography variant="body2">{element}</Typography>
                <Typography variant="caption">{optionDescriptions[element]}</Typography>
              </div>
            ) : (
              element
            )}
          </MenuItem>
        ))}
      </Select>
      {['warning', 'error'].indexOf(errorLevel) !== -1 ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </SurroundElement>
  )
}

UploaderExperimentalMetadataFieldSelect.propTypes = {
  disabledMessage: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(PropTypes.string),
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  optionDescriptions: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  reactRef: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
}

UploaderExperimentalMetadataFieldSelect.defaultProps = {
  disabledMessage: null,
  dropdownOptions: [],
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  optionDescriptions: {},
  value: false,
}

export default UploaderExperimentalMetadataFieldSelect
