import type {Item} from '@bayer-int/imagine-sdk-browser'

async function getItemThumbnailUrl(item: Item): Promise<string> {
  let thumbnailUrl = ''
  try {
    const thumbnailAsset = await item.fetchAsset({role: 'thumbnail'})
    thumbnailUrl = await thumbnailAsset?.fetchDownloadUrl()
  } catch (err) {
    // TODO so many mission thumbnails
    // console.error(`Error getting thumbnail url for item: ${JSON.stringify(item)}`, {cause: err})
  }
  return thumbnailUrl
}

export default getItemThumbnailUrl
