import {useEffect, useState} from 'react'
import {handleSentryError} from '../utilities/sentryErrors'

function useDetermineBatchActionUnsuitability(selectedItems) {
  const [unTaggableItems, setUntaggableItems] = useState([])

  /** Validate each item when the dialog opens */
  useEffect(() => {
    async function determineItemSuitability(item) {
      const entitlements = (await item.entitlements()) ?? []
      const hasPublishPermission = entitlements.currentUserHasEntitlement('PUBLISH')
      if (!hasPublishPermission) {
        return {
          id: item.id,
          message: "Insufficient permssion for item's project",
        }
      }

      // item.schema() to be implemented in future sdk version. For now assign as empty object
      // const schema = await item.schema().catch(e => {
      //   const additionalErrorDetails = 'There was an error retrieving the schema for an item.'
      //   handleSentryError(e, additionalErrorDetails)
      // })
      const schema = {}
      const collection = await item.collection().catch(e => {
        const additionalErrorDetails = 'There was an error retrieving the collection for an item.'
        handleSentryError(e, additionalErrorDetails)
      })
      const extensions = collection.stacExtensions ?? []

      const hasTagInSchema = [...Object.keys(schema), ...extensions].indexOf('tags') !== -1
      if (!hasTagInSchema) {
        return {
          id: item.id,
          message: "Incompatable schema for item's collection",
        }
      }

      return null
    }

    // Seeks whether each item lacks a tag element in the schema, and user has publish permission
    async function validateSelectedItems() {
      const unsuitableItemPromises = selectedItems.map(determineItemSuitability)

      Promise.all(unsuitableItemPromises).then(elements => {
        const unsuitableItems = elements.filter(i => i)

        setUntaggableItems(unsuitableItems)
      })
    }
    validateSelectedItems()
  }, [selectedItems])

  // Reset on close of hook
  useEffect(
    () => () => {
      setUntaggableItems([])
    },
    []
  )

  return unTaggableItems
}

export default useDetermineBatchActionUnsuitability
