import React, {createContext} from 'react'
import {isProd} from '../utilities/serviceBindings'

// List set of possible feature toggles here. Set to false
const initialState = {loading: true}

const featureFlagContext = createContext(initialState)
const {Provider} = featureFlagContext

/**
 * Gathers statuses and provides source of truth for all feature flags in the UI
 *
 * Feature flags (toggles) are a system that allows developers to make changes to the UI
 * without requiring changes to the codebase. https://martinfowler.com/articles/feature-toggles.html
 *
 * Instead of inserting this into redux, I am using context as the data will remain static
 *
 * Future scope to this may include API, or explicit permissions by groups based on the current user
 *
 * */
function FeatureFlagProvider({children}) {
  const deployedFlags = {
    releaseFlags: {
      multipleTagAdd: !isProd, // Accessable in nonprod
      advancedSearchTag: !isProd, // Accessable in nonprod

      // exampleFlag: !isProd,
    },
  }

  const flags =
    process?.env?.NODE_ENV === 'development'
      ? {
          releaseFlags: {
            multipleTagAdd: process.env.FEATURE_FLAG_MULTIPLE_TAG_ADD === 'true',
            advancedSearchTag: process.env.FEATURE_FLAG_ADVANCED_SEARCH_TAG === 'true',
          },
        }
      : deployedFlags

  return <Provider value={flags}>{children}</Provider>
}

export {featureFlagContext, FeatureFlagProvider}
export default featureFlagContext
