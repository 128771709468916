import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import {Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import styled from 'styled-components'

const StyledErrorIcon = styled(ErrorIcon)`
  display: block;
  font-size: 80px !important;
`

const Message = styled(Typography)`
  font-size: 1rem;
  margin-top: 0.5rem;
`

const Submessage = styled(Typography)`
  font-size: 0.95rem;
`

const FullScreenErrorElement = styled('div')`
  width: 100vw;
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.95);
    width: 75vw;
    max-width: 800px;
    z-index: 100;
  }
`

const ActionsElement = styled('div')`
  display: flex;
  margin-top: 1.75rem;

  a:not(:first-child) {
    margin-left: 1.5rem;
  }
`

function FullscreenError({actions, message, subMessage}) {
  const actionLinks = actions.map(({action, text}) => (
    <Link key={text} style={{cursor: 'pointer'}} onClick={action}>
      {text}
    </Link>
  ))
  return (
    <FullScreenErrorElement>
      <div className="content">
        <StyledErrorIcon color="error" />
        <Message variant="body1">{message}</Message>
        {Boolean(subMessage) && <Submessage variant="body1">{subMessage}</Submessage>}
        <ActionsElement>{actionLinks}</ActionsElement>
      </div>
    </FullScreenErrorElement>
  )
}

FullscreenError.displayName = 'FullscreenError'

FullscreenError.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      text: PropTypes.string,
    })
  ),
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
}

FullscreenError.defaultProps = {
  actions: [],
  subMessage: '',
}

export default FullscreenError
