import React, {useContext} from 'react'

import Typography from '@mui/material/Typography'
import styled from 'styled-components'

import {itemContext} from '../context/itemContext'

import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'

const MetadataItemTitle = styled(Typography)`
  margin: 0.4em 0 0.2em 0 !important;
`
function ItemMetadataListGeographyDisplay() {
  const context = useContext(itemContext)

  const {
    itemMetadata: {geometry},
  } = context

  if (!geometry) return <></>

  const {type, coordinates} = geometry

  if (['point', 'polygon', 'multipolygon'].indexOf(type.toLocaleLowerCase()) === -1) {
    console.warn('Unknown coordinate type ', type, ', skipping geometry...')
    return <></>
  }

  const CoordinatesList = () =>
    type.toLocaleLowerCase() === 'point' ? (
      <dd>{coordinates.join(', ')}</dd>
    ) : type.toLocaleLowerCase() === 'multipolygon' ? (
      <dd className="sub-polygon-list">
        {coordinates.map((array, index) => (
          <details key={`polygon-${index + 1}`}>
            <summary>Polygon {index + 1}</summary>
            {array.map((item, itemIndex) => (
              <dd key={`coordinates-${itemIndex}`}>{item.join(', ')}</dd>
            ))}
          </details>
        ))}
      </dd>
    ) : (
      <>
        {coordinates.map((i, idx) => (
          <dd key={`coordinates-${idx}`}>{i.join(', ')}</dd>
        ))}
      </>
    )

  return (
    <>
      <ItemMetadataHeaderAccordion header="Item Geometry">
        <div>
          <MetadataItemTitle variant="h6">Geometry</MetadataItemTitle>
          <dl className="sub-list">
            <dt>
              <MetadataItemTitle variant="h6">Geometry Type</MetadataItemTitle>
            </dt>
            <dd>{type}</dd>
            <dt>
              <MetadataItemTitle variant="h6">Coordinates</MetadataItemTitle>
            </dt>

            <CoordinatesList />
          </dl>
        </div>
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default ItemMetadataListGeographyDisplay
