import React from 'react'
import PropTypes from 'prop-types'
import {FormControl, FormHelperText, Input, InputLabel, MenuItem, Select} from '@mui/material'

function UploaderOrthoMosaicMetadataFieldSelectMultiple(props) {
  const {
    errorLevel,
    errorMessage,
    dropdownOptions,
    helperText,
    label,
    reactRef,
    setValue,
    value,
  } = props

  return (
    <FormControl
      key={`dropdown-field-${label}`}
      ref={reactRef}
      error={['warning', 'error'].indexOf(errorLevel) !== -1}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        input={<Input />}
        value={value || []}
        disabled={!(dropdownOptions && dropdownOptions.length)}
        onChange={event => {
          setValue(event.target.value)
        }}
      >
        {dropdownOptions.map(element => (
          <MenuItem value={element} key={`dropdown-${label}-${element}`}>
            {element}
          </MenuItem>
        ))}
      </Select>
      {errorLevel === 'error' ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

UploaderOrthoMosaicMetadataFieldSelectMultiple.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.string),
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  reactRef: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
}

UploaderOrthoMosaicMetadataFieldSelectMultiple.defaultProps = {
  dropdownOptions: [],
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  value: false,
}

export default UploaderOrthoMosaicMetadataFieldSelectMultiple
