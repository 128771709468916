import React, {useContext, useEffect, useRef} from 'react'

import DirectoryLayout from '../layouts/DirectoryLayout'
import CommonLayout from '../layouts/CommonLayout'
import {imagineServiceContext} from '../context/imagineServiceContext'
import useDirectoryPageState from '../hooks/useDirectoryPageState'
import {handleSentryError} from '../utilities/sentryErrors'
import {asyncThrottle} from '../utilities/async'
import getItemThumbnailUrl from '../utilities/getItemThumbnailUrl'
import useToastNotification from '../hooks/useToastNotification'

const DIRECTORY_PAGE_COLLECTIONS_CACHE_KEY = `DIRECTORY_PAGE_COLLECTIONS_CACHE_KEY`

/**
 * Data loading for collections
 */
function DirectoryPageCollections() {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    retrieveCollections,
  } = useContext(imagineServiceContext)
  const {
    state,
    dispatch,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  } = useDirectoryPageState()
  const {errorNotification, warningNotification} = useToastNotification()

  const mounted = useRef(false)
  const abortController = useRef(new AbortController())
  const {filter} = state

  useEffect(() => {
    mounted.current = true
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
      abortController?.current?.abort()
    }
  }, [])

  useEffect(() => {
    const gathercollectionElements = async () => {
      let collectionsArray
      let cachedData

      try {
        cachedData = window.localStorage.getItem(DIRECTORY_PAGE_COLLECTIONS_CACHE_KEY)
        if (cachedData) {
          cachedData = JSON.parse(cachedData)
          dispatch({
            type: 'set-max-count',
            maxCount: cachedData.cnt,
          })
          if (mounted.current) {
            setData(cachedData.collectionElements)
            incrementNumberItemsPopulated()
          }
        }
      } catch (err) {
        handleSentryError(err, 'Failed to load cached collection page data.')
      }

      try {
        collectionsArray = await retrieveCollections()
      } catch (err) {
        handleSentryError(err, 'There was an error retrieving collections.')
        if (cachedData) {
          warningNotification(
            'Error checking for collection updates. The server may be having trouble.'
          )
        } else {
          errorNotification('Error loading collections. The server may be having trouble.')
        }
      }

      if (collectionsArray) {
        try {
          const collectionElements = []

          if (collectionsArray.length > 0 && mounted.current)
            dispatch({
              type: 'set-max-count',
              maxCount: collectionsArray.length,
            })

          // eslint-disable-next-line
          async function expandCollectionsArray(collection) {
            const getEntitlements = await collection.entitlements().catch(e => {
              const additionalErrorDetails = `There was an error fetching entitlements for ${collection.id}.`
              handleSentryError(e, additionalErrorDetails)
            })
            const userEntitlements = []
            if (getEntitlements.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
            if (getEntitlements.currentUserHasEntitlement('DELETE')) userEntitlements.push('DELETE')
            if (getEntitlements.currentUserHasEntitlement('PUBLISH'))
              userEntitlements.push('PUBLISH')
            if (getEntitlements.currentUserHasEntitlement('DOWNLOAD'))
              userEntitlements.push('DOWNLOAD')

            const itemsIterator = collection.fetchItems({
              limit: 1,
              sortBy: [
                {
                  direction: 'desc',
                  field: 'properties.datetime',
                },
              ],
            })

            const collectionDisplayElement = {
              name: collection.id,
              title: collection.title,
              entitlements: userEntitlements,
              description: collection.description || '',
              count: await itemsIterator.count().catch(e => {
                const additionalErrorDetails = `Unable to retrieve count for collection "${collection.title}".`
                handleSentryError(e, additionalErrorDetails)
              }),
              thumbnail: null,
              lastUpdated: null,
            }

            if (collectionDisplayElement.count) {
              // Retrieves thumbnail information for collection by retrieving data for one item in the collection
              for await (const item of itemsIterator) {
                collectionDisplayElement.thumbnail = await getItemThumbnailUrl(item)

                // Date formatter helper function
                const dateFormatter = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })

                collectionDisplayElement.lastUpdated = dateFormatter.format(item.updatedAt)
              }
            }

            collectionElements.push(collectionDisplayElement)
            if (!cachedData && mounted.current) {
              setData(collectionElements)
              incrementNumberItemsPopulated()
            }
          }

          const collectionQueryFns = collectionsArray.map(collection => {
            return () => expandCollectionsArray(collection)
          })
          const collectionQueries = await asyncThrottle(collectionQueryFns, {
            limit: 20,
            abortController: abortController.current,
            checkDelay: 20,
          })
          Promise.allSettled(collectionQueries)
            .then(() => {
              if (mounted.current) {
                setData(collectionElements)
                itemPopulatingComplete()
                window.localStorage.setItem(
                  DIRECTORY_PAGE_COLLECTIONS_CACHE_KEY,
                  JSON.stringify({
                    cnt: collectionsArray.length,
                    collectionElements,
                  })
                )
              }
            })
            .catch(e => handleSentryError(e))
        } catch (e) {
          if (mounted.current) {
            const details = 'There was an error loading collections.'
            handleSentryError(e, details)
            errorNotification(details)
          }
        }
      }
    }

    if (!(globalLoading || globalLoadingError) && mounted.current) gathercollectionElements()
  }, [globalLoading, globalLoadingError, mounted.current])

  const content = state.data
  const filteredContent = content.filter(
    ({name, title}) =>
      title?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase()) ||
      name?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase())
  )

  return (
    <CommonLayout>
      <DirectoryLayout
        filteredContent={filteredContent}
        loading={state.loading}
        error={state.error || globalLoadingError}
        populating={state.itemsPopulated < state.maxCount}
        numPossibleElements={state.data.length}
        maxCount={state.maxCount}
        contentType="collections"
      />
    </CommonLayout>
  )
}

export default DirectoryPageCollections
