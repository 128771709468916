export const nonDropdownProperties = [
  {
    name: 'preprocessed',
    label: 'Preprocessed Orthomosaic',
    type: 'boolean',
    helperText: 'Whether the image has been fully orthorectified and quality controlled',
  },
  {
    name: 'collectionDate',
    label: 'Collection Date',
    type: 'date',
    helperText: 'the date the flight occured',
  },
  {
    name: 'altitude',
    label: 'Altitude (m)',
    type: 'integer',
    helperText: 'The nominal height above ground level the UAV was flown at, in meters',
    minimum: 10,
    maximum: 120,
    interval: 10,
  },
  {
    name: 'gcpCount',
    label: 'GCP Count',
    type: 'integer',
    helperText: 'The number of ground control points used to orthorectify the image',
    minimum: 0,
    maximum: 10,
    interval: 1,
  },
  {
    name: 'comments',
    label: 'Comments',
    type: 'string',
    required: false,
    helperText: 'Miscellaneous notes about the flight or orthomosaic data',
  },
  {
    name: 'operator',
    label: 'Operator',
    type: 'string',
    helperText: 'The company-assigned user ID of the UAV pilot',
  },
]

export const dropdownProperties = [
  // Group 1
  {
    // Consult `crops()` for allowed values
    name: 'crop',
    label: 'Crop',
    type: 'string',
    helperText: 'The identifying name(s) of the land feature(s)',
  },
  {
    // Consult `growthStagesForCrop(crop)` for allowed values.
    name: 'growthStage',
    label: 'Growth Stage',
    type: 'string',
    helperText: 'The growth stage of the crop that was imaged',
  },
  {
    // Consult `traitRecipesForCropStage(crop, growthStage)` for allowed values
    name: 'traitRecipe',
    label: 'Trait Recipe',
    helperText: 'The analytical products that should be generated from this image.',
    type: 'string',
  },

  // Group 2
  {
    // Consult `featureTypes()` for allowed values.
    name: 'featureType',
    label: 'Feature Type',
    helperText: 'The type of land feature that was imaged',
    type: 'string',
  },
  {
    // Consult `searchFeatureNames(featureType)` for allowed values
    name: 'featureNames',
    label: 'Feature Names',
    type: 'autocomplete-array-string',
    helperText: 'The identifying name(s) of the land feature(s)',
  },
  {
    name: 'organization',
    label: 'Organization',
    type: 'string',
    helperText: 'The organization you belong to',
  },

  // Group 3
  {
    // Consult `sensors()` for allowed values.
    name: 'sensor',
    label: 'Sensor',
    type: 'string',
    helperText: 'The spectral bands captured by the sensor',
  },
  {
    // Consult bandsForSensor(sensor) for allowed values
    name: 'sensorBands',
    label: 'Sensor Bands',
    type: 'array-string',
    helperText: 'The spectral bands captured by the sensor',
  },

  // The following properties do not rely on each other

  {
    // Consult `workflows()` for allowed values
    name: 'workflow',
    label: 'Workflow',
    type: 'string',
    helperText: 'The processing workflow the image should be sent to',
  },
  {
    // Consult `regions()` for allowed values
    name: 'region',
    label: 'Region',
    type: 'string',
    helperText: 'The region the flight occurred within',
  },
  {
    name: 'originator',
    label: 'Originator',
    type: 'string',
    helperText: 'The company that employs the UAV pilot',
  },
]
