import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
} from '@mui/material'
import {blue, grey} from '@mui/material/colors'

import styled from 'styled-components'

import DialogMultiTagRemoveSelectionTableHead from './DialogMultiTagRemoveSelectionTableHead'

const StyledTableRow = styled(TableRow)`
  user-select: none;
  cursor: pointer;
  transition: background 0.25s ease-in-out;

  background-color: ${props => (props.$selected ? blue[300] : 'inherit')};
  color: ${props => (props.$disabled ? grey[200] : 'inherit')};

  .MuiTableCell-root {
    padding: 3px 16px;
  }

  .tag-cell {
    width: 490px;
  }

  &:hover {
    background-color: ${props => (props.$selected ? blue[200] : grey[200])};
  }
`

/**
 * Table which displays and allows removal of multiple tags
 */
function DialogMultiTagRemoveSelectionTable(props) {
  const {removeTags, tableHistogram} = props

  const [selectedSubset, setSelectedSubset] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState({
    column: 'tags',
    ascending: true,
  })

  function toggleRowSelection(tag) {
    const index = selectedSubset.indexOf(tag)

    const list = [...selectedSubset]

    if (index === -1) {
      list.push(tag)
    } else {
      list.splice(index, 1)
    }

    setSelectedSubset(list)
  }

  const rows = Object.entries(tableHistogram)
  function toggleSelectAll() {
    if (selectedSubset.length === rows.length) {
      setSelectedSubset([])
    } else {
      setSelectedSubset(Object.keys(tableHistogram))
    }
  }

  rows.sort((a, b) => {
    if (order.column === 'tags') {
      return a[0].toUpperCase() > b[0].toUpperCase() ? 1 : -1
    } else {
      return a[1] > b[1] ? 1 : -1
    }
  })

  if (!order.ascending) rows.reverse()

  const selectedRows = []
  for (let i = page * rowsPerPage; i < (page + 1) * rowsPerPage; i++) {
    if (rows.length > i) selectedRows.push(rows[i])
  }

  return (
    <div>
      <Paper>
        <DialogMultiTagRemoveSelectionTableHead
          numSelected={selectedSubset.length}
          rowCount={rows.length}
          onSelectAllClick={toggleSelectAll}
          removeTags={() => removeTags(selectedSubset)}
          setOrder={setOrder}
          order={order}
        />
        <TableContainer>
          <TableBody>
            {selectedRows.map(([tag, count]) => {
              const selectedRow = selectedSubset.indexOf(tag) !== -1

              return (
                <StyledTableRow
                  key={tag}
                  onClick={() => toggleRowSelection(tag)}
                  $selected={selectedRow}
                >
                  <TableCell>
                    <Checkbox color="primary" checked={selectedRow} />
                  </TableCell>
                  <TableCell className="tag-cell">{tag}</TableCell>
                  <TableCell>{count}</TableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={event => {
            setRowsPerPage(parseInt(event.target.value, 10))
            setPage(0)
          }}
        />
      </Paper>
    </div>
  );
}

DialogMultiTagRemoveSelectionTable.propTypes = {
  /** A series of keys and associated counts for each key */
  tableHistogram: PropTypes.shape({}).isRequired,
  /** Function that deletes a series of tags */
  removeTags: PropTypes.func.isRequired,
}

export default DialogMultiTagRemoveSelectionTable
