import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {TableHead, TableCell, TableRow, TableSortLabel, IconButton} from '@mui/material'
import {Save as SaveIcon} from '@mui/icons-material'
import styled from 'styled-components'

const StyledTableHeadRow = styled(TableRow)`
  .MuiTableCell-root {
    padding: 3px 16px;
  }

  .tag-cell {
    width: 405px;
  }
`

const TableStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    padding-left: 25px;
  }

  .action {
    margin-right: 10px;
  }
`

/** Table head row for the tags selection */
function DialogMultiTagEditSelectionTableHead(props) {
  const {numSelected, loading, setOrder, order, triggerEdit} = props
  const [editingTags, setEditingTags] = useState(false)

  function indicateSortOrder(column) {
    if (order.column !== column) return false
    return order.ascending ? 'asc' : 'desc'
  }

  const activeColumnSortProps = {
    active: true,
    direction: order.ascending ? 'asc' : 'desc',
  }

  const tableSortLabelTagProps = order.column === 'tags' ? activeColumnSortProps : {}
  const tableSortLabelCountProps = order.column === 'count' ? activeColumnSortProps : {}
  const disableTrigger = editingTags || loading || numSelected === 0

  return <>
    <TableStatus>
      <h3 className="title">
        {loading && 'Loading'}
        {editingTags && `Modifying ${numSelected} tag${numSelected !== 1 ? 's' : ''}...`}
        {!(loading || editingTags) &&
          `${numSelected} item${numSelected !== 1 ? 's' : ''} queued for modification`}
      </h3>
      <IconButton style={{marginRight: 12}} size="large">
        <SaveIcon
          color={numSelected === 0 ? 'inherit' : 'primary'}
          disabled={disableTrigger}
          onClick={() => {
            if (!disableTrigger) {
              triggerEdit()
              setEditingTags(true)
            }
          }}
        />
      </IconButton>
    </TableStatus>
    <TableHead>
      <StyledTableHeadRow>
        <TableCell width={64} />

        <TableCell align="left" sortDirection={indicateSortOrder('tag')} className="tag-cell">
          <TableSortLabel
            {...tableSortLabelTagProps}
            onClick={() =>
              setOrder({column: 'tags', ascending: !(order.column === 'tags' && order.ascending)})
            }
          >
            Tag
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" sortDirection={indicateSortOrder('count')}>
          <TableSortLabel
            {...tableSortLabelCountProps}
            onClick={() =>
              setOrder({
                column: 'count',
                ascending: !(order.column === 'count' && order.ascending),
              })
            }
          >
            Count
          </TableSortLabel>
        </TableCell>
      </StyledTableHeadRow>
    </TableHead>
  </>;
}

DialogMultiTagEditSelectionTableHead.propTypes = {
  /** The number of selected rows */
  numSelected: PropTypes.number.isRequired,
  /** The count of total possible rows */
  rowCount: PropTypes.number.isRequired,
  /** Changes the order of the columns displayed on the table */
  setOrder: PropTypes.func.isRequired,
  /** Usestate state of parent component */
  order: PropTypes.shape({
    column: PropTypes.string,
    ascending: PropTypes.bool,
  }).isRequired,
}

export default DialogMultiTagEditSelectionTableHead
