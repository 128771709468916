import {useContext} from 'react'
import {directoryPageContext} from '../context/directoryPageContext'

/** Contains useable API for directory page components */
function useDirectoryPageState() {
  const {state, dispatch} = useContext(directoryPageContext)

  function setDirectoryPageFilter(filter) {
    dispatch({type: 'set-filter', filter})
  }

  function setError(error) {
    dispatch({
      type: 'set-error',
      error,
    })
  }

  function setData(content) {
    dispatch({
      type: 'set-data',
      content,
    })
  }

  function incrementNumberItemsPopulated() {
    dispatch({
      type: 'increment-data-population-count',
    })
  }

  function itemPopulatingComplete() {
    dispatch({
      type: 'item-populating-complete',
    })
  }

  return {
    state,
    dispatch,
    setDirectoryPageFilter,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  }
}

export default useDirectoryPageState
