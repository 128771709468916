import React from 'react'
import {Snackbar} from '@mui/material'
import MuiAlert from '@mui/material/Alert'

import useToastNotification from '../hooks/useToastNotification'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

/*
  Standalone Toast Notification component used across the app.

*/
function ToastNotification() {
  const {
    toastState: {open, severity, timeout, message},
    resetNotification,
  } = useToastNotification()

  return (
    <Snackbar
      open={open}
      autoHideDuration={timeout}
      onClose={resetNotification}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Alert onClose={resetNotification} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default ToastNotification
