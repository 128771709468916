import React from 'react'
import {Button, DialogActions, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {closeUploader, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import useOrthoMosaicValidateTrigger from '../hooks/useOrthoMosaicValidateTrigger'

const IconGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
  margin-right: -5px;

  span {
    font-size: 14px !important;
  }
`

function UploaderDialogFooterOrthoMosaic() {
  const [orthoMosaicValidating, setOrthoMosaicValidating] = useOrthoMosaicValidateTrigger()
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  const availableWorkflowStages = useSelector(state => state.imageUploader.availableWorkflowStages)

  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeUploader())
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))

  const canUpload = availableWorkflowStages.indexOf(2) !== -1

  return (
    <DialogActions style={{flexDirection: 'row'}}>
      <>
        {workflowStage === 1 && (
          <>
            {orthoMosaicValidating ? (
              <Button disabled>
                <Typography variant="button">Validating</Typography>
              </Button>
            ) : (
              <Button
                onClick={() => setOrthoMosaicValidating(true)}
                disabled={orthoMosaicValidating}
              >
                <Typography color="primary" variant="button">
                  Validate
                </Typography>
              </Button>
            )}
            <Button
              disabled={availableWorkflowStages.indexOf(2) === -1}
              onClick={() => setUploaderStage(2)}
            >
              <IconGroup>
                <CloudUploadIcon color={canUpload ? 'primary' : 'inherit'} />
                <Typography color={canUpload ? 'primary' : 'inherit'} variant="button">
                  Upload
                </Typography>
              </IconGroup>
            </Button>
          </>
        )}
        <Button
          onClick={e => {
            handleClose(e)
          }}
          color="primary"
        >
          Close
        </Button>
      </>
    </DialogActions>
  )
}

export default UploaderDialogFooterOrthoMosaic
