export const nonDropdownProperties = [
  {
    name: 'experimentName',
    label: 'Experiment Name',
    type: 'string',
    helperText: 'The name that will identify these files',
  },
]

export const dropdownProperties = [
  {
    name: 'organization',
    label: 'Organization',
    type: 'string',
    helperText: 'The organization you belong to',
  },
]
