import styled from 'styled-components'
import {IconButton} from '@mui/material'

const StyledIconButton = styled(IconButton)`
  margin: 2px !important;
  padding: 15px !important;
  width: 1em;
  height: 1em;
  &.Mui-disabled .MuiSvgIcon-colorPrimary {
    color: var(--mdc-theme-text-disabled-on-dark) !important;
  }
`

export default StyledIconButton
