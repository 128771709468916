import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {Link, useLocation} from 'react-router-dom'
import {Error, OpenInNew, Warning} from '@mui/icons-material'
import styled from 'styled-components'

const WarningExplainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;

  align-items: center;
`

/**
 * Provides a table for all the items that won't allow tag adding
 */
function DialogDialogMultiTagAddWarning(props) {
  const {handleClose, numberItems, progressToNextStage, untaggableItems} = props
  const {pathname, search} = useLocation()

  const canProgress = numberItems > untaggableItems.length

  const DialogExplainer = () =>
    canProgress ? (
      <WarningExplainer>
        <Warning />
        <Typography>
          Some items in your selection aren&lsquo;t editable. You can view these items and why in
          the table below and choose whether you wish to proceed.
        </Typography>
      </WarningExplainer>
    ) : (
      <WarningExplainer>
        <Error />
        <Typography>
          All items in your selection aren&lsquo;t editable. You can view these items and why in the
          table below.
        </Typography>
      </WarningExplainer>
    )

  return (
    <>
      <DialogContent
        style={{
          width: 600,
          height: 340,
          display: 'grid',
          gridTemplateRows: '72px 1fr',
          gridRowGap: '5px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialogExplainer />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell color="primary">Item</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {untaggableItems.map(({message, id}) => {
              const hrefURL = new URL(window.location.href)
              const hrefParams = new URLSearchParams(hrefURL)
              hrefParams.set('id', id)
              hrefParams.set('parent', pathname)
              if (search) hrefParams.set('search', search)

              return (
                <TableRow key={id}>
                  <Link target="_blank" rel="noreferrer" to={`/item?${hrefParams}`}>
                    <TableCell>
                      <Typography color="primary" style={{fontSize: '0.7rem'}}>
                        {id}{' '}
                        <OpenInNew
                          color="primary"
                          style={{width: '18px', position: 'relative', top: '9px', left: '2px'}}
                        />
                      </Typography>
                    </TableCell>
                  </Link>
                  <TableCell>{message}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {canProgress ? (
          <>
            <Button onClick={handleClose}>cancel</Button>
            <Button color="primary" onClick={progressToNextStage}>
              proceed
            </Button>
          </>
        ) : (
          <Button onClick={handleClose}>close</Button>
        )}
      </DialogActions>
    </>
  )
}

DialogDialogMultiTagAddWarning.propTypes = {
  /** Closes the dialog */
  handleClose: PropTypes.func.isRequired,
  /** The total number of items selected */
  numberItems: PropTypes.number.isRequired,
  /** Progresses to the next stage of the dialog */
  progressToNextStage: PropTypes.func.isRequired,
  /** The items which cannot have tags */
  untaggableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
}

export default memo(DialogDialogMultiTagAddWarning)
