import React, {useState} from 'react'
import {Share as ShareIcon, DoneOutlined as CopiedIcon} from '@mui/icons-material'
import {Tooltip, IconButton} from '@mui/material'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import useToastNotification from '../hooks/useToastNotification'

function ControlBarIconShareUrl({urlToBeCopied}) {
  const [copied, setCopied] = useState(false)
  const {infoNotification} = useToastNotification()

  const changeButtonIcon = () => {
    setCopied(true)
    // Return indicator to previous position in 3s
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Tooltip title={copied ? 'Copied to Clipboard' : 'Share'} aria-label="share">
      <IconButton
        aria-label="Share"
        size="small"
        color="primary"
        onClick={() => {
          infoNotification('Link copied')
        }}
      >
        <CopyToClipboard text={urlToBeCopied} onCopy={changeButtonIcon}>
          {copied ? <CopiedIcon /> : <ShareIcon />}
        </CopyToClipboard>
      </IconButton>
    </Tooltip>
  )
}

export default ControlBarIconShareUrl
