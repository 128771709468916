import React, {useEffect, useState} from 'react'
import {DialogContent} from '@mui/material'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import UploaderMissionPackageUploadingPane from './UploaderMissionPackageUploadingPane'
import DialogContentContainer from './styled/DialogContentContainer'
import {Uploader} from './initializeHyperspectralUploader'
import {
  HyperspecUploader,
  VALID_AT_LEAST_ONE_FILE,
  VALID_AT_LEAST_ONE_VNIR,
  initializeHyperspectralUploader,
} from './initializeHyperspectralUploader'
import useToastNotification from '../hooks/useToastNotification'
import {handleSentryError} from '../utilities/sentryErrors'
import {addAvailableWorkflowStages, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import DirectorySelector from './DirectorySelector'
import {
  HyperspectralMetadataEditor,
  HyperspectralMetadataEditorValues,
} from './HyperspectralMetadataEditor'
import {queries, queryProfile} from '@monsantoit/profile-client'
import {ErrorBoundary} from 'react-error-boundary'

function UploaderMissionPackageUploadingPaneWrapper(props: {uploader: Uploader}) {
  function handleError(err) {
    handleSentryError(err, 'Error occurred in hyperspec mission uploader pane.')
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={handleError}>
      <UploaderMissionPackageUploadingPane {...props} />
    </ErrorBoundary>
  )
}

function Hyperspec() {
  const {errorNotification} = useToastNotification()
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)
  const dispatch = useDispatch()
  const {currentUser} = queries

  const [uploader, setUploader] = useState<HyperspecUploader | undefined>()
  const [files, setFiles] = useState<File[] | undefined>()
  const [geohash, setGeohash] = useState<string | undefined>()
  const [userId, setUserId] = useState<string>()

  function onChangeDirectory(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event?.target?.files) {
      errorNotification('No files found in directory.')
      return
    }
    const files = Array.from((event?.target as any)?.files)
    files.forEach(file => {
      const isFile = file instanceof File
      if (!isFile) {
        errorNotification('Failed to upload files.')
        return
      }
    })
    setFiles(files as File[])

    dispatch(addAvailableWorkflowStages([1]))
    dispatch(setUploaderWorkflowStage(1))
  }

  function onSubmitMetadata(values: HyperspectralMetadataEditorValues) {
    setGeohash(values.geohash)

    dispatch(addAvailableWorkflowStages([1, 2]))
    dispatch(setUploaderWorkflowStage(2))
  }

  useEffect(() => {
    async function updateUser() {
      const {
        getCurrentUser: {id},
      } = await queryProfile(currentUser())

      setUserId(id)
    }

    updateUser().catch(err => {
      errorNotification('Error getting your user ID. Please try again later.')
      handleSentryError(err, 'Error getting your user ID.')
    })
  }, [])

  useEffect(() => {
    if (!files?.length || !geohash || !userId) {
      return
    }

    initializeHyperspectralUploader({files, geohash, userId})
      .then(uploader => setUploader(uploader))
      .catch(err => {
        const details = getUploadError(err)
        errorNotification(details, {timeout: null})
        handleSentryError(err, details)
        dispatch(addAvailableWorkflowStages([0]))
        dispatch(setUploaderWorkflowStage(0))
      })
  }, [files, geohash, userId])

  return (
    <>
      <DialogContentContainer>
        <DialogContent>
          <FormContainer>
            <>
              {workflowStage === 0 && <DirectorySelector onChange={onChangeDirectory} />}
              {workflowStage === 1 && <HyperspectralMetadataEditor onSubmit={onSubmitMetadata} />}
              {workflowStage === 2 && (
                <UploaderMissionPackageUploadingPaneWrapper uploader={uploader} />
              )}
            </>
          </FormContainer>
        </DialogContent>
      </DialogContentContainer>
    </>
  )
}

function getUploadError(err: Error) {
  if (VALID_AT_LEAST_ONE_FILE === err?.message) {
    return VALID_AT_LEAST_ONE_FILE
  }
  if (VALID_AT_LEAST_ONE_VNIR === err?.message) {
    return VALID_AT_LEAST_ONE_VNIR
  }
  return 'Error occurred during upload.'
}

function fallbackRender({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>{error.message}</pre>
    </div>
  )
}

const FormContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default Hyperspec
