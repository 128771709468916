import {useEffect, useState} from 'react'
import {handleSentryError} from '../utilities/sentryErrors'
import {queryProfile} from '@monsantoit/profile-client'

type Group = {
  id: string
  name: string
  description: string
}

export default function useCurrentUserGroups(): {
  currentUserGroups: Group[]
  loading: boolean
  error?: Error
} {
  const [currentUserGroups, setCurrentUserGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  async function getUser() {
    const groupsUserIsMemberOf = `{
        getCurrentUser {
          groups {
            id
            name
          }
        }
      }`

    return queryProfile(groupsUserIsMemberOf)
  }

  useEffect(() => {
    getUser()
      .then(response => {
        setCurrentUserGroups(response.getCurrentUser.groups)
      })
      .catch(e => {
        const additionalErrorDetails =
          'There was an error retrieving the groups the user is apart of.'
        handleSentryError(e, additionalErrorDetails)
        setError(e)
      })
      .finally(() => setLoading(false))
  }, [])

  return {currentUserGroups, loading, error}
}
