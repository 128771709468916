import React, {SetStateAction, createContext, useReducer, useState} from 'react'
import { SearchParameters, Bbox } from './SearchParameters';
import { SearchAction } from './SearchAction';
import { searchReducer } from './searchReducer';

export const initialSearchParameters: SearchParameters = {
  bbox: [0, 0, 0, 0] as Bbox,
  collections: [],
  projects: [],
  properties: [],
  keywords: [],
  tags: [],
  fromDate: '',
  toDate: '',
}

type AdvancedSearchContext = {
  searchAttributes: SearchParameters
  dispatch: React.Dispatch<SearchAction>
  queryStringState: [string, React.Dispatch<string>]
  drawerState: [boolean, React.Dispatch<boolean>]
  triggerSearch: [boolean, React.Dispatch<boolean>]
}

const initialContext = {
  searchAttributes: initialSearchParameters,
  dispatch: {} as React.Dispatch<SearchAction>,
  queryStringState: {} as [string, React.Dispatch<SetStateAction<string>>],
  drawerState: {} as [boolean, React.Dispatch<SetStateAction<boolean>>],
  triggerSearch: {} as [boolean, React.Dispatch<SetStateAction<boolean>>],
}

const advancedSearchContext = createContext<AdvancedSearchContext>(initialContext)
const {Provider} = advancedSearchContext

function AdvancedSearchProvider({children}) {
  // search attributes for advanced searching
  const [searchAttributes, dispatch] = useReducer(searchReducer, initialSearchParameters)
  // Used to store current value of the query string to be used on "search"
  const queryStringState = useState('')
  // Used to toggle advanced search drawer
  const drawerState = useState(false)
  // Redirects when set to true
  const triggerSearch = useState(false)

  return (
    <Provider value={{searchAttributes, dispatch, queryStringState, drawerState, triggerSearch}}>
      {children}
    </Provider>
  )
}

export {advancedSearchContext, AdvancedSearchProvider}
