import React from 'react'
import PropTypes from 'prop-types'

import UploaderImagineMetadataFieldText from './UploaderImagineMetadataFieldText'
import UploaderImagineMetadataFieldNumber from './UploaderImagineMetadataFieldNumber'
import UploaderImagineMetadataFieldBoolean from './UploaderImagineMetadataFieldBoolean'
import UploaderImagineMetadataFieldArray from './UploaderImagineMetadataFieldArray'
import UploaderImagineMetadataFieldTime from './UploaderImagineMetadataFieldTime'

const fieldInputs = {
  number: ({fieldName, value, error, onChange, warning}) => (
    <UploaderImagineMetadataFieldNumber
      fieldName={fieldName}
      value={value}
      onChange={e => {
        onChange({[fieldName]: parseFloat(e.target.value)})
      }}
      error={error}
      warning={warning}
    />
  ),
  integer: ({fieldName, value, error, onChange, warning}) => (
    <UploaderImagineMetadataFieldNumber
      fieldName={fieldName}
      value={value}
      onChange={e => {
        onChangeInteger([fieldName], e.target.value, onChange) // eslint-disable-line
      }}
      error={error}
      warning={warning}
      integer
    />
  ),
  string: ({fieldName, value, error, onChange, warning}) => (
    <UploaderImagineMetadataFieldText
      fieldName={fieldName}
      value={value}
      error={error}
      warning={warning}
      onChange={e => {
        onChange({[fieldName]: e.target.value})
      }}
    />
  ),
  boolean: ({fieldName, value, onChange}) => (
    <UploaderImagineMetadataFieldBoolean
      fieldName={fieldName}
      value={value}
      onChange={event => {
        onChange({[fieldName]: event.target.value})
      }}
    />
  ),
  array: ({onChange, fieldName, error, warning, value}) => (
    <UploaderImagineMetadataFieldArray
      value={value}
      warning={warning}
      fieldName={fieldName}
      errorMessage={error}
      onChange={arrayOfItems => {
        onChange({[fieldName]: arrayOfItems})
      }}
    />
  ),
  // None of these should make it through
  object: ({fieldName, value, error, onChange}) => {
    return <span>an object</span>
  },
}

// On change event for number input that must output integer
// Truncates number to remove fractional/decimal
function onChangeInteger(fieldName, value, onChangeCallback) {
  const number = parseFloat(value)

  if (value.includes('.')) {
    // Truncate - remove fractional digits. Never rounds up
    const closestIntStr = Math.trunc(number)
    onChangeCallback({[fieldName]: closestIntStr})
  } else {
    onChangeCallback({[fieldName]: number})
  }
}

/**
 * Selects which property input is used for the Imagine Uploader
 */
function UploaderImagineMetadataInput({field, value, warning, onChange}) {
  /**
   * Some fields are treated as a datetime rather than a string which they profess to be.
   *
   * These field names are collected here
   *
   * */
  const dateTimeFields = ['datetime', 'created', 'updated', 'breedinggd:DateTaken']

  if (['provider', 'providers'].includes(field.attribute)) {
    return <></>
  } else if (dateTimeFields.includes(field.attribute)) {
    return (
      <UploaderImagineMetadataFieldTime
        field={field}
        onChange={onChange}
        value={value}
        warning={warning}
      />
    )
  } else if (field.type === undefined) {
    console.error('Unknown field type for field ', field)
    return <></>
  } else if (
    field.attribute !== 'project:name' &&
    ['number', 'integer', 'string', 'boolean', 'array', 'object'].indexOf(field.type) !== -1
  ) {
    return fieldInputs[field.type]({
      fieldName: field.attribute,
      field,
      value,
      error: warning?.message,
      warning: warning?.level === 'warning',
      onChange,
    })
  } else if (
    ['number', 'integer', 'string', 'boolean', 'array', 'object'].indexOf(field.type) === -1
  ) {
    console.error(`Unknown field type "${field.type}"`, field)
    return <></>
  } else {
    console.error('Unknown outcome')
    return <></>
  }
}

UploaderImagineMetadataInput.propTypes = {
  field: PropTypes.shape({}),
  value: PropTypes.any, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
  warning: PropTypes.shape({
    level: PropTypes.string,
    message: PropTypes.string,
  }),
}

export default UploaderImagineMetadataInput
