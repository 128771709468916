import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Button} from '@mui/material'

import DoneIcon from '@mui/icons-material/Done'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import styled from 'styled-components'

const Container = styled('div')`
  overflow: hidden;
  height: 40px;

  button {
    width: 100%;
  }

  input {
    position: relative;
    height: 0;
    width: 0;
    left: 200vw;
  }
`

const CopyIcon = ({icon}) => {
  switch (icon) {
    case 'copy':
    default:
      return <FileCopyIcon />
    case 'ok':
      return <DoneIcon />
  }
}

/*
 * A button that when triggered copies the metadata prop into a clipboard through a hidden input
 *
 */
function ItemLayoutCopyButton(props) {
  const hiddenInput = useRef(null)
  const [copyIndicatorIcon, setcopyIndicatorIcon] = useState('copy')
  const {disabled, metadata, children} = props

  const changeButtonIcon = () => {
    setcopyIndicatorIcon('ok')
    // Return indicator to previous position in 3s
    setTimeout(() => {
      setcopyIndicatorIcon('copy')
    }, 3000)
  }

  return (
    <Container>
      <CopyToClipboard text={JSON.stringify(metadata)} onCopy={changeButtonIcon}>
        <Button
          onClick={changeButtonIcon}
          variant="outlined"
          color="primary"
          startIcon={<CopyIcon icon={copyIndicatorIcon} />}
          disabled={disabled}
        >
          {children}
        </Button>
      </CopyToClipboard>
      <input id="hiddenInput" value={JSON.stringify(metadata)} ref={hiddenInput} readOnly />
    </Container>
  )
}

ItemLayoutCopyButton.propTypes = {
  disabled: PropTypes.bool,
  metadata: PropTypes.shape({}).isRequired,
}

ItemLayoutCopyButton.defaultProps = {
  disabled: false,
}

export default ItemLayoutCopyButton
