import React, {useContext, useEffect, useRef} from 'react'

import DirectoryLayout from '../layouts/DirectoryLayout'
import CommonLayout from '../layouts/CommonLayout'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {handleSentryError} from '../utilities/sentryErrors'

import useDirectoryPageState from '../hooks/useDirectoryPageState'
import {asyncThrottle} from '../utilities/async'
import getItemThumbnailUrl from '../utilities/getItemThumbnailUrl'
import useToastNotification from '../hooks/useToastNotification'

const DIRECTORY_PAGE_PROJECTS_CACHE_KEY = `DIRECTORY_PAGE_PROJECTS_CACHE_KEY`

function DirectoryPageProjects() {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    retrieveProjects,
  } = useContext(imagineServiceContext)

  const {
    state,
    dispatch,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  } = useDirectoryPageState()
  const mounted = useRef(false)
  const abortController = useRef(new AbortController())
  const {filter} = state
  const {errorNotification, warningNotification} = useToastNotification()

  useEffect(function () {
    mounted.current = true
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
      abortController?.current?.abort()
    }
  }, [])

  // Generates a list of parseable projects
  useEffect(() => {
    const gatherProjectElements = async () => {
      let projectsArray
      let cachedData

      try {
        cachedData = window.localStorage.getItem(DIRECTORY_PAGE_PROJECTS_CACHE_KEY)
        if (cachedData) {
          cachedData = JSON.parse(cachedData)
          dispatch({
            type: 'set-max-count',
            maxCount: cachedData.cnt,
          })
          if (mounted.current && cachedData?.projectElements) {
            setData(cachedData.projectElements)
            incrementNumberItemsPopulated()
          }
        }
      } catch (err) {
        const additionalErrorDetails = 'There was an error loading cached project data.'
        handleSentryError(err, additionalErrorDetails)
      }

      try {
        projectsArray = await retrieveProjects()
      } catch (e) {
        const additionalErrorDetails = 'There was an error retrieving projects.'
        handleSentryError(e, additionalErrorDetails)
        if (cachedData) {
          warningNotification(
            'Error checking for project updates. The server may be having trouble.'
          )
        } else {
          errorNotification('Error loading projects. The server may be having trouble.')
        }

        return
      }

      if (projectsArray.length === 0) setError('No projects are available to retrieve from Imagine')

      try {
        const projectElements = []
        if (projectsArray.length > 0 && mounted.current)
          dispatch({
            type: 'set-max-count',
            maxCount: projectsArray.length,
          })

        // eslint-disable-next-line
        async function expandProjectsArray(project) {
          const getEntitlements = await project.entitlements()
          const userEntitlements = []
          if (getEntitlements.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
          if (getEntitlements.currentUserHasEntitlement('DELETE')) userEntitlements.push('DELETE')
          if (getEntitlements.currentUserHasEntitlement('PUBLISH')) userEntitlements.push('PUBLISH')
          if (getEntitlements.currentUserHasEntitlement('DOWNLOAD'))
            userEntitlements.push('DOWNLOAD')

          const itemsIterator = project?.fetchItems({limit: 1})

          const projectDisplayElement = {
            name: project.id,
            title: project.title,
            entitlements: userEntitlements,
            description: '',
            count: await itemsIterator.count().catch(e => {
              const additionalErrorDetails = `Unable to retrieve count for project "${project.title}".`
              handleSentryError(e, additionalErrorDetails)
            }),
            thumbnail: null,
          }

          if (projectDisplayElement.count) {
            // Fetch thumbnail URL for a project by retrieving a single item within that project
            for await (const item of itemsIterator) {
              projectDisplayElement.thumbnail = getItemThumbnailUrl(item)
            }
          }

          projectElements.push(projectDisplayElement)
          if (!cachedData && mounted.current) {
            setData(projectElements)
            incrementNumberItemsPopulated()
          }
        }

        const projectQueryFns = projectsArray.map(project => {
          return () => expandProjectsArray(project)
        })
        const projectQueries = await asyncThrottle(projectQueryFns, {
          limit: 20,
          abortController: abortController.current,
          checkDelay: 20,
        })
        Promise.allSettled(projectQueries)
          .then(() => {
            if (mounted.current) {
              setData(projectElements)
              itemPopulatingComplete()
              window.localStorage.setItem(
                DIRECTORY_PAGE_PROJECTS_CACHE_KEY,
                JSON.stringify({
                  cnt: projectsArray.length,
                  projectElements: projectElements,
                })
              )
            }
          })
          .catch(e => handleSentryError(e))
      } catch (e) {
        if (mounted.current) {
          const details = 'There was an error loading projects.'
          handleSentryError(e, details)
          errorNotification(details)
        }
      }
    }

    if (!(globalLoading || globalLoadingError) && mounted.current) gatherProjectElements()
  }, [globalLoading, globalLoadingError, mounted.current])

  const filteredContent =
    state.data?.filter(
      ({name, title}) =>
        title?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase()) ||
        name?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase())
    ) ?? []

  return (
    <CommonLayout>
      <DirectoryLayout
        filteredContent={filteredContent}
        loading={state.loading}
        error={state.error || globalLoadingError}
        populating={state.itemsPopulated < state.maxCount}
        numPossibleElements={state.data?.length ?? 0}
        maxCount={state.maxCount}
        contentType="projects"
      />
    </CommonLayout>
  )
}

export default DirectoryPageProjects
