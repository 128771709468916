import React, {memo, useContext} from 'react'

import PropTypes from 'prop-types'

import useMultipleSelect from '../hooks/useMultipleSelect'

import CardGalleryViewItem from './CardGalleryViewItem'
import CardGalleryViewItemMultipleSelect from './CardGalleryViewItemMultipleSelect'
import {galleryItemContext} from '../context/galleryItemContext'
import CardGalleryViewItemSkeleton from './CardGalleryViewItemSkeleton'

/**
 * Loads and feeds content for an item in any gallery view
 * */
const GalleryViewGridItem = function (props) {
  const {addItemToAlbum, canRemoveAlbumItem, cardWidth, item, selectMode, fullSearchTerms} = props
  const {loading} = useContext(galleryItemContext)

  const {selectedItems, toggleItem} = useMultipleSelect()
  const selectedIds = selectedItems?.map(i => i.id)

  // Load item content

  if (loading) return <CardGalleryViewItemSkeleton width={cardWidth} />

  const checkSelected = itemId => selectedIds.indexOf(itemId) !== -1

  function toggleGridItem(imagineItem) {
    if (selectMode) {
      toggleItem(imagineItem)
    }
  }

  return selectMode ? (
    <CardGalleryViewItemMultipleSelect
      onToggle={() => toggleGridItem(item)}
      selected={checkSelected(item.id)}
      width={cardWidth}
    />
  ) : (
    <CardGalleryViewItem
      addItemToAlbum={addItemToAlbum}
      canRemoveAlbumItem={canRemoveAlbumItem}
      width={cardWidth}
      fullSearchTerms={fullSearchTerms}
    />
  )
}

GalleryViewGridItem.propTypes = {
  /** Allows removal of item from current album. Only enabled in album gallery pages */
  canRemoveAlbumItem: PropTypes.bool,
  /** The width of the card in px. Calculated in the parent */
  cardWidth: PropTypes.number.isRequired,
  /** Imagine JS SDK Item */
  item: PropTypes.shape({}).isRequired,
  /** Informs when multiple selection mode is set */
  selectMode: PropTypes.bool.isRequired,
  /* The basic search search term */
  fullSearchTerms: PropTypes.string.isRequired,
}

GalleryViewGridItem.defaultProps = {
  canRemoveAlbumItem: false,
}

export default memo(GalleryViewGridItem)
