import React, {useState} from 'react'
import {useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  height: 250px;
  padding: 8px 0 8px 0;
`

function ItemMetadataSummaryCarousel({assetThumbnailUrls}) {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = assetThumbnailUrls.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    <Box sx={{flexGrow: 1}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{assetThumbnailUrls[activeStep]?.assetName}</Typography>
      </Paper>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {assetThumbnailUrls.map((step, index) => (
          <BoxWrapper key={step.assetName}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
                src={step.thumbnailUrl}
                alt={step.assetName}
              />
            ) : null}
          </BoxWrapper>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  )
}

ItemMetadataSummaryCarousel.propTypes = {
  assetThumbnailUrls: PropTypes.array,
}

export default ItemMetadataSummaryCarousel
