import React, {useEffect, useReducer, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import UploaderMissionPackageMetadataFormElement from './UploaderMissionPackageMetadataFormElement'

const HiddenElement = styled('div')`
  width: 0px;
  height: 0px;
  color: white;
  font-size: 0.00001px;
`

const FormContainer = styled('section')`
  width: 830px;
  min-height: 280px;

  padding: 10px;
  display: grid;
  margin: 20px auto 0 auto;

  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  grid-auto-rows: ${props => (props.defects === 'true' ? '95px' : '75px')};
`

function reducer(state, action) {
  switch (action.type) {
    case 'set-initial-state':
      return {...state, metadataValues: action.metadata}
    case 'update-element':
      return {
        lastKey: action.key,
        lastValue: action.value,
        metadataValues: {...state.metadataValues, [action.key]: action.value},
      }

    default:
      throw new Error(
        `Unknown reducer call "${action.type}" encountered in UploaderMissionPackageMetadaForm component`
      )
  }
}

/*
 *
 * Displays metadata form elements for the mission package uploader
 *
 * Updates form on change.
 */
function UploaderMissionPackageMetadataForm(props) {
  const {uploader, metadata, defects, validate} = props

  const reducerInitialValues = {
    lastKey: '',
    lastValue: null,
    metadataValues: Object.fromEntries(metadata.form.map(x => [x.key, x.value])),
  }
  const [{metadataValues, lastKey, lastValue}, dispatch] = useReducer(reducer, reducerInitialValues)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (metadata && lastKey) {
      try {
        metadata.properties[lastKey].value = lastValue
      } catch (e) {
        dispatch({
          type: 'update-element',
          key: lastKey,
          value: metadata.properties[lastKey].value,
        })
      }

      validate()

      setCount(count + 1)
    }
  }, [metadataValues, metadata])

  const formElements = metadata
    ? Object.entries(metadata.properties).map(i => ({
        name: i[0],
        ...i[1],
      }))
    : []

  return (
    <>
      <HiddenElement>{count}</HiddenElement>
      <FormContainer defects={defects.toString()}>
        {formElements.map((formElement, index) => (
          <UploaderMissionPackageMetadataFormElement
            name={formElement.name}
            elementInformation={formElement}
            value={metadataValues[formElement.name]}
            elementBlurHandler={elementValue => {
              dispatch({type: 'update-element', key: formElement.name, value: elementValue})
            }}
            onKeyUpHandler={elementValue => {
              dispatch({type: 'update-element', key: formElement.name, value: elementValue})
            }}
            key={`mission-package-form-${index}-${formElement.name}`}
          />
        ))}
      </FormContainer>
    </>
  )
}

UploaderMissionPackageMetadataForm.propTypes = {
  metadata: PropTypes.shape({}).isRequired,
  defects: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  uploader: PropTypes.any,
}

export default UploaderMissionPackageMetadataForm
