import {TileLayer} from 'leaflet'
import {useLeaflet} from 'react-leaflet'

import bingMaps from '../utilities/bingMapService'

const BingLayer = TileLayer.extend({
  getAttribution() {
    return bingMaps.resource.imageryProviders.map(x => x.attribution).join(', ')
  },
  getTileUrl(coords) {
    const quadkey = quadKey(coords)
    const {imageUrl, imageUrlSubdomains} = bingMaps.resource
    const subdomain = randomChoice(imageUrlSubdomains)
    const substituted = imageUrl.replace('{subdomain}', subdomain).replace('{quadkey}', quadkey)
    return substituted + '&dpi=d1&device=mobile'
  },
})

export default function BingBasemap() {
  const {map} = useLeaflet()
  const layer = new BingLayer()
  layer.addTo(map)
  return null
}

function quadKey(coords) {
  const {x, y, z} = coords
  let quadkey = ''
  for (let i = z; i > 0; i--) {
    let digit = 0
    const mask = 1 << (i - 1)
    if (x & mask) {
      digit += 1
    }
    if (y & mask) {
      digit += 2
    }
    quadkey += digit
  }
  return quadkey
}

function randomChoice(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}
