import {handleSentryError} from './sentryErrors'

export async function asyncThrottle(fns, options) {
  if (!fns?.length) {
    return []
  }
  const defaultOptions = {limit: 20, abortController: new AbortController(), checkDelay: 20}
  const {limit, abortController, checkDelay} = {...defaultOptions, ...options}
  const {signal} = abortController

  let cnt = 0
  const ready = () => {
    if (cnt < limit) {
      return cnt
    }
    return new Promise(resolve => {
      const iv = setInterval(() => {
        if (cnt < limit) {
          resolve(cnt)
          clearInterval(iv)
        }
      }, checkDelay)
    })
  }

  const results = []
  for (let i = 0; i < fns.length; i++) {
    if (signal.aborted) {
      throw new Error(signal.reason)
    }
    await ready()
    cnt++
    const result = fns[i]()
      .then(res => {
        cnt--
        return res
      })
      .catch(err => {
        handleSentryError(err)
        cnt--
        return null
      })
    results.push(result)
  }
  return results
}
