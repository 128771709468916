import {useReducer} from 'react'
import useToastNotification from './useToastNotification'
import * as Sentry from '@sentry/react'
import {handleSentryError} from '../utilities/sentryErrors'
import {createNextFactory} from '../utilities/infiniteScroll'
import type {Item, ItemListing} from '@bayer-int/imagine-sdk-browser'

export const NUMBER_RESULTS_PER_BATCH = 50

type GalleryPageState = {
  exactMatch: Item
  exactMatchByProperty: Item
  items: Item[]
  numberResultingItems: number
  error: any
  initialLoading: boolean
  loading: boolean
  hasMore: boolean
}

const initialState: GalleryPageState = {
  // Imagine item found in basic search through UUID
  exactMatch: null,
  // Imagine item found in basic search through property match
  exactMatchByProperty: null,
  // Imagine items that result from a search
  items: [],
  // Initially set to zero to trigger implicit false on first load
  numberResultingItems: 0,
  // Any error that needs to be visualized.
  error: null,
  // Triggers the initial loading spinner in the page components.
  initialLoading: true,
  // Triggers the "loading" state of the load more button at the bottom of the gallery
  loading: false,
  /**
   *   - Tells the page component how many items to retrieve.
   *   - A change to this value triggers a reload
   *   - It's the product of the page number (from 1) and the number results per batch
   */
  hasMore: true,
}

function reducer(state: GalleryPageState, action: any) {
  switch (action.type) {
    case 'reset-search':
      return {...initialState}

    case 'set-number-items-to-retrieve':
      return {
        ...state,
        loading: true,
      }

    case 'set-error':
      return {
        ...state,
        loading: false,
        initialLoading: false,
        error: action.error,
      }
    case 'set-number-possible-items':
      return {
        ...state,
        numberResultingItems: action.numberResultingItems,
      }

    case 'exact-basic-search-match':
      return {...state, exactMatch: action.exactMatch}

    case 'exact-basic-search-match-by-property':
      return {...state, exactMatchByProperty: action.exactMatch}

    case 'set-items':
      return {
        ...state,
        loading: false,
        initialLoading: false,
        items: action.items ?? [],
      }

    case 'set-more':
      return {
        ...state,
        hasMore: action.more,
      }

    case 'set-loading':
      return {
        ...state,
        loading: action.loading,
      }

    default:
      throw new Error('Unknown reducer case in AdvancedSearchGalleryPage component ', action)
  }
}

/**
 * Maintains state for each of the gallery page components:
 * 1. AdvancedSearchGalleryPage
 * 2. BasicSearchGalleryPage
 * 3. ProjectGalleryPage
 * 4. CollectionGalleryPage
 */
function useGalleryPageState(itemListing: ItemListing) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    exactMatch,
    exactMatchByProperty,
    items,
    numberResultingItems,
    error,
    initialLoading,
    loading,
    hasMore,
  } = state
  const {successNotification, errorNotification} = useToastNotification()

  const setItems = (newItems: Item[]) => dispatch({type: 'set-items', items: newItems})
  const updateItems = (update: (items: Item[]) => any) =>
    dispatch({type: 'set-items', items: update(items)})
  const setLoading = (loading: boolean) => dispatch({type: 'set-loading', loading})
  const setHasMore = (more: boolean) => dispatch({type: 'set-more', more})

  const next = createNextFactory({
    itemListing,
    setLoading,
    setData: updateItems,
    offset: 100,
    reverse: false,
    hasMore,
    setHasMore,
  })

  const setServerError = () => {
    const errorMessage =
      'An error occured while attempting to retrieve items. If this continues, please contact Location360 Support.'
    const errorObject = {
      type: 'set-error',
      error: {
        title: 'Server error',
        description: errorMessage,
      },
    }
    dispatch(errorObject)
    Sentry.captureMessage(errorMessage)
  }

  const setClientError = () => {
    const errorMessage = `An error occured while attempting to retrieve this collection. Please contact Location360 Support.`
    const errorObject = {
      type: 'set-error',
      error: {
        title: 'Client error',
        description: errorMessage,
      },
    }
    dispatch(errorObject)
    Sentry.captureMessage(errorMessage)
  }

  const setNotFoundError = (name: string) => {
    const errorMessage = `We could not retrieve the project "${name}". If you believe this is in error, please contact Location360 Support.`
    const errorObject = {
      type: 'set-error',
      error: {
        title: 'Project not found',
        description: errorMessage,
      },
    }
    dispatch(errorObject)
    Sentry.captureMessage(errorMessage)
  }

  const setNumberItemsPossible = (number: number) =>
    dispatch({
      type: 'set-number-possible-items',
      numberResultingItems: number,
    })

  const resetSearch = () => {
    dispatch({type: 'reset-search'})
  }

  const setExactMatch = (match: Item) =>
    dispatch({type: 'exact-basic-search-match', exactMatch: match})

  const setExactMatchByProperty = (match: Item) =>
    dispatch({type: 'exact-basic-search-match-by-property', exactMatchByProperty: match})

  // Removing the item from display before deleting ensures app does not crash on re-render
  function removeItem(itemForRemoval: Item) {
    setItems(items.filter((item: Item) => item.id !== itemForRemoval.id))
    itemForRemoval
      .remove()
      .then(() => {
        successNotification('Item deleted')
      })
      .catch(e => {
        const additionalErrorDetails = `Unable to delete item ${itemForRemoval.id}.`
        handleSentryError(e, additionalErrorDetails)
        errorNotification(e.message)
      })
  }

  return {
    error,
    exactMatch,
    exactMatchByProperty,
    items,
    initialLoading,
    loading,
    numberResultingItems,
    removeItem,
    resetSearch,
    setExactMatch,
    setExactMatchByProperty,
    setItems,
    setNotFoundError,
    setServerError,
    setClientError,
    setNumberItemsPossible,
    next,
    hasMore,
    setHasMore,
    setLoading,
    updateItems,
  }
}

export default useGalleryPageState
