import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/react'
import {TextField} from '@mui/material'

// Exported for testing
export const orange = '#FFA500'
export const red = '#b00020' // current value for var(--mdc-theme-error)

const acquireValidationColor = warning => (warning ? orange : red)

/**
 * Numeric input or uploader used for the Imagine API (c3)
 */
function UploaderImagineMetadataFieldNumber({fieldName, value, error, onChange, warning, integer}) {
  const color = acquireValidationColor(warning)
  const root = css`
    & .Mui-error {
      color: ${color};
    }
    & .MuiFormHelperText-root {
      color: ${color};
    }
    & .MuiInput-underline.Mui-error:after {
      borderbottomcolor: ${color};
    }
  `

  return (
    <TextField
      id={`number-${fieldName}`}
      label={fieldName}
      value={value}
      onChange={onChange}
      type="number"
      step={integer ? 1 : 0.1}
      error={!!error}
      helperText={warning ? `(warning) ${error}` : error}
      style={root}
      variant="standard"
    />
  )
}

UploaderImagineMetadataFieldNumber.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  warning: PropTypes.bool,
  integer: PropTypes.bool,
}

UploaderImagineMetadataFieldNumber.defaultProps = {
  warning: false,
  error: '',
  value: 0,
  integer: false,
}

export default UploaderImagineMetadataFieldNumber
