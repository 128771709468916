import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Edit as EditIcon} from '@mui/icons-material'
import styled from 'styled-components'

import {imagineServiceContext} from '../context/imagineServiceContext'

const IconContainer = styled.div`
  position: relative;
  width: 0;
  height: 0;
  left: 140px;
  top: 140px;

  .badge {
    background: var(--mdc-theme-primary);
    width: 35px;
    height: 35px;
    border-radius: 10px 0 0 0;
    border-left: 1px solid white;
    border-top: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiSvgIcon-root {
    color: white;
  }
`

function DirectoryCardOwnershipAlbum(props) {
  const [canModify, setCanModify] = useState(false)
  const {createdBy} = props
  const {retrieveSession} = useContext(imagineServiceContext)

  useEffect(() => {
    retrieveSession().then(session => {
      setCanModify(session.userId === createdBy)
    })
  }, [])

  return canModify ? (
    <IconContainer>
      <div className="badge">
        <EditIcon fontSize="small" />
      </div>
    </IconContainer>
  ) : (
    <></>
  )
}

DirectoryCardOwnershipAlbum.propTypes = {
  createdBy: PropTypes.string.isRequired,
}

export default DirectoryCardOwnershipAlbum
