import {getPreferencesValue, setPreferencesValue} from '../utilities/userPreferences'
import {getBearerToken} from '../utilities/initializeProfile'

/**
 * Returns a user's pinned collections and functions to add/remove collections
 * */

function useCollectionPin() {
  async function getPinnedCollections() {
    return (await getPreferencesValue('pinned-collections')) || []
  }

  function addToPinnedCollections(pinnedCollections, collectionToAdd) {
    const updatedCollection = [...new Set([...pinnedCollections, collectionToAdd])]
    setPreferencesValue('pinned-collections', updatedCollection)
  }

  function removeFromPinnedCollections(pinnedCollections, collectionToRemove) {
    const updatedCollection = pinnedCollections.filter(c => c !== collectionToRemove)
    setPreferencesValue('pinned-collections', updatedCollection)
  }

  return {getPinnedCollections, addToPinnedCollections, removeFromPinnedCollections}
}

export default useCollectionPin
