import {useContext} from 'react'
import {galleryPageContext} from '../context/galleryPageContext'

/** custom hook to handle bulk selection used in gallery view grid images */
function useMultipleSelect() {
  const {selectedItems, setSelectedItems} = useContext(galleryPageContext)

  const clearItems = () => setSelectedItems([])

  const addItemToList = item => {
    setSelectedItems([...selectedItems, item])
  }

  const removeItemFromList = id => {
    if (selectedItems.indexOf(id) === -1) return
    const items = [...selectedItems]

    items.splice(selectedItems.indexOf(id), 1)

    setSelectedItems(items)
  }

  const toggleItem = item => {
    if (selectedItems.map(i => i.id).indexOf(item.id) === -1) {
      addItemToList(item)
    } else {
      removeItemFromList(item)
    }
  }

  const toggleSelectAll = itemList => {
    setSelectedItems(itemList.length === selectedItems.length ? [] : itemList)
  }

  return {selectedItems, addItemToList, removeItemFromList, toggleItem, clearItems, toggleSelectAll}
}
export default useMultipleSelect
