import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {DialogContent} from '@mui/material'
import {queries, queryProfile} from '@monsantoit/profile-client'

import {getBearerToken} from '../utilities/initializeProfile'
import {isProd} from '../utilities/serviceBindings'
import DialogContentContainer from './styled/DialogContentContainer'
import UploaderExperimentalUploadingPane from './UploaderExperimentalUploadingPane'
import UploaderExperimentalFileSelector from './UploaderExperimentalFileSelector'
import UploaderExperimentalMetadataForm from './UploaderExperimentalMetadataForm'
import UploaderDialogFooterExperimental from './UploaderDialogFooterExperimental'
import CardGenericError from './CardGenericError'
import {handleSentryError} from '../utilities/sentryErrors'

const FormContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`

function UploaderExperimental() {
  const [uploader, setUploader] = useState(null)
  const [criticalError, setCriticalError] = useState('')

  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  async function initializeUploader(event) {
    try {
      const {
        getCurrentUser: {id: username},
      } = await queryProfile(queries.currentUser())

      const source = await fetch(
        'https://unpkg.loc360.monsanto.net/@bayer-int/experimental-image-uploader@1',
        {
          headers: {
            Authorization: `Bearer ${await getBearerToken()}`,
          },
        }
      ).then(r => r.blob())

      const UploaderModule = await import(/* webpackIgnore: true */ URL.createObjectURL(source))

      const uploaderInstance = await UploaderModule.Uploader({
        authorization: async () => `Bearer ${await getBearerToken()}`,
        environment: isProd ? 'prod' : 'nonprod',
        files: event.target.files,
        remoteLogging: true,
        username,
      })

      setUploader(uploaderInstance)

      return uploaderInstance
    } catch (e) {
      const additionalErrorDetails = 'There was an error initializing the uploader.'
      handleSentryError(e, additionalErrorDetails)
      setCriticalError(e.message)
    }
  }

  return (
    <>
      <DialogContentContainer>
        <DialogContent>
          <FormContainer>
            {!criticalError ? (
              <>
                {workflowStage === 0 && (
                  <UploaderExperimentalFileSelector initializeUploader={initializeUploader} />
                )}
                {workflowStage === 1 && (
                  <UploaderExperimentalMetadataForm
                    metadata={uploader?.metadata}
                    setCriticalError={setCriticalError}
                  />
                )}
                {workflowStage === 2 && (
                  <UploaderExperimentalUploadingPane
                    uploader={uploader}
                    setCriticalError={setCriticalError}
                  />
                )}
              </>
            ) : (
              <CardGenericError
                title="Unable to process directory"
                description={criticalError}
                caption="If this error persists, please contact Location360 Support"
              />
            )}
          </FormContainer>
        </DialogContent>
      </DialogContentContainer>
      <UploaderDialogFooterExperimental />
    </>
  )
}

export default UploaderExperimental
