import React from 'react'

import {green, blue, orange, blueGrey, grey} from '@mui/material/colors/'
import CssBaseline from '@mui/material/CssBaseline'

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import {ThemeProvider} from 'styled-components'

import DialogImagineUploader from '../components/DialogImagineUploader'
import ToastNotification from '../components/ToastNotification'
import ControlBar from '../components/ControlBar'
import LayoutBreadcrumbs from '../components/LayoutBreadcrumbs'

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: green,
    grey,
    status: {
      danger: orange,
    },
    background: {
      default: '#f0f2f5',
    },
    subduedText: blueGrey['300'],
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
})

if (window && process?.env?.NODE_ENV === 'development') window.theme = theme

/** A common layout shared by all the app's components */
const CommonLayout = ({children}) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <>
            <ControlBar />
            <LayoutBreadcrumbs />

            <>{children}</>

            {/* Dialog Components go here: */}
            <DialogImagineUploader />
            <ToastNotification />
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
)

export default CommonLayout
