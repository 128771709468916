import React, {useState} from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

function UploaderOrthoMosaicMetadataFieldMultipleAutocomplete(props) {
  const {
    errorMessage,
    helperText,
    errorLevel,
    label,
    disabled,
    dropdownOptions: dropdownValues,
    name,
    updateValue,
    reactRef,
    value,
    retrieveFeatureNames,
  } = props
  const [dropdownOptions, setDropdownOptions] = useState(dropdownValues ?? [])

  function updateDropdownOptions(e) {
    retrieveFeatureNames(e.target.value).then(options => setDropdownOptions(options))
  }

  return (
    <FormControl
      ref={reactRef}
      className="bottom-row"
      key={`dropdown-field-${name}-autocomplete`}
      error={errorLevel === 'error'}
      disabled={disabled}
    >
      <Autocomplete
        disabled={disabled}
        multiple
        options={dropdownOptions}
        onChange={(_event, newValue) => {
          updateValue(newValue)
        }}
        value={value}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            onChange={updateDropdownOptions}
            helperText="Type to view options"
          />
        )}
      />
      {errorLevel === 'error' ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

UploaderOrthoMosaicMetadataFieldMultipleAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  dropdownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  reactRef: PropTypes.shape({}).isRequired,
  retrieveFeatureNames: PropTypes.func,
  updateValue: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
}

UploaderOrthoMosaicMetadataFieldMultipleAutocomplete.defaultProps = {
  disabled: false,
  errorLevel: '',
  errorMessage: '',
  helperText: '',
  retrieveFeatureNames: () => {},
  updateValue: () => {},
}

export default UploaderOrthoMosaicMetadataFieldMultipleAutocomplete
