import React from 'react'
import PropTypes from 'prop-types'
import {TextField} from '@mui/material'
import styled from 'styled-components'

const ExtentDisplayElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiFormControl-root.MuiTextField-root {
    width: 140px;
    margin: 4px 4px 8px 4px;
  }
`

/**
 *
 * Number fields that indicate current selected extent
 */
function AdvancedSearchSelectorExtentNumberInput(props) {
  const {bbox} = props

  return (
    <ExtentDisplayElement>
      <TextField label="Min Longitude" value={bbox?.xMin ?? ''} disabled variant="standard" />
      <TextField label="Min Lattitude" value={bbox?.yMin ?? ''} disabled variant="standard" />
      <TextField label="Max Longitidude" value={bbox?.xMax ?? ''} disabled variant="standard" />
      <TextField label="Max Lattitude" value={bbox?.yMax ?? ''} disabled variant="standard" />
    </ExtentDisplayElement>
  )
}

AdvancedSearchSelectorExtentNumberInput.propTypes = {
  bbox: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
  }),
}

AdvancedSearchSelectorExtentNumberInput.defaultProps = {
  bbox: {yMin: null, xMin: null, xMax: null, yMax: null},
}

export default AdvancedSearchSelectorExtentNumberInput
