import React from 'react'
import PropTypes from 'prop-types'
import useToastNotification from '../hooks/useToastNotification'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'

const CopyableTextSpan = styled.span`
  cursor: pointer;
`

/**
 * A string of text that when clicked, copies to the clipboard
 */
function TextCopyable(props) {
  const {value} = props
  const {infoNotification} = useToastNotification()

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => infoNotification(`"${value}" copied to clipboard"`)}
    >
      <CopyableTextSpan>{value}</CopyableTextSpan>
    </CopyToClipboard>
  )
}

TextCopyable.propTypes = {
  value: PropTypes.string.isRequired,
}

export default TextCopyable
