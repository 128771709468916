import React from 'react'
import {AdvancedSearchProvider} from '../context/search/advancedSearchContext'
import AdvancedSearchDrawer from './AdvancedSearchDrawer'

/** Contains provider for advanced search drawer */
export default function AdvancedSearchContainer() {
  return (
    <AdvancedSearchProvider>
      <AdvancedSearchDrawer />
    </AdvancedSearchProvider>
  )
}
