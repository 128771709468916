import {getBearerToken} from './initializeProfile'
import {handleSentryError} from './sentryErrors'

const exports = {ImageViewer: null}

export default exports

async function loadModule() {
  const source = await fetch('https://unpkg.loc360.monsanto.net/@bayer-int/slippy-image-viewer@3', {
    headers: {
      Authorization: `Bearer ${await getBearerToken()}`,
    },
  })
    .then(r => r.blob())
    .catch(e => {
      const additionalErrorDetails = 'There was an error encountered with the image viewer service.'
      handleSentryError(e, additionalErrorDetails)
    })
  const {ImageViewer} = await import(/* webpackIgnore: true */ URL.createObjectURL(source))
  exports.ImageViewer = ImageViewer
}

export const imageViewerLoaded = loadModule()
