import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import {DirectoryPageProvider} from '../context/directoryPageContext'
import {ItemProvider} from '../context/itemContext'

import ItemPage from './ItemPage'

import DirectoryPageAlbums from './DirectoryPageAlbums'
import DirectoryPageCollections from './DirectoryPageCollections'
import DirectoryPageProjects from './DirectoryPageProjects'

import GalleryPageAlbum from './GalleryPageAlbum'
import GalleryPageAdvancedSearch from './GalleryPageAdvancedSearch'
import GalleryPageBasicSearch from './GalleryPageBasicSearch'
import GalleryPageCollection from './GalleryPageCollection'
import GalleryPageProject from './GalleryPageProject'

import Page404 from './404Page'

import {isLocal} from '../utilities/serviceBindings'

export default function Router() {
  const Item = () => (
    <ItemProvider>
      <ItemPage />
    </ItemProvider>
  )

  const AlbumDirectory = () => (
    <DirectoryPageProvider contentType="album">
      <DirectoryPageAlbums />
    </DirectoryPageProvider>
  )

  const CollectionDirectory = () => (
    <DirectoryPageProvider contentType="collection">
      <DirectoryPageCollections />
    </DirectoryPageProvider>
  )

  const ProjectDirectory = () => (
    <DirectoryPageProvider contentType="project">
      <DirectoryPageProjects />
    </DirectoryPageProvider>
  )

  return (
    <BrowserRouter basename={isLocal ? '/imagine' : '/'}>
      <Routes>
        <Route path="/item" element={<Item />} />
        <Route path="/project/:project" element={<GalleryPageProject />} />
        <Route path="/collection/:collection" element={<GalleryPageCollection />} />
        <Route path="/album/:album" element={<GalleryPageAlbum />} />
        <Route path="/search/:searchTerm" element={<GalleryPageBasicSearch />} />
        <Route path="/advanced-search" element={<GalleryPageAdvancedSearch />} />
        <Route path="/directory/albums" element={<AlbumDirectory />} />
        <Route path="/directory/collections" element={<CollectionDirectory />} />
        <Route path="/directory/projects" element={<ProjectDirectory />} />
        <Route path="/" element={<ProjectDirectory />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}
