import React, {useContext, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'

import {albumExportContext} from '../context/albumExportContext'

const cellStyle = {paddingTop: 0, paddingBottom: 0}

function DialogExportAlbumAttributeSelection(props) {
  const {itemSchemas} = props

  const {
    attributeState: [selectedAttributes, setSelectedAttributes],
    attributeListPage: [page, setPage],
  } = useContext(albumExportContext)

  const tableContainerRef = useRef(null)

  const rows = [...itemSchemas].map(({name, type}, index) => ({
    id: index,
    name,
    type,
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  function toggleRow(attribute) {
    const index = selectedAttributes.indexOf(attribute)

    if (index === -1) {
      setSelectedAttributes([...selectedAttributes, attribute])
    } else {
      const newAttributes = [...selectedAttributes]
      newAttributes.splice(index, 1)
      setSelectedAttributes(newAttributes)
    }
  }

  function toggleAllRows() {
    if (selectedAttributes.length === rows.length) {
      setSelectedAttributes([])
    } else {
      setSelectedAttributes(rows.map(i => i.name))
    }
  }

  const rowsPerPage = 8

  return (
    <Paper sx={{width: '100%'}}>
      <TableContainer sx={{maxHeight: '440px'}} id="scrollTable" ref={tableContainerRef}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{minWidth: '50px'}} align="center" sx={cellStyle}>
                <Checkbox
                  color="default"
                  onClick={toggleAllRows}
                  checked={selectedAttributes.length === rows.length}
                />
              </TableCell>
              <TableCell style={{minWidth: '300px', ...cellStyle}}>attribute name</TableCell>
              <TableCell style={{minWidth: '100px', ...cellStyle}}>type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`${index * page}-${row.name}`}
                sx={{maxHeight: '37px', paddingTop: 0, paddingBottom: 0, cursor: 'pointer'}}
                onClick={() => toggleRow(row.name)}
              >
                <TableCell align="center" style={cellStyle}>
                  <Checkbox color="default" checked={selectedAttributes.indexOf(row.name) !== -1} />
                </TableCell>
                <TableCell style={cellStyle}>{row.name}</TableCell>
                <TableCell style={cellStyle}>{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[8]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}

DialogExportAlbumAttributeSelection.propTypes = {
  itemSchemas: PropTypes.array.isRequired,
  selectedAttributes: PropTypes.array.isRequired,
  setSelectedAttributes: PropTypes.func.isRequired,
}

export default DialogExportAlbumAttributeSelection
