const SEARCH_RESULT_SUMMARY_LIMIT = 35000

const newHistogramElement = ({baseline = false} = {baseline: false}) =>
  baseline
    ? {numResultingItems: 0, numBaselineResultingItems: 1}
    : {numResultingItems: 1, numBaselineResultingItems: 0}

const keywordsChanged = (oldKeywords, newKeywords) => (oldKeywords ?? '') !== (newKeywords ?? '')

function projectsChanged(oldProjects, newProjects) {
  const oldProjectNames = [...new Set((oldProjects ?? []).map(p => p.name).sort())].join(',')
  const newProjectNames = [...new Set((newProjects ?? []).map(p => p.name).sort())].join(',')

  return oldProjectNames !== newProjectNames
}

function collectionsChanged(oldCollections, newCollections) {
  const oldCollectionNames = [...new Set((oldCollections ?? []).map(c => c.name).sort())].join(',')
  const newCollectionNames = [...new Set((newCollections ?? []).map(c => c.name).sort())].join(',')

  return oldCollectionNames !== newCollectionNames
}

function tagsChanged(oldProperties, newProperties) {
  const oldTags = (oldProperties ?? [])
    .filter(i => i.property === 'tags')
    .map(i => i.value)
    .sort()
    .join(',')
  const newTags = (newProperties ?? [])
    .filter(i => i.property === 'tags')
    .map(i => i.value)
    .sort()
    .join(',')

  return oldTags !== newTags
}

/**
 * Retrieves content that appears in basic search histogram
 */
function useRetrieveBasicSearchHistogram() {
  function searchTermsChanged(oldTerms, newTerms) {
    if (keywordsChanged(oldTerms?.keywords, newTerms?.keywords)) return true
    if (projectsChanged(oldTerms?.projects, newTerms?.projects)) return true
    if (collectionsChanged(oldTerms?.collections, newTerms?.collections)) return true
    if (tagsChanged(oldTerms?.properties, newTerms?.properties)) return true

    return false
  }

  return {
    SEARCH_RESULT_SUMMARY_LIMIT,
    newHistogramElement,
    searchTermsChanged,
  }
}

export default useRetrieveBasicSearchHistogram
