import validBoundingBox from '../helpers/validBoundingBox'
import {
  FilterComparisonClause,
  FilterConjunctionClause,
  FilterConjunctionOperator,
} from '../context/imagineServiceContext'
import type {
  Collection,
  DatetimeRange,
  FetchItemsOptions,
  FilterComparisonClause as FilterComparisonClauseType,
  FilterComparisonOperator,
  FilterConjunctionClause as FilterConjunctionClauseType,
  Project,
} from '@bayer-int/imagine-sdk-browser'
import {Writeable} from './tsHelpers'
import {SearchKeyword, SearchParameters, SearchProperty} from '../context/search/SearchParameters'

export default function mapSearchParametersToFetchItemsOptions(
  searchTerms: SearchParameters
): FetchItemsOptions {
  const {bbox, collections, projects, properties, keywords, tags, fromDate, toDate} = searchTerms

  const validBounds = validBoundingBox(bbox)

  const searchPayload: Writeable<FetchItemsOptions> = {}

  if (projects?.length) {
    searchPayload.projects = mapProjects(projects)
  }

  if (collections?.length) {
    searchPayload.collections = mapCollections(collections)
  }

  let acquiredAt: Writeable<DatetimeRange>
  if (fromDate || toDate) {
    acquiredAt = {}
  }

  if (fromDate) {
    acquiredAt.start = mapStart(fromDate)
  }

  if (toDate) {
    acquiredAt.end = mapEnd(toDate)
  }

  if (acquiredAt) {
    searchPayload.acquiredAt = acquiredAt
  }

  if (validBounds) {
    searchPayload.bbox = bbox
  }

  if (keywords?.length) {
    searchPayload.keywords = mapKeywords(keywords)
  }

  const tagProperties = mapTagProperties(tags ?? [])

  const allProperties = (properties ?? []).concat(tagProperties)

  if (allProperties?.length) {
    searchPayload.filter = mapFilter(allProperties)
  }

  return searchPayload
}

function mapProjects(projects: string[]): Project[] {
  return projects.map<Project>(id => ({id, _name: 'Project'} as any as Project))
}

function mapCollections(collections: string[]): Collection[] {
  return collections.map<Collection>(id => ({id, _name: 'Collection'} as any as Collection))
}

function mapStart(fromDate: string): Date {
  return new Date(fromDate)
}

function mapEnd(toDate: string): Date {
  return new Date(toDate)
}

function mapKeywords(keywords: SearchKeyword[]) {
  let searchTermsString = ''
  for (const searchTerm of keywords) {
    searchTermsString += `${searchTerm.operator}${searchTerm.value}`
  }
  return searchTermsString
}

function mapTagProperties(tags: string[]): SearchProperty[] {
  return tags.map(t => ({
    property: 'properties.tags:tags',
    comparison: '==',
    value: t,
  }))
}

export function mapFilter(properties: SearchProperty[]): FilterConjunctionClauseType {
  const filterClauses: FilterComparisonClauseType[] = []
  for (const property of properties) {
    const operator = mapFilterComparison(property.comparison)
    filterClauses.push(
      new FilterComparisonClause({
        operator,
        property: property.property,
        value: property.value,
      })
    )
  }
  return new FilterConjunctionClause({
    clauses: filterClauses,
    operator: FilterConjunctionOperator.AND,
  })
}

function mapFilterComparison(comparison: string): FilterComparisonOperator {
  switch (comparison) {
    case '==':
      return '=' as typeof FilterComparisonOperator.EQUAL_TO
    case '!=':
      return '<>' as typeof FilterComparisonOperator.NOT_EQUAL_TO
    case '!':
      return 'isNull' as typeof FilterComparisonOperator.IS_NULL
    default:
      return comparison as FilterComparisonOperator
  }
}
