import navbar from '@monsantoit/phoenix-navbar'
import {getAuthHeader} from '@monsantoit/profile-client'

import {bindings} from './serviceBindings'

const nav = document.querySelector('.nav')

const observer = new MutationObserver(() => {
  const appName = document.querySelector('.application-name')
  if (appName) {
    observer.disconnect()
    appName.style.setProperty('line-height', '1.2em')
    appName.style.setProperty('font-size', '15px')
    appName.innerHTML = `
      Imagine v4
      <br>
      <span style="font-size:11.5px">Powered by Location360</span>
    `
  }
})

export const navbarInstalled = navbar.install({
  element: nav,
  suiteId: 'velocity',
  productId: 'image',
  bootstrapVersion: 4,
  staticCSS: true,
  cookieName: bindings.serviceBindings.imagine_cookie,
  disableReduxDevtools: true,
})

navbarInstalled.then(() => {
  observer.observe(nav, {
    subtree: true,
    childList: true,
  })
})

export async function getBearerToken() {
  await navbarInstalled
  return getAuthHeader().Authorization.substring('Bearer '.length)
}
