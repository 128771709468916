import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Map, FeatureGroup} from 'react-leaflet'
import {EditControl} from 'react-leaflet-draw'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
} from '@mui/material'

import 'leaflet-draw/dist/leaflet.draw.css'

import BingBasemap from './BingBasemap'
import {bingLoaded} from '../utilities/bingMapService'

const DialogTitle = props => {
  const {children, onClose, ...other} = props

  return (
    <MuiDialogTitle
      sx={{
        margin: 0,
        padding: '8px',
      }}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: '#9e9e9e',
          }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const MapContainer = styled.div`
  height: 400px;
  width: 568px;
`

export default function AdvancedSearchSelectorExtentDrawDialog(props) {
  const {setBbox} = props
  const [bingReady, setBingReady] = useState(false)
  const [editableFG, setEditableFG] = useState(null)
  const [drawnBbox, setDrawnBBox] = useState(null)
  const [mapExtent, setMapExtent] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    bingLoaded.then(() => setBingReady(true))
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setDrawnBBox(null)
  }

  const confirmSize = bbox => {
    const roundedBBox = {
      xMin: Math.round((bbox.xMin + Number.EPSILON) * 10000) / 10000,
      xMax: Math.round((bbox.xMax + Number.EPSILON) * 10000) / 10000,
      yMin: Math.round((bbox.yMin + Number.EPSILON) * 10000) / 10000,
      yMax: Math.round((bbox.yMax + Number.EPSILON) * 10000) / 10000,
    }

    setBbox(roundedBBox)
    handleClose()
  }

  const onCreated = () => {
    const drawnItems = editableFG.leafletElement._layers

    const layers = Object.values(drawnItems)

    function setBounds() {
      const ix = layers.length - 1
      setDrawnBBox({
        xMin: layers[ix]._bounds._southWest.lng,
        yMin: layers[ix]._bounds._southWest.lat,
        xMax: layers[ix]._bounds._northEast.lng,
        yMax: layers[ix]._bounds._northEast.lat,
      })
    }

    if (layers.length >= 2) {
      editableFG.leafletElement.removeLayer(layers[0])
    }
    if (layers.length >= 1) {
      setBounds()
    } else {
      setDrawnBBox(null)
    }
  }

  const onFeatureGroupReady = reactFGref => {
    setEditableFG(reactFGref)
  }

  const mapExtentChangeEvent = mapBoundingBox => {
    const {x1, x2, y1, y2} = mapBoundingBox

    setMapExtent({xMin: x1, xMax: x2, yMin: y1, yMax: y2})
  }

  if (!bingReady) {
    return <></>
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Set geographic extent
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Set search extent
        </DialogTitle>
        <MuiDialogContent sx={{padding: '16px'}} dividers>
          <Typography gutterBottom>
            To select an extent, you can either draw a rectangle or confirm the extent
          </Typography>
          <MapContainer>
            <Map
              id="extentSelectionMap"
              // ref={extentDrawMapRef}
              center={[0, 0]}
              zoom={2}
              style={{height: '400px', width: '568px'}}
              ondragend={e => {
                const {
                  _southWest: {lat: y1, lng: x1},
                  _northEast: {lat: y2, lng: x2},
                } = e.target.getBounds()
                mapExtentChangeEvent({x1, x2, y1, y2})
              }}
              whenReady={e => {
                const {
                  _southWest: {lat: y1, lng: x1},
                  _northEast: {lat: y2, lng: x2},
                } = e.target.getBounds()
                mapExtentChangeEvent({x1, x2, y1, y2})
              }}
            >
              <FeatureGroup
                ref={featureGroupRef => {
                  onFeatureGroupReady(featureGroupRef)
                }}
              >
                <EditControl
                  position="topright"
                  onCreated={onCreated}
                  draw={{
                    polyline: false,
                    polygon: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                  }}
                  edit={{edit: false, remove: false}}
                />
                <BingBasemap />
              </FeatureGroup>
            </Map>
          </MapContainer>
        </MuiDialogContent>

        <MuiDialogActions
          sx={{
            margin: 0,
            padding: '8px',
          }}
        >
          <Button
            autoFocus
            onClick={() => confirmSize(drawnBbox)}
            color="primary"
            disabled={!drawnBbox}
          >
            Use drawn extent
          </Button>
          <Button autoFocus onClick={() => confirmSize(mapExtent)} color="primary">
            Use current map extent
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  )
}
