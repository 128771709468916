import React from 'react'
import {Button, DialogActions, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {
  closeUploader,
  setUploaderWorkflowStage,
  setMissionPackageDiscoveryConfirmDialog,
} from '../actions/imageUploaderAction'

const IconGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
  margin-right: -5px;

  span {
    font-size: 14px !important;
  }
`

/**
 * Controls the apperance and actions of the Mission Package Uploader based on the workflow and the stage.
 *
 * Also used for the Mission Package Uploader
 */
function UploaderDialogFooterMissionPackage() {
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)
  const {missionPackageDiscoveryMode} = useSelector(state => state.imageUploader)

  const availableWorkflowStages = useSelector(state => state.imageUploader.availableWorkflowStages)

  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeUploader())
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))

  const canUpload = availableWorkflowStages.indexOf(2) !== -1

  // triggers a confirmation dialog in <UploaderMissionPackageValidator /> if in discovery mode
  function initiateUpload() {
    if (missionPackageDiscoveryMode) {
      dispatch(setMissionPackageDiscoveryConfirmDialog(true))
    } else {
      setUploaderStage(2)
    }
  }

  // Dialog footer buttons for Mission Package
  return (
    <DialogActions style={{flexDirection: 'row'}}>
      {workflowStage === 0 && (
        <>
          <Button
            disabled={availableWorkflowStages.indexOf(1) === -1}
            onClick={() => setUploaderStage(1)}
          >
            Confirm Package
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
      {workflowStage === 1 && (
        <>
          <Button disabled={availableWorkflowStages.indexOf(2) === -1} onClick={initiateUpload}>
            <IconGroup>
              <CloudUploadIcon color={canUpload ? 'primary' : 'inherit'} />
              <Typography color={canUpload ? 'primary' : 'inherit'} variant="button">
                Upload
              </Typography>
            </IconGroup>
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
      {(workflowStage === 2 || workflowStage === 3) && (
        <>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
    </DialogActions>
  )
}

export default UploaderDialogFooterMissionPackage
