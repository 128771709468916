import React from 'react'
import PropTypes from 'prop-types'
import {Button, Dialog, DialogActions} from '@mui/material'
import styled from 'styled-components'

const Title = styled('h3')`
  padding: 0.3em 1em;
  font-weight: 200;
  font-size: 1.7em;
`

/** Display component: Asks the question to the user */
function DialogDeleteSingleItem({removeImageFn, closeDialogFn}) {
  const onDelete = () => {
    removeImageFn()
    closeDialogFn()
  }

  return (
    <Dialog
      open
      onClose={closeDialogFn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Title>Are you sure you want to delete this item?</Title>

      <DialogActions>
        <Button style={{fontSize: '0.8em'}} onClick={closeDialogFn}>
          Cancel
        </Button>
        <Button
          style={{fontSize: '0.8em'}}
          onClick={onDelete}
          variant="outlined"
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDeleteSingleItem.propTypes = {
  /** Function triggered when the image is deleted */
  removeImageFn: PropTypes.func.isRequired,
  /** Function that is triggered when the dialog is to be closed */
  closeDialogFn: PropTypes.func.isRequired,
}

export default DialogDeleteSingleItem
