import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
  TableHead,
  Checkbox,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'

import styled from 'styled-components'

const StyledTableHeadRow = styled(TableRow)`
  .MuiTableCell-root {
    padding: 3px 16px;
  }

  .tag-cell {
    width: 490px;
  }
`

const TableStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    padding-left: 25px;
  }

  .action {
    margin-right: 10px;
  }
`

/** Table head row for the tags selection */
function DialogMultiTagRemoveSelectionTableHead(props) {
  const {numSelected, rowCount, loading, onSelectAllClick, setOrder, order, removeTags} = props
  const [deletingTags, setDeletingTags] = useState(false)

  function indicateSortOrder(column) {
    if (order.column !== column) return false
    return order.ascending ? 'asc' : 'desc'
  }

  const activeColumnSortProps = {
    active: true,
    direction: order.ascending ? 'asc' : 'desc',
  }

  const tableSortLabelTagProps = order.column === 'tags' ? activeColumnSortProps : {}
  const tableSortLabelCountProps = order.column === 'count' ? activeColumnSortProps : {}

  return <>
    <TableStatus>
      <h3 className="title">
        {loading && 'Loading'}
        {deletingTags && `Removing ${numSelected} tag${numSelected !== 1 ? 's' : ''}...`}
        {!(loading || deletingTags) &&
          `${numSelected} item${numSelected !== 1 ? 's' : ''} selected`}
      </h3>
      <IconButton size="large">
        <DeleteIcon
          color={numSelected === 0 ? 'inherit' : 'primary'}
          disabled={deletingTags || loading || numSelected === 0}
          onClick={() => {
            removeTags()
            setDeletingTags(true)
          }}
        />
      </IconButton>
    </TableStatus>
    <TableHead>
      <StyledTableHeadRow>
        <TableCell>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
            color="primary"
          />
        </TableCell>

        <TableCell align="left" sortDirection={indicateSortOrder('tags')} className="tag-cell">
          <TableSortLabel
            {...tableSortLabelTagProps}
            active={order.column === 'tags'}
            onClick={() =>
              setOrder({
                column: 'tags',
                ascending: !(order.column === 'tags' && order.ascending),
              })
            }
          >
            Tag
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" sortDirection={indicateSortOrder('count')}>
          <TableSortLabel
            {...tableSortLabelCountProps}
            active={order.column === 'count'}
            onClick={() =>
              setOrder({
                column: 'count',
                ascending: !(order.column === 'count' && order.ascending),
              })
            }
          >
            Count
          </TableSortLabel>
        </TableCell>
      </StyledTableHeadRow>
    </TableHead>
  </>;
}

DialogMultiTagRemoveSelectionTableHead.propTypes = {
  /** The number of selected rows */
  numSelected: PropTypes.number.isRequired,
  /** The count of total possible rows */
  rowCount: PropTypes.number.isRequired,
  /** Function action on clicking select all */
  onSelectAllClick: PropTypes.func.isRequired,
  /** Usestate change function */
  setOrder: PropTypes.func.isRequired,
  /** Usestate state of parent component */
  order: PropTypes.shape({
    column: PropTypes.string,
    ascending: PropTypes.bool,
  }).isRequired,
  removeTags: PropTypes.func.isRequired,
}

export default DialogMultiTagRemoveSelectionTableHead
