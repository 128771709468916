import React from 'react'
import PropTypes from 'prop-types'

import {LinearProgress, Typography} from '@mui/material'
import styled from 'styled-components'
import {Equalizer} from '@mui/icons-material'

const Container = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr 480px;
  grid-column-gap: 5px;
  padding-top: 5px;
  width: 100%;
  align-items: center;
  justify-items: center;

  p {
    color: #777;
  }

  em {
    color #999;
    margin-left: 3px;
  }

  .MuiLinearProgress-root {
    padding-left: 15px;
    width: 460px;
    height: 11px;
    border-radius: 10px;
    margin-top: 3px;
  }
`

function GallerySummaryHistogramLoading(props) {
  const {value} = props

  return (
    <Container>
      <Equalizer color="primary" />
      <Typography variant="body2">
        Search Results Summary <em>(loading...)</em>
      </Typography>

      <LinearProgress variant="determinate" value={value} />
    </Container>
  )
}

GallerySummaryHistogramLoading.propTypes = {
  value: PropTypes.number.isRequired,
}

export default GallerySummaryHistogramLoading
