import {useDispatch, useSelector} from 'react-redux'
import {setOrthoMosaicValidating as setOrthoMosaicValidatingAction} from '../actions/imageUploaderAction'

/** Triggers the validation function in UploaderOrthoMosaicMetadataForm */
function useOrthoMosaicValidateTrigger() {
  const {orthoMosaicValidating} = useSelector(state => state.imageUploader)
  const dispatch = useDispatch()

  const setOrthoMosaicValidating = state => dispatch(setOrthoMosaicValidatingAction(state))

  return [orthoMosaicValidating, setOrthoMosaicValidating]
}

export default useOrthoMosaicValidateTrigger
