import React, {createContext, useEffect, useState} from 'react'
import {getPreferencesValue, setPreferencesValue} from '../utilities/userPreferences'

import useListVisibleCollections from '../hooks/useListVisibleCollections'
import useManageVisibleMetadata from '../hooks/useManageVisibleMetadata'

const initialState = {
  type: '',
  album: null,
  collection: null,
  project: null,
  pinned: false,
}

const galleryPageContext = createContext(initialState)
const {Provider} = galleryPageContext

/**
 * SDK Instance context
 * Provides the gallery-relevant SDK instance
 * */
function GalleryPageProvider(props) {
  // Used for multiple select
  const [selectedItems, setSelectedItems] = useState([])
  const [itemsPinned, setItemsPinned] = useState(false)

  const toggleItemsPinned = () => setItemsPinned(!itemsPinned)

  const {
    album,
    children,
    collection,
    project,
    type,
    items,
    setItems,
    hasMore,
    itemsToDisplay,
    loading,
    removeItem,
    numberResultingItems,
    next,
  } = props

  const visibleCollections = useListVisibleCollections(items)
  const {addSchemaElement, removeSchemaElement, visibleSchemaElements} =
    useManageVisibleMetadata(visibleCollections)

  // * Retrieves preference on item card pinning
  useEffect(() => {
    async function getCardPinPreference() {
      const preference = (await getPreferencesValue('item-gallery-pin-metadata')) ?? false

      setItemsPinned(preference)
    }

    getCardPinPreference()
  }, [])

  // * Saves preference on item card pinning
  useEffect(() => {
    setPreferencesValue('item-gallery-pin-metadata', itemsPinned)
  }, [itemsPinned])

  if (['', 'album', 'project', 'collection', 'search'].indexOf(type) === -1) {
    throw new Error('Type must be one of "project", "collection" or "album"')
  }
  if (type === 'album' && !album) throw new Error('Album instance is missing')
  if (type === 'collection' && !collection) throw new Error('Collection instance is missing')
  if (type === 'project' && !project) throw new Error('Project instance is missing')

  return (
    <Provider
      value={{
        type,
        project,
        collection,
        album,
        loading,
        items,
        setItems,
        hasMore,
        next,
        itemsToDisplay,
        removeItem,
        numberResultingItems,
        selectedItems,
        setSelectedItems,
        itemsPinned,
        toggleItemsPinned,
        // Used for selecting visible schema in the collection galleries
        addSchemaElement,
        removeSchemaElement,
        visibleSchemaElements,
        visibleCollections,
      }}
    >
      {children}
    </Provider>
  )
}

export {galleryPageContext, GalleryPageProvider}
