import React, {useState} from 'react'
import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'
import {IconButton, TextField} from '@mui/material'
import {Add as AddIcon} from '@mui/icons-material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import SearchSelectedAttribute from './SearchSelectedAttribute'
import {SearchKeywordOperator} from '../context/search/SearchParameters'

const KeywordSelector = styled.div`
  form {
    display: grid;
    grid-template-columns: 200px 1fr 50px;
    grid-column-gap: 10px;
    align-items: end;
    margin-left: 5px;
    max-width: 605px;
  }

  .text-field {
    margin-bottom: 2px;
    margin-left: 5px;
  }

  button {
    height: 35px;
  }

  .selector-field {
    width: 200px;
  }

  .pills {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
`

const operatorOptions = [
  {label: 'includes', value: SearchKeywordOperator.INCLUDES},
  {label: 'must include', value: SearchKeywordOperator.MUST_INCLUDE},
  {label: 'must not include', value: SearchKeywordOperator.MUST_NOT_INCLUDE},
]

const operatorLabels = {
  [SearchKeywordOperator.INCLUDES]: 'includes',
  [SearchKeywordOperator.MUST_INCLUDE]: 'must include',
  [SearchKeywordOperator.MUST_NOT_INCLUDE]: 'must not include',
}

const defaultOperator = operatorOptions[0]

function quoteWrapper(term) {
  const isQuoted = term[0] === '"' && term[term.length - 1] === '"'
  if (!isQuoted) {
    return `"${term}"`
  }
  return term
}

function AdvancedSearchSelectorKeywords() {
  const [operator, setOperator] = useState(defaultOperator)
  const [keyword, setKeyword] = useState('')

  const {
    searchAttributes: {keywords: selectedKeywords},
    setKeywords,
  } = useAdvancedSearch()

  const existingValue = selectedKeywords.some(keyword => keyword.value === keyword)

  const addKeywordCriteria = () => {
    if (!keyword || existingValue) return

    setKeywords([
      ...(selectedKeywords ?? []),
      {value: quoteWrapper(keyword), operator: operator.value},
    ])
    setKeyword('')
  }

  const removeKeywordCriteria = index => {
    const newArray = [...selectedKeywords]
    newArray.splice(index, 1)
    setKeywords(newArray)
  }

  const KeywordPills = () =>
    selectedKeywords ? (
      selectedKeywords.map(({operator, value}, index) => (
        <SearchSelectedAttribute
          property={value}
          value={`(${operatorLabels[operator]})`}
          onDelete={() => removeKeywordCriteria(index)}
          key={`selected-keyword-${index}-${value}`}
          keyword
        />
      ))
    ) : (
      <></>
    )

  return (
    <KeywordSelector>
      <form>
        <Autocomplete
          options={operatorOptions}
          getOptionLabel={option => option.label}
          renderInput={params => <TextField {...params} label="Operator" variant="standard" />}
          defaultValue={defaultOperator}
          className="selector-field"
          disableClearable
          onChange={(_event, selectedOperator) => {
            setOperator(selectedOperator)
          }}
          value={operator}
        />
        <TextField
          value={keyword}
          onChange={event => setKeyword(event.target.value)}
          onKeyUp={event => {
            if (!existingValue && event.key === 'Enter' && keyword) {
              addKeywordCriteria()
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          label="keyword"
          className="text-field"
          error={existingValue}
          variant="standard"
        />
        <IconButton
          aria-label="add"
          color="primary"
          disabled={!keyword || existingValue}
          onClick={addKeywordCriteria}
          size="large"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </form>
      <div className="pills">
        <KeywordPills />
      </div>
    </KeywordSelector>
  )
}

export default AdvancedSearchSelectorKeywords
