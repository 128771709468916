import React from 'react'
import GallerySummarySearchResult from './GallerySummarySearchResult'

function GallerySummaryBasicSearch(props) {
  const {condensedSearch, numberItems, searchTerm, showHistogram, onHistogramToggle} = props
  const searchText = `Your ${condensedSearch ? 'enhanced ' : ''}search for "${searchTerm}" returns
            ${
              isFinite(numberItems)
                ? ` ${new Intl.NumberFormat('en-US').format(numberItems)}`
                : ' more than 10,000'
            } 
            items`

  return (
    <GallerySummarySearchResult
      searchText={searchText}
      showHistogram={showHistogram}
      onHistogramToggle={onHistogramToggle}
    />
  )
}

export default GallerySummaryBasicSearch
