import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Typography, Card, CardHeader, Avatar, CardContent} from '@mui/material'
import {blue} from '@mui/material/colors'
import styled from 'styled-components'
import {Done, Launch} from '@mui/icons-material'
import * as Sentry from '@sentry/react'

import {closeUploader} from '../actions/imageUploaderAction'
import CardGenericError from './CardGenericError'

const Container = styled.section`
  width: 960px;
  height: 583px;
  display: flex;
  justify-content: center;

  .MuiCard-root {
    width: 450px;
    height: 210px;
    margin-top: 100px;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 152px;
    padding-top: 20px;
  }
`

function UploaderImagineStageResults() {
  const imageId = useSelector(state => state.imageUploader.imageId)
  const imageProject = useSelector(state => state.imageUploader.imageProject)
  const errorMessage = useSelector(state => state.imageUploader.errorMessage)

  const dispatch = useDispatch()

  if (errorMessage) Sentry.captureMessage(`Error in uploading an item: ${errorMessage}`)

  const SuccessCard = ({link}) => (
    <Container>
      <Card sx={{maxWidth: '545px'}}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" style={{backgroundColor: blue[500]}}>
              <Done />
            </Avatar>
          }
          style={{fontSize: '1.23rem'}}
          title="Item Upload Successful"
        />

        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            The item is now published and viewable through the link below.
          </Typography>
          <Typography variant="button" color="textSecondary" component="p">
            <Link to={link} onClick={() => dispatch(closeUploader())}>
              View the asset{' '}
              <Launch
                color="primary"
                fontSize="small"
                style={{position: 'relative', top: 5, left: 1}}
              />
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )

  return errorMessage ? (
    <CardGenericError
      fullErrorTrace={errorMessage}
      title="Error in Uploading Item"
      description="An error prevented the upload of the item. If this error continues, please copy the trace below and contact Location360 Support"
    />
  ) : (
    <SuccessCard link={`/item?id=${imageId}&project=${imageProject}`} />
  )
}

export default UploaderImagineStageResults
