import React, {createContext, useState} from 'react'

type UploaderContextType = {
  workflowType: any
  metadata: any
  setWorkflowType: any
  setMetadata: any
}

const uploaderContext = createContext({} as UploaderContextType)
const {Provider} = uploaderContext

/**
 * Uploader store for the Imagine Uploader
 * Provides a store to pass information from an item's asset to the uploader
 * */
function UploaderProvider({children}) {
  const [workflowType, setWorkflowType] = useState(0)
  const [metadata, setMetadata] = useState({})

  return (
    <Provider
      value={{
        workflowType,
        metadata,
        setWorkflowType,
        setMetadata,
      }}
    >
      {children}
    </Provider>
  )
}

export {uploaderContext, UploaderProvider}
