import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const SpinnerElement = styled('div')`
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

const StyledCircularProgress = styled(CircularProgress)`
  color: #90caf9;
  display: block;
`

function LoadingSpinnerInline({message}) {
  return (
    <SpinnerElement>
      <StyledCircularProgress size={40} />
      <Typography variant="body2" align="center">
        {message}
      </Typography>
    </SpinnerElement>
  )
}

LoadingSpinnerInline.displayName = 'LoadingSpinnerInline'

LoadingSpinnerInline.propTypes = {
  message: PropTypes.string,
}

LoadingSpinnerInline.defaultProps = {
  message: '',
}

export default LoadingSpinnerInline
