import React, {createContext, useState} from 'react'

/**
 * Provides a list of items. Used in Gallery views and item view.
 *
 */
const initialState = {
  albums: null,
}

const albumExportContext = createContext(initialState)
const {Provider} = albumExportContext

/**
 * Album Export Store
 * Provides a store to hone down on prop drilling for updating and deleting
 * */
function AlbumExportProvider({children}) {
  const [excludedRoles, setExcludedRoles] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [attributeListPage, setAttributeListPage] = useState(0)

  return (
    <Provider
      value={{
        excludeRoleState: [excludedRoles, setExcludedRoles],
        attributeState: [selectedAttributes, setSelectedAttributes],
        attributeListPage: [attributeListPage, setAttributeListPage],
      }}
    >
      {children}
    </Provider>
  )
}

export {albumExportContext, AlbumExportProvider}
