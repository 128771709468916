import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {IconButton, Typography} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function ItemMetadataHeaderAccordion(props) {
  const {header, children} = props
  const [showMetadataRows, setShowMetadataRows] = useState(true)

  const handleRowCollapse = () => {
    setShowMetadataRows(!showMetadataRows)
  }

  return (
    <>
      <div
        style={{display: 'flex', justifyContent: 'space-between', zIndex: 1}}
        className="section-title"
      >
        <Typography variant="h5" color="primary">
          {header}
        </Typography>
        <IconButton onClick={handleRowCollapse}>
          {showMetadataRows ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="primary" />
          )}
        </IconButton>
      </div>
      {showMetadataRows && <div style={{marginBottom: '4px'}}>{children}</div>}
    </>
  )
}

ItemMetadataHeaderAccordion.propTypes = {
  /** The label for the header */
  header: PropTypes.string,
}

export default ItemMetadataHeaderAccordion
