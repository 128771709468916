import React, {useEffect, useReducer} from 'react'
import PropTypes from 'prop-types'
import {TableBody, TableContainer, TablePagination, Paper} from '@mui/material'

import DialogMultiTagEditSelectionTableHead from './DialogMultiTagEditSelectionTableHead'
import DialogMultiTagEditSelectionTableRow from './DialogMultiTagEditSelectionTableRow'

const initialState = {
  rows: [],
  loading: true,
  alteredTags: {},
  page: 0,
  rowsPerPage: 10,
  order: {
    column: 'tags',
    ascending: true,
  },
}

const createLoadedState = rows => ({
  rows: rows && Array.isArray(rows) ? rows.map(row => ({...row, editing: false})) : [],
  loading: false,
  alteredTags: {},
  page: 0,
  rowsPerPage: 10,
  order: {
    column: 'tags',
    ascending: true,
  },
})

function reducer(state, action) {
  switch (action.type) {
    case 'set-content':
      return action.content

    case 'set-rows':
      return {...state, rows: action.rows}
    case 'set-altered-rows':
      return {
        ...state,
        alteredTags: action.alteredTags,
        rows: state.rows.map(row => ({...row, editing: false})),
      }
    case 'set-row-editing':
      return {...state, rows: action.rows}
    case 'set-table-page':
      return {...state, page: action.page}
    case 'set-rows-per-table-page':
      return {...state, page: 0, rowsPerPage: action.rowsPerPage}
    case 'set-table-order':
      return {...state, page: 0, order: action.order}
    default:
      throw new Error(`Unknown Reducer case "${action.type}" in DialogMultiTagEditSelectionTable`)
  }
}

/**
 * Table which displays and allows editing of multiple tags
 */
function DialogMultiTagEditSelectionTable(props) {
  const {executeEditTags, listEditableTags} = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const {rows, alteredTags, page, rowsPerPage, order, loading} = state

  const setAlteredTags = alteredRows =>
    dispatch({type: 'set-altered-rows', alteredTags: alteredRows})
  const setRowsPerPage = newRowsPerPage =>
    dispatch({type: 'set-rows-per-table-page', rowsPerPage: newRowsPerPage})
  const setOrder = updatedOrder => dispatch({type: 'set-table-order', order: updatedOrder})
  const setPage = newPage => dispatch({type: 'set-table-page', page: newPage})

  // Reset content on dialog dismount
  useEffect(() => {
    dispatch({
      type: 'set-content',
      content: createLoadedState(listEditableTags),
    })
    return () => {
      dispatch({type: 'set-content', content: initialState})
    }
  }, [])

  function setRowEditing({index, editingState}) {
    // When function triggers, the default is to set all rows editing to false
    const newRows = [...rows].map(row => ({...row, editing: false}))

    newRows[index].editing = editingState

    return dispatch({type: 'set-row-editing', rows: newRows})
  }

  rows.sort((a, b) => {
    if (order.column === 'tags') {
      return a.originalTag.toUpperCase() > b.originalTag.toUpperCase() ? 1 : -1
    } else {
      return a.count > b.count ? 1 : -1
    }
  })

  if (!order.ascending) rows.reverse()

  const selectedRows = []
  for (let i = page * rowsPerPage; i < (page + 1) * rowsPerPage; i++) {
    if (rows.length > i) selectedRows.push(rows[i])
  }

  function recordAlteredValue({originalValue, alteredValue}) {
    const updatedTags = {...alteredTags}

    if (alteredValue && originalValue !== alteredValue) {
      updatedTags[originalValue] = alteredValue
    } else {
      delete updatedTags[originalValue]
    }

    setAlteredTags(updatedTags)
  }

  return (
    <div>
      <Paper>
        <DialogMultiTagEditSelectionTableHead
          numSelected={Object.keys(alteredTags).length}
          rowCount={rows.length}
          setOrder={setOrder}
          order={order}
          loading={loading}
          triggerEdit={() => executeEditTags(alteredTags)}
        />
        <TableContainer>
          <TableBody>
            {selectedRows.map(({originalTag, count, editing}, index) => (
              <DialogMultiTagEditSelectionTableRow
                alterValue={recordAlteredValue}
                alteredValue={alteredTags[originalTag]}
                originalValue={originalTag}
                editing={editing}
                setEditing={editingState => setRowEditing({index, editingState})}
                count={count}
              />
            ))}
          </TableBody>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={event => {
            setRowsPerPage(parseInt(event.target.value, 10))
            setPage(0)
          }}
        />
      </Paper>
    </div>
  );
}

DialogMultiTagEditSelectionTable.propTypes = {
  /** A series of keys and associated counts for each key. Used to populate table */
  listEditableTags: PropTypes.arrayOf(
    PropTypes.shape({name: PropTypes.string, count: PropTypes.number})
  ).isRequired,
  /** Function that modifes a series of tags for each selected item */
  executeEditTags: PropTypes.func.isRequired,
}

export default DialogMultiTagEditSelectionTable
