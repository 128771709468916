import React, {useEffect, useRef} from 'react'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import {Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'

import {
  addAvailableWorkflowStages,
  setUploaderWorkflowStage,
  setUploadImage,
} from '../actions/imageUploaderAction'
import DropArea from './styled/DropArea'
import DropInputLabel from './styled/DropInputLabel'

// Prevents accidental closure of window
window.close = () => {
  console.error('window.close was called')
  debugger
}

/**
 * Image selection for Imagine Uploader
 */
function UploaderImagineStageFileSelector() {
  const selectedImage = useSelector(state => state.imageUploader.itemAssetFile)

  const dispatch = useDispatch()
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))
  const fileInputRef = useRef(null)

  const setImageFile = image => dispatch(setUploadImage(image))

  useEffect(() => {
    if (selectedImage) {
      addWorkflowStages([1])
      setUploaderStage(1)
    }
  }, [selectedImage])

  const selectFile = () => {
    const [file] = fileInputRef.current.files
    if (file) setImageFile(file)
  }

  return (
    <DropArea>
      <input type="file" name="file" id="file" onChange={selectFile} ref={fileInputRef} />
      <DropInputLabel htmlFor="file">
        <div className="icon-container">
          <CreateNewFolderIcon size="large" />
        </div>
        <Typography use="subtitle1" style={{cursor: 'pointer'}}>
          Click to upload a file
        </Typography>
      </DropInputLabel>
    </DropArea>
  )
}

export default UploaderImagineStageFileSelector
