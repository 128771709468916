import React from 'react'
import styled from 'styled-components'
import {Button, Paper, Typography, IconButton} from '@mui/material'
import {
  CloudDownload as CloudDownloadIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  AddPhotoAlternate as AddToAlbumIcon,
} from '@mui/icons-material'

import { Skeleton } from '@mui/material';

import ItemLayoutCopyButton from '../components/ItemLayoutCopyButton'

const StyledIconButton = styled(IconButton)`
  color: ${props =>
    props.disabledcolor === 'true'
      ? 'var(--mdc-theme-text-disabled-on-background) !important'
      : 'var(--mdc-theme-primary) !important'};
`

const ButtonTitle = styled(Typography)`
  margin-left: 10px;
  margin-bottom: 8px !important;
`

const ItemInformationSection = styled.section`
  display: grid;

  // Height minus searchbar minus breadcrumbs
  height: calc(95vh - 120px - 20px);
  grid-template-rows: 50px 155px 1fr 1fr 60px 36px 36px;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: 1fr minmax(300px, 680px) 1fr minmax(300px, 560px) 1fr;
  overflow: hidden;
  padding-bottom: 10px;

  .item-metadata-information {
    grid-column: 2;
    grid-row: 1 / 8;
    overflow-y: scroll;
    padding: 0 5px 5px 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-skeleton-container {
    margin-top: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(160, 160, 160);
    padding-bottom: 5px;
    padding-left: 10px;
    width: 95%;
  }

  .metadata-section {
    grid-column: 4;
    grid-row: 1 / 4;

    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }

    .button-row {
      display: grid;

      grid-template-columns: 1fr 1fr;
      padding: 0 10px;
      grid-column-gap: 8px;
    }
  }

  .geometry-display-skeleton {
    grid-column: 4;
    grid-row: 4 / 5;
    margin-top: 10px;
    padding-top: 20px;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .item-user-entitlements {
    grid-column: 4;
    grid-row: 5 / 6;
    padding: 10px;
    align-self: center;

    h5 {
      text-align: center;
    }

    .user-entitlement-row {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .add-item-to-album-button,
  .delete-item-button {
    background: white;
    grid-column: 4;
  }

  .add-item-to-album-button {
    grid-row: 6 / 7;
  }

  .delete-item-button {
    grid-row: 7 / 8;
  }

  .retrieval-pane {
    margin-bottom: 10px;
    padding: 5px;
  }
`

/** Presents a loading preview to the Item page */
function ItemPageLayout() {
  return (
    <ItemInformationSection>
      <Paper className="item-metadata-information" elevation={0}>
        <div className="header-skeleton-container">
          <Skeleton variant="rectangular" height={27} width={150} />
        </div>
        <Skeleton variant="rectangular" width="95%" height={165} />
        <div className="header-skeleton-container">
          <Skeleton variant="rectangular" height={27} width={150} />
        </div>
        <Skeleton variant="rectangular" width="95%" height={100} />
        <div className="header-skeleton-container">
          <Skeleton variant="rectangular" height={27} width={150} />
        </div>
        <Skeleton variant="rectangular" width="95%" height={180} />
      </Paper>
      <div className="metadata-section">
        <Paper elevation={0} className="retrieval-pane">
          <ButtonTitle variant="h5">
            <Skeleton variant="rectangular" height={32} width={200} />
          </ButtonTitle>
          <div className="button-row">
            <ItemLayoutCopyButton metadata={{foo: 'bar'}} disabled>
              Copy
            </ItemLayoutCopyButton>

            <Button disabled variant="outlined" startIcon={<CloudDownloadIcon />}>
              Download
            </Button>
          </div>
        </Paper>

        <Paper className="geometry-display-skeleton" elevation={0}>
          <Skeleton variant="rectangular" height="90%" width="94%" />
        </Paper>
      </div>

      <Paper className="item-user-entitlements" elevation={0}>
        <div className="user-entitlement-row">
          <Skeleton variant="rectangular" width={145} height={26} />
          <StyledIconButton disabledcolor="true">
            <CloudDownloadIcon />
          </StyledIconButton>
          <StyledIconButton disabledcolor="true">
            <EditIcon />
          </StyledIconButton>
          <StyledIconButton disabledcolor="true">
            <DeleteForeverIcon />
          </StyledIconButton>
        </div>
      </Paper>
      <Button className="add-item-to-album-button" startIcon={<AddToAlbumIcon />} disabled>
        <Skeleton width={161} height={24} />
      </Button>
      <Button className="delete-item-button" startIcon={<DeleteForeverIcon />} disabled>
        <Skeleton width={105} height={24} />
      </Button>
    </ItemInformationSection>
  );
}

export default ItemPageLayout
