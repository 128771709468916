import React, {useContext, useEffect, useReducer} from 'react'
import PropTypes from 'prop-types'
import {Create, Label, LabelOff} from '@mui/icons-material'
import {Dialog, DialogTitle, IconButton, Tooltip} from '@mui/material'
import {galleryPageContext} from '../context/galleryPageContext'
import useDetermineBatchActionUnsuitability from '../hooks/useDetermineBatchActionUnsuitability'

import DialogMultiTagAdd from './DialogMultiTagAdd'
import DialogMultiTagEdit from './DialogMultiTagEdit'
import DialogMultiTagRemove from './DialogMultiTagRemove'
import DialogMultiTagAddWarning from './DialogMultiTagAddWarning'

const initialState = {
  tooltips: {
    add: 'Add tags to selected items',
    edit: 'Modify tags to selected items',
    remove: 'Remove tags from selected items',
  },
  disabledActions: {
    add: false,
    edit: false,
    remove: false,
  },
  openDialog: '',
  dialogStage: 0,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-tooltips':
      return {...state, tooltips: {...state.tooltips, ...action.tooltips}}
    case 'trigger-dialog':
      return {...state, openDialog: action.dialog}
    case 'disable-actions':
      return {
        ...state,
        disabledActions: {
          add: true,
          edit: true,
          remove: true,
        },
        tooltips: action.tooltips,
      }
    case 'set-dialog-stage':
      return {...state, dialogStage: action.dialogStage}
    case 'close-dialog':
      return {...state, dialogStage: 0, openDialog: ''}
    default:
      throw new Error(`Unknown action selected "${action.type}" in DialogMultiTagActions`)
  }
}

function DialogMultiTagActions(props) {
  const {selectedItems} = props

  const untaggableItems = useDetermineBatchActionUnsuitability(selectedItems)
  const [state, dispatch] = useReducer(reducer, initialState)
  const {type, project, collection} = useContext(galleryPageContext)

  const disableActions = tooltips => dispatch({type: 'disable-actions', tooltips})
  const openDialog = dialog => dispatch({type: 'trigger-dialog', dialog})
  const setDialogStage = dialogStage => dispatch({type: 'set-dialog-stage', dialogStage})

  const closeDialog = () => dispatch({type: 'close-dialog'})

  // Disables dialogue on mount based on specific criteria
  useEffect(() => {
    async function initialize() {
      if (type === 'project') {
        const entitlements = await project.entitlements()
        if (
          entitlements.currentUserHasEntitlement('PUBLISH') ||
          entitlements.currentUserHasEntitlement('DELETE')
        ) {
          disableActions({
            add: 'Insufficient Entitlement',
            edit: 'Insufficient Entitlement',
            remove: 'Insufficient Entitlement',
          })
        }
      }
      if (type === 'collection') {
        // TODO .schema() and .stacExtensions() will be implemented in a future sdk release
        // For now assign as empty array
        // const schema = await collection.schema()
        // const extensions = collection.stacExtensions
        const schema = []
        const extensions = []

        if ([...Object.keys(schema), ...extensions].indexOf('tags') === -1) {
          disableActions({
            add: 'Cannot add tags to a collection without a "tags" attribute',
            edit: 'Cannot modify tags to a collection without a "tags" attribute',
            remove: 'Cannot remove tags from a collection without a "tags" attribute',
          })
        }
      }
    }

    initialize()
  }, [])

  const labelProps = dialog =>
    selectedItems.length === 0 || state.disabledActions[dialog] ? {} : {color: 'primary'}

  const title = state.openDialog
  const untaggableIds = untaggableItems?.map(i => i.id) ?? []

  const CoreDialog = () => {
    if (state.openDialog === 'add') {
      return (
        <DialogMultiTagAdd
          handleClose={closeDialog}
          selectedItems={selectedItems}
          untaggableIds={untaggableIds}
        />
      )
    } else if (state.openDialog === 'remove') {
      return (
        <DialogMultiTagRemove
          handleClose={closeDialog}
          selectedItems={selectedItems}
          untaggableIds={untaggableIds}
        />
      )
    } else if (state.openDialog === 'edit') {
      return (
        <DialogMultiTagEdit
          handleClose={closeDialog}
          selectedItems={selectedItems}
          untaggableIds={untaggableIds}
        />
      )
    } else {
      console.error('Improper dialog selection selected for DialogMultiTagActions')
      return <></>
    }
  }

  const DialogContent = () => {
    if (state.dialogStage === 0 && untaggableItems.length > 0) {
      return (
        <DialogMultiTagAddWarning
          handleClose={closeDialog}
          numberItems={selectedItems.length}
          progressToNextStage={() => setDialogStage(1)}
          untaggableItems={untaggableItems}
        />
      )
    } else {
      return <CoreDialog />
    }
  }

  return (
    <>
      <Dialog onClose={closeDialog} open={!!state.openDialog}>
        <>
          {title && <DialogTitle>{`${title[0].toUpperCase()}${title.slice(1)}`} Tags</DialogTitle>}
          <DialogContent />
        </>
      </Dialog>
      <Tooltip title={state.tooltips.add}>
        <span>
          <IconButton
            disabled={selectedItems.length === 0 || state.disabledActions.add}
            onClick={() => openDialog('add')}
            size="large"
          >
            <Label {...labelProps('add')} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={state.tooltips.remove}>
        <span>
          <IconButton
            disabled={selectedItems.length === 0 || state.disabledActions.remove}
            onClick={() => openDialog('remove')}
            size="large"
          >
            <LabelOff {...labelProps('remove')} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={state.tooltips.edit}>
        <span>
          <IconButton
            disabled={selectedItems.length === 0 || state.disabledActions.edit}
            onClick={() => openDialog('edit')}
            size="large"
          >
            <Create {...labelProps('edit')} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

DialogMultiTagActions.propTypes = {
  /** List of selected items to carry out operations upon */
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default DialogMultiTagActions
