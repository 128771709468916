import React, {useEffect, useRef, useState} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import {useDispatch} from 'react-redux'

import {addAvailableWorkflowStages, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import DropArea from './styled/DropArea'
import DropInputLabel from './styled/DropInputLabel'
import {handleSentryError} from '../utilities/sentryErrors'
import useToastNotification from '../hooks/useToastNotification'

/**
 * A UI element for selecting a single file. Once selected,
 * it triggers an initialize function brought in by the parent component
 * t
 */
function UploaderOrthoMosaicFileSelector(props) {
  const [initializing, setInitializing] = useState(false)
  const {initializeUploader, setCriticalError} = props
  const {errorNotification} = useToastNotification()

  // Set initializing back to false on dismount
  useEffect(() => () => setInitializing(false), [])

  const dispatch = useDispatch()
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))
  const fileInputRef = useRef(null)

  const selectFile = () => {
    setInitializing(true)

    // When the metadata instance is set to null, it means it is ready to upload
    initializeUploader(fileInputRef.current.files[0])
      .then(uploader => {
        if (!uploader) {
          console.warn('Unable to progress with ortho upload due to file initialization error.')
        } else if (uploader.metadata === null) {
          addWorkflowStages([2])
          setUploaderStage(2)
        } else {
          addWorkflowStages([1])
          setUploaderStage(1)
        }
      })
      .catch(err => {
        const details = 'Failed to initialize uploader'
        handleSentryError(err, details)
        errorNotification(details)
        setCriticalError(details)
      })
  }

  return (
    <DropArea>
      <input
        type="file"
        name="file"
        id="file"
        accept="image/tiff, image/x-tiff"
        onChange={selectFile}
        ref={fileInputRef}
      />
      <DropInputLabel htmlFor="file">
        {initializing ? (
          <>
            <div className="icon-container">
              <CircularProgress />
            </div>
            <Typography use="subtitle1" style={{cursor: 'pointer'}}>
              Uploader initializing
            </Typography>
          </>
        ) : (
          <>
            <div className="icon-container">
              <CreateNewFolderIcon size="large" />
            </div>
            <Typography use="subtitle1" style={{cursor: 'pointer'}}>
              Click to upload a file
            </Typography>
          </>
        )}
      </DropInputLabel>
    </DropArea>
  )
}

export default UploaderOrthoMosaicFileSelector
