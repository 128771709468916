import React from 'react'
import PropTypes from 'prop-types'
import {Paper, Typography} from '@mui/material'
import {Info as InfoIcon} from '@mui/icons-material'
import geojsonValidator from 'geojson-validation'

import ItemLocationMap from './ItemLocationMap'

/** Validates presence and validity of geojson, then presents map of item extent */
function ItemGeometryDisplay(props) {
  const {geometry} = props

  if (!geometry) {
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#aaa',
          height: '70%',
        }}
        elevation={1}
      >
        <InfoIcon style={{marginRight: '5px'}} />
        <Typography variant="body1">No geometry was detected for this item.</Typography>
      </Paper>
    )
  } else if (!geojsonValidator.valid(geometry)) {
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#aaa',
          height: '70%',
        }}
        elevation={1}
      >
        <InfoIcon style={{marginRight: '5px'}} />
        <Typography variant="body1">
          No valid GeoJSON geometry was detected for this item
        </Typography>
      </Paper>
    )
  } else {
    return (
      <Paper sx={{height: '70%'}} elevation={1}>
        <ItemLocationMap geometry={geometry} />
      </Paper>
    )
  }
}

ItemGeometryDisplay.propTypes = {
  /** Any object works. This prop is responsible for validating against geojson */
  geometry: PropTypes.shape({}),
}

export default ItemGeometryDisplay
