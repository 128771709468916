import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'

import {saveAs} from 'file-saver'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import styled from 'styled-components'

import DialogExportAlbumStepper from './DialogExportAlbumStepper'
import DialogExportAlbumAttributeSelection from './DialogExportAlbumAttributeSelection'
import DialogExportAlbumRoleExclusion from './DialogExportAlbumRoleExclusion'

import {albumExportContext} from '../context/albumExportContext'

const LoadingElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;

  p {
    text-decoration: auto;
    font-style: italic;
    color: #aaa;
    letter-spacing: 0.4px;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DialogExportAlbum(props) {
  const {album, closeDialog, items} = props
  const [loading, setLoading] = useState(true)
  const [itemSchemas, setItemSchemas] = useState([])
  const [itemRoles, setItemRoles] = useState([])
  const [dialogStage, setDialogStage] = useState(0)

  const {
    attributeState: [selectedAttributes, setSelectedAttributes],
    excludeRoleState: [excludedRoles],
  } = useContext(albumExportContext)

  useEffect(() => {
    const schemaAttributes = []

    const groupedRoles = []

    items.forEach(async (item, index) => {
      const collection = await item.collection()
      const schema = await collection.schema()
      Object.entries(schema).forEach(([key, value]) => {
        const isDuplicate = schemaAttributes.findIndex(attr => attr.name === key) !== -1
        const isObject = value.type === 'object'
        if (isObject || isDuplicate) {
          return
        }

        schemaAttributes.push({name: key, type: value.type})
      })
      setItemSchemas(schemaAttributes)

      const assets = await item.assets()

      for (const value of assets.map.values()) {
        groupedRoles.push(...value.roles)
      }

      if (index === items.length - 1) {
        setLoading(false)
        setItemRoles([...new Set([...itemRoles, ...groupedRoles])])
      }
    })
  }, [])

  async function downloadFile(format) {
    const includeProperties = selectedAttributes
    const additionalProperties = excludedRoles.length !== 0 ? {excludeRoles: excludedRoles} : {}
    const results = await album.generateManifest({
      format,
      includeProperties,
      ...additionalProperties,
    })

    if (format === 'csv') {
      saveAs(new Blob([results], {type: 'text/plain;charset=utf-8'}), `export-${album.title}.csv`)
    } else {
      saveAs(
        new Blob([JSON.stringify(results)], {type: 'text/plain;charset=utf-8'}),
        `export-${album.title}-sagemaker.json`
      )
    }
  }

  function DialogContentBody() {
    if (loading)
      return (
        <LoadingElement>
          <CircularProgress />
          <p>Loading visible album attributes...</p>
        </LoadingElement>
      )

    return (
      <>
        {dialogStage === 0 && (
          <>
            <DialogContentText>Select the attributes you wish to export.</DialogContentText>
            <div style={{height: '490px'}}>
              <DialogExportAlbumAttributeSelection
                itemSchemas={itemSchemas}
                selectedAttributes={selectedAttributes}
                setSelectedAttributes={setSelectedAttributes}
              />
            </div>
          </>
        )}
        {dialogStage === 1 && (
          <>
            <DialogContentText>
              Select the asset roles you wish to exclude from the file.
            </DialogContentText>
            <div style={{height: '490px'}}>
              <DialogExportAlbumRoleExclusion possibleRoles={itemRoles} />
            </div>
          </>
        )}
        {dialogStage === 2 && (
          <>
            <div
              style={{
                height: '420px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Button
                color="primary"
                onClick={() => downloadFile('csv')}
                variant="contained"
                startIcon={<CloudDownloadIcon />}
              >
                download csv
              </Button>
              <Button
                color="primary"
                onClick={() => downloadFile('sagemaker')}
                variant="contained"
                startIcon={<CloudDownloadIcon />}
              >
                download sagemaker json
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-slide-title">Export Album</DialogTitle>
      <DialogContent sx={{minHeight: '500px', minWidth: '594px'}}>
        <div style={{padding: '24px'}}>
          <DialogExportAlbumStepper stage={dialogStage} />
        </div>
        <DialogContentBody />
      </DialogContent>
      <DialogActions>
        {(dialogStage === 1 || dialogStage === 2) && (
          <Button color="primary" onClick={() => setDialogStage(Number(dialogStage) - 1)}>
            Back
          </Button>
        )}
        {(dialogStage === 0 || dialogStage === 1) && (
          <Button
            color="primary"
            onClick={() => setDialogStage(Number(dialogStage) + 1)}
            disabled={
              (dialogStage === 0 && selectedAttributes.length === 0) ||
              (dialogStage === 1 && excludedRoles.length >= itemRoles.length)
            }
          >
            Next
          </Button>
        )}
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogExportAlbum
