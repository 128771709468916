import React, {useState} from 'react'
import styled from 'styled-components'
import {IconButton, TextField} from '@mui/material'
import {Add as AddIcon} from '@mui/icons-material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import SearchSelectedAttribute from './SearchSelectedAttribute'

const TagSelector = styled.div`
  .form {
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-column-gap: 10px;
    align-items: end;
    margin-left: 5px;
    max-width: 605px;
  }

  .text-field {
    margin-bottom: 2px;
    margin-left: 5px;
  }

  button {
    height: 35px;
  }

  .selector-field {
    width: 200px;
  }

  .pills {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
`

function AdvancedSearchSelectorTags() {
  const [newTagValue, setNewTagValue] = useState('')

  const {
    searchAttributes: {tags: selectedTags},
    setTags,
  } = useAdvancedSearch()

  const existingValue = selectedTags.some(tag => tag === newTagValue)

  function addTag() {
    setTags([newTagValue, ...selectedTags])
    setNewTagValue('')
  }

  function removeTag(index) {
    const alteredTags = [...selectedTags]

    alteredTags.splice(index, 1)
    setTags(alteredTags)
  }

  return (
    <TagSelector>
      <div className="form">
        <TextField
          value={newTagValue}
          onChange={event => setNewTagValue(event.target.value)}
          onKeyUp={event => {
            if (!existingValue && event.key === 'Enter' && newTagValue && !existingValue) {
              addTag()
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          label="Tags"
          className="text-field"
          variant="standard"
        />
        <IconButton
          aria-label="add"
          color="primary"
          disabled={!newTagValue || existingValue}
          onClick={addTag}
          size="large"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="pills">
        {selectedTags.map((tag, index) => (
          <SearchSelectedAttribute
            property={`${tag} (${index})`}
            onDelete={() => removeTag(index)}
            key={`selected-tag-${index}-${tag}`}
          />
        ))}
      </div>
    </TagSelector>
  )
}

export default AdvancedSearchSelectorTags
