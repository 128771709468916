import React, {useContext, useEffect, useState} from 'react'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {
  setProject,
  setCollection,
  addAvailableWorkflowStages,
  setAvailableWorkflowStages,
} from '../actions/imageUploaderAction'
import {EntitlementLevel, imagineServiceContext} from '../context/imagineServiceContext'
import {handleSentryError} from '../utilities/sentryErrors'
import useToastNotification from '../hooks/useToastNotification'

const Container = styled.section`
  width: 80%;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
`

const StyledFormControl = styled(FormControl)`
  margin-bottom: 20px;
`

function UploaderImagineStageProjectCollectionSelect() {
  const [projectList, setProjectList] = useState([])
  const [collectionList, setCollectionList] = useState([])
  const dispatch = useDispatch()
  const {errorNotification} = useToastNotification()
  const {imagineSdk, retrieveProjects, retrieveCollections} = useContext(imagineServiceContext)

  const setProjectAction = project => dispatch(setProject(project))
  const setCollectionAction = collection => dispatch(setCollection(collection))
  const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))
  const setWorkflowStages = stages => dispatch(setAvailableWorkflowStages(stages))

  const selectedProject = useSelector(state => state.imageUploader.selectedProject)
  const selectedCollection = useSelector(state => state.imageUploader.selectedCollection)

  // Prevent user from going back to image selection once image locked in
  // This is due to react-dropzone not being able to render with selected image pre-loaded
  useEffect(() => {
    setWorkflowStages([1])
  }, [])

  useEffect(() => {
    async function retrieveCollectionList() {
      try {
        const collections = await retrieveCollections()
        const sortedCollectionList = collections.map(collection => collection.id).sort()
        setCollectionList(sortedCollectionList)
      } catch (e) {
        const additionalErrorDetails =
          'There was an error retrieving the set of collections from Imagine.'
        handleSentryError(e, additionalErrorDetails)
      }
    }
    retrieveCollectionList()
  }, [])

  // Map projects and publish entitlements
  useEffect(() => {
    async function retrieveProjectList() {
      try {
        const listOfProjects = []
        const projects = await retrieveProjects()
        for (const project of projects) {
          const getEntitlements = await project.entitlements()
          listOfProjects.push({
            name: project.id,
            hasEntitlement: getEntitlements.currentUserHasEntitlement(EntitlementLevel.PUBLISH),
          })
        }

        function nameSort(a, b) {
          return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        }

        const enabledProjects = listOfProjects.filter(i => i.hasEntitlement)
        const disabledProjects = listOfProjects.filter(i => !i.hasEntitlement)

        setProjectList([...enabledProjects.sort(nameSort), ...disabledProjects.sort(nameSort)])
      } catch (e) {
        const additionalErrorDetails =
          'There was an error retrieving the set of projects from Imagine.'
        handleSentryError(e, additionalErrorDetails)
      }
    }

    retrieveProjectList()
  }, [])

  useEffect(() => {
    async function projectAndCollectionCheck() {
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})
      const schema = await collection.itemSchema()
      const schemaProperties = schema.jsonSchema.properties.properties.properties
      const requiredProperties = schema.jsonSchema.properties.properties.required
      for (const property of requiredProperties) {
        if (schemaProperties[property].type === 'object') {
          errorNotification('The uploader does not support "object" properties')
          setWorkflowStages([1])
          return
        }
      }
      addWorkflowStages([2])
    }

    if (selectedProject && selectedCollection) projectAndCollectionCheck()
  }, [selectedCollection, selectedProject])

  const collection = useSelector(state => state.imageUploader.selectedCollection)
  const project = useSelector(state => state.imageUploader.selectedProject)

  return (
    <>
      <Container>
        <Typography variant="h6">Set Item Project and Collection</Typography>
        <br />
        <StyledFormControl>
          <InputLabel id="uploadProjectSelect">Project *</InputLabel>
          <Select
            labelId="uploadProjectSelect"
            value={project}
            onChange={e => setProjectAction(e.target.value)}
            variant="standard"
          >
            {/* set-selected-project */}
            {projectList &&
              projectList.map(({name, hasEntitlement}, index) => (
                <MenuItem
                  key={`project-dropdown-${name}-${index}`}
                  value={name}
                  disabled={!hasEntitlement}
                >
                  {name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            Projects are groupings that are used for controlling access.
          </FormHelperText>
        </StyledFormControl>
        <FormControl>
          <InputLabel id="uploadCollectionSelect">Collection *</InputLabel>
          <Select
            labelId="uploadCollectionSelect"
            value={collection}
            onChange={e => setCollectionAction(e.target.value)}
            helpertext="test"
            variant="standard"
          >
            {collectionList &&
              collectionList.map(i => (
                <MenuItem key={`collection-dropdown-${i}`} value={i}>
                  {i}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            Collections dictate the metadata schema of an Item and its assets.
          </FormHelperText>
        </FormControl>
      </Container>
    </>
  )
}

export default UploaderImagineStageProjectCollectionSelect
