import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'
import styled from 'styled-components'

const CountContainer = styled.div`
  position: relative;
  width: 0;
  height: 0;
  left: 110px;
  top: 0;

  .count {
    background: var(--mdc-theme-primary);
    width: 65px;
    padding-left: 3px;
    padding-right: 3px;
    height: 35px;
    border-radius: 0 0 0 10px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiTypography-root {
    color: white;
  }
`

function DirectoryCardItemsCount(props) {
  const {count} = props

  return (
    <CountContainer>
      <div className="count">
        <Typography variant="caption">{new Intl.NumberFormat('en-us').format(count)}</Typography>
      </div>
    </CountContainer>
  )
}

DirectoryCardItemsCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default DirectoryCardItemsCount
