import React, {createContext, useState} from 'react'

const initialState = {
  queryParamSearchTerms: {},
  searchTerm: '',
}

const galleryBasicSearchContext = createContext(initialState)
const {Provider} = galleryBasicSearchContext

/**
 * Pushes the basic search params into context form
 * */
function GalleryBasicSearchProvider(props) {
  const {queryParamSearchTerms, searchTerm, children} = props

  const [selectedAccordion, setSelectedAccordion] = useState(-1)
  function toggleAccordion(index) {
    if (selectedAccordion === index) {
      setSelectedAccordion(-1)
    } else {
      setSelectedAccordion(index)
    }
  }

  return (
    <Provider
      value={{
        queryParamSearchTerms,
        searchTerm,
        toggleAccordion,
        selectedAccordion,
      }}
    >
      {children}
    </Provider>
  )
}

export {galleryBasicSearchContext, GalleryBasicSearchProvider}
