import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Accordion, AccordionSummary, AccordionDetails, Button, Typography} from '@mui/material'
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Equalizer} from '@mui/icons-material'
import {Link} from 'react-router-dom'

import GallerySummaryHistogramLoading from './GallerySummaryHistogramLoading'
import GallerySummaryHistogram from './GallerySummaryHistogram'

import {galleryBasicSearchContext} from '../context/galleryBasicSearchContext'

const Container = styled.section`
  max-width: 800px;

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }

  .error-threshold {
    padding-top: 6px;
    display: grid;
    grid-template-columns: 35px 325px;
    grid-column-gap: 10px;
    justify-items: center;
    align-items: center;
  }

  .cancelled-icon::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: var(--mdc-theme-text-primary-on-light);
    height: 30px;
    transform: rotate(50deg);
    left: 33px;
    bottom: 16px;
  }
`

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: 15px;
  justify-items: center;
  align-items: center;

  .copy-none-available {
    justify-self: flex-start;
  }
`

const ResetContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  margin-top: 15px;
  padding-top: 8px;
  border-top: 1px solid var(--mdc-theme-text-primary-on-light);

  width: 100%;
`

function GallerySummaryBasicSearchAccordion(props) {
  const {selectedAccordion, toggleAccordion} = useContext(galleryBasicSearchContext)

  const {
    projectsHistogram,
    collectionsHistogram,
    tagsHistogram,
    numberItems,
    searchTerm,
    exceedsSizeThreshold,
    numberResultingItems,
    loadingHistogram,
    accordionIndex,
    loadingPct,
  } = props

  const Layout = ({children}) => (
    <Container>
      <Accordion
        expanded={selectedAccordion === accordionIndex}
        onChange={() => toggleAccordion(accordionIndex)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SummaryContainer>
            <Equalizer color="primary" />
            <Typography variant="body2" color="primary">
              Search Results Summary
            </Typography>
          </SummaryContainer>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Container>
  )

  if (exceedsSizeThreshold)
    return (
      <Container>
        <Accordion disabled>
          <AccordionDetails>
            <div className="error-threshold">
              <div className="cancelled-icon">
                <Equalizer />
              </div>
              <Typography variant="body2">
                Search Results Summary <em>(search terms too broad)</em>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </Container>
    )

  if (numberResultingItems === 0) {
    return (
      <Container>
        <Accordion disabled>
          <AccordionDetails>
            <SummaryContainer>
              <Equalizer color="primary" />
              <Typography variant="body2" className="copy-none-available">
                Search Results Summary Unavailable <em>(no items)</em>
              </Typography>
            </SummaryContainer>
          </AccordionDetails>
        </Accordion>
      </Container>
    )
  }

  if (loadingHistogram)
    return (
      <Container>
        <Accordion disabled>
          <AccordionDetails>
            <GallerySummaryHistogramLoading value={loadingPct} />
          </AccordionDetails>
        </Accordion>
      </Container>
    )

  return (
    <Layout>
      <GallerySummaryHistogram
        projectsHistogram={projectsHistogram}
        collectionsHistogram={collectionsHistogram}
        tagsHistogram={tagsHistogram}
        numberItems={numberItems}
        searchTerm={searchTerm}
      />
      <ResetContainer>
        <Link to="#">
          <Button color="primary">reset</Button>
        </Link>
      </ResetContainer>
    </Layout>
  )
}

export default GallerySummaryBasicSearchAccordion
