import React, {useState} from 'react'
import PropTypes from 'prop-types'

import useToastNotification from '../hooks/useToastNotification'
import DialogMultiTagAddForm from './DialogMultiTagAddForm'
import {handleSentryError} from '../utilities/sentryErrors'

/**
 * Trigger button and workflows for adding multiple tags to items
 */
function DialogMultiTagAdd(props) {
  const {handleClose, selectedItems, untaggableIds} = props
  const [updatingTags, setUpdatingTags] = useState(false)

  const alterableItems = selectedItems.filter(item => untaggableIds.indexOf(item.id) === -1)

  const {errorNotification, successNotification, warningNotification} = useToastNotification()

  function resetDialog() {
    setUpdatingTags(false)
  }

  async function setTags(newTags) {
    setUpdatingTags(true)
    let errorsCount = 0
    const tagsCount = selectedItems.length

    const addTagsPromise = alterableItems.map(async item => {
      try {
        const properties = await item.properties()
        const tags = properties.tags ?? []

        const newTagValues = [...new Set([...tags, ...newTags].filter(i => i))]
        await item.updateMetadata({properties: {tags: newTagValues}})
      } catch (e) {
        const additionalErrorDetails = `Error in updating tags for item ${item.id}.`
        handleSentryError(e, additionalErrorDetails)
        errorsCount++
      }
    })

    await Promise.allSettled(addTagsPromise)

    if (errorsCount === 0) {
      successNotification('Success: Tags updated')
    } else if (errorsCount < tagsCount) {
      warningNotification('Some tags could not be updated')
    } else {
      errorNotification('No tags could be updated')
    }
    resetDialog()
  }

  return (
    <DialogMultiTagAddForm handleClose={handleClose} loading={updatingTags} setTags={setTags} />
  )
}

DialogMultiTagAdd.propTypes = {
  /** Function used to close the dialog */
  handleClose: PropTypes.func.isRequired,
  /** List of selected items to carry out operations upon */
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Subset of selected items which cannot be tagged */
  untaggableIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DialogMultiTagAdd
