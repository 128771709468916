import React from 'react'
import styled from 'styled-components'
import {CircularProgress, Typography} from '@mui/material'
import {InfoOutlined as InfoIcon, WarningOutlined as WarnIcon} from '@mui/icons-material'
import {grey} from '@mui/material/colors'

const WarningElement = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  .icon {
    margin-right: 8px;
  }

  .spin {
    margin-right: 18px;
  }

  color: ${grey[500]};
  font-weight: 500;

  p {
    margin-top: 2px;
  }
`

/** Inline warning messages that are used in the Properties section of the advanced search */
const WarningMessage = ({message, info}: {message: string; info?: boolean}) => (
  <WarningElement>
    {info ? <InfoIcon className="icon" /> : <WarnIcon className="icon" />}
    <Typography variant="body1">{message}</Typography>
  </WarningElement>
)

const LoadingMessage = () => (
  <WarningElement>
    <CircularProgress className="spin" />
    <Typography variant="body1">Loading collection properties...</Typography>
  </WarningElement>
)

export {LoadingMessage, WarningMessage}
