import React from 'react'
import PropTypes from 'prop-types'

import {Chip, TableCell, TableRow} from '@mui/material/'
import styled from 'styled-components'

const MarginedChip = styled(Chip)`
  margin: 0 4px 2px 0;
  font-style: normal;
`

const ClickableMarginedChip = styled(Chip)`
  margin: 0 4px 2px 0;
  font-style: normal;
  cursor: pointer;
`

/**
 * A row for an array contaning strings
 */
function ItemMetadataListScalarArrayTableRow(props) {
  const {title, valuesArray, onClickChip} = props

  const Chip = onClickChip ? ClickableMarginedChip : MarginedChip

  const chipSet = valuesArray.map((value, lowerIndex) => (
    <Chip
      key={`metadata-table-array-item-${title}-${value}-${lowerIndex}`}
      label={value}
      variant="outlined"
      color="primary"
      onClick={() => onClickChip?.(value)}
    />
  ))

  return (
    <TableRow key={`metadata-table-array-${title}`}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="left" className="subdue">
        {chipSet}
      </TableCell>
    </TableRow>
  )
}

ItemMetadataListScalarArrayTableRow.propTypes = {
  /**  The title of the row */
  title: PropTypes.string.isRequired,
  /** The array of string values */
  valuesArray: PropTypes.arrayOf(PropTypes.string),
  onClickChip: PropTypes.func,
}

ItemMetadataListScalarArrayTableRow.defaultProps = {
  valuesArray: [],
}

export default ItemMetadataListScalarArrayTableRow
