import React from 'react'
import {ImageListItem} from '@mui/material'
import {grey} from '@mui/material/colors'
import styled from 'styled-components'

import FallbackImage from './FallbackImage'

const StyledImageListItem = styled(ImageListItem)`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  border: 2px solid ${() => grey['200']};

  transition: border 0.35s ease-in-out;
  overflow: hidden;
  font-size: 1rem;

  .tile-bottom {
    position: relative;
    bottom: 49px;
    width: calc(${props => props.width}px - 4px);
    height: ${props => props.width}px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 400;
    font-size: 90%;
    line-height: 1.2;
    transition: bottom 0.35s ease-in-out, background-color 0.35s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

/**
 * Displays a gallery grid item
 */
function CardGalleryViewItemSkeleton({width}) {
  return (
    <StyledImageListItem width={width} className="gallery-view-tile">
      <FallbackImage src="" />
      <div className="tile-bottom">
        <div className="tile-bottom-information" />
      </div>
    </StyledImageListItem>
  )
}

export default CardGalleryViewItemSkeleton
