import { ObjectKeyValue } from '../../utilities/ObjectKeyValue';
import { SearchParameters } from './SearchParameters';

export enum SearchActionType {
  UPDATE_VALUES = 'update-value',
  UPDATE_MULTIPLE_VALUES = 'update-multiple-values',
  RESET = 'reset'
}

export type SearchAction = UpdateValuesAction | ResetAction;

export type UpdateValuesValue = Partial<ObjectKeyValue<SearchParameters>>;

export type UpdateValuesAction = {
  type: SearchActionType.UPDATE_VALUES;
  values: UpdateValuesValue;
};

export type ResetAction = {
  type: SearchActionType.RESET;
};
