import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {IconButton, TextField} from '@mui/material'
import {Done} from '@mui/icons-material'

/** A single text field for editing a proposed tag */
function DialogMultiTagAddFormEditField(props) {
  const {value, setValue} = props
  const [tempValue, setTempValue] = useState(value)

  return <>
    <TextField
      value={tempValue}
      onChange={event => setTempValue(event.target.value)}
      onKeyUp={e => e.key === 'Enter' && setValue(tempValue)}
      onBlur={() => setValue(tempValue)}
      className="form"
      autoFocus
    />
    <IconButton className="confirm" onClick={() => setValue(tempValue)} size="large">
      <Done color="primary" />
    </IconButton>
  </>;
}

DialogMultiTagAddFormEditField.propTypes = {
  /** Initial value of the field */
  value: PropTypes.string.isRequired,
  /** Sets the value of the field on set */
  setValue: PropTypes.func.isRequired,
}

export default DialogMultiTagAddFormEditField
