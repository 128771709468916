import React, {isValidElement, memo, useContext} from 'react'

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'

import {itemContext} from '../context/itemContext'

import ItemMetadataListScalarArrayTableRow from './ItemMetadataListScalarArrayTableRow'
import ItemMetadataListTagArrayTableRow from './ItemMetadataListTagArrayTableRow'

import TextCopyable from './TextCopyable'
import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'
import {isUUID} from '../utilities/uuid'
import {useNavigate} from 'react-router-dom'

const RECURSIVE_TABLE_DEPTH = 3

const nullValueStyle = {
  color: ' #888',
  fontStyle: 'oblique',
}

const splitMetadataSections = list => {
  const presentableRows = []
  const scalarArrayRows = []
  const tagArrayRows = []

  const nestedMetadataSections = []

  list.forEach(element => {
    if (typeof element[1] !== 'object') {
      presentableRows.push(element)
    } else if (isValidElement(element[1])) {
      presentableRows.push(element)
    } else if (/asset/.test(element[0])) {
      // Do nothing
    } else if (Array.isArray(element[1])) {
      const types = [...new Set(element[1].map(i => typeof i))]

      if (types.length > 1 || types[0] === 'object') {
        nestedMetadataSections.push(element)
      } else if (types[0] === 'string') {
        tagArrayRows.push(element)
      } else {
        scalarArrayRows.push(element)
      }
    } else if (
      element[1] === null ||
      element[1] === undefined ||
      Object.keys(element[1]).length === 0
    ) {
      presentableRows.push([element[0], null])
    } else {
      nestedMetadataSections.push(element)
    }
  })
  return {
    presentableRows,
    scalarArrayRows,
    nestedMetadataSections,
    tagArrayRows,
  }
}

function ItemMetadataPresentable({value}) {
  if (value === null || value === undefined || value === '') {
    return <span className={nullValueStyle}>(None provided)</span>
  } else if (typeof value === 'boolean') {
    return value ? <>True</> : <>False</>
  } else if (isUUID(value)) {
    return <TextCopyable value={value} />
  }
  return value
}

/**
 * The entry point for displaying Item metadata as a table.
 * uses recursion to obtain sub-properties.
 */
function ItemMetadataTableRecursive() {
  const {
    itemMetadata: {metadata},
  } = useContext(itemContext)
  const navigate = useNavigate()

  let metadataList = Object.entries(metadata).filter(i => i[0] !== 'tags')

  metadataList = metadataList.sort((a, b) => {
    if (a[0][0].toUpperCase() < b[0][0].toUpperCase()) return 1
    if (b[0][0].toUpperCase() < a[0][0].toUpperCase()) return -1
    return 0
  })

  window.metadataList = metadataList

  const NestedMetadataTableRow = ({list, level = 0, title}) => {
    // Limit number of recursions
    if (level > RECURSIVE_TABLE_DEPTH) return <></>

    const splitSections = splitMetadataSections(list)
    const {presentableRows, scalarArrayRows, nestedMetadataSections, tagArrayRows} = splitSections

    return (
      <>
        <TableRow>
          <TableCell />
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            <strong>{title}</strong>
          </TableCell>
          <TableCell align="left" />
        </TableRow>
        {presentableRows.reverse().map(([key, value], index) => {
          return (
            <TableRow key={`metadata-table-level-${level}-${index}-${key}`}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell align="left">
                <ItemMetadataPresentable value={value} />
              </TableCell>
            </TableRow>
          )
        })}
        {scalarArrayRows.length > 0 &&
          scalarArrayRows.map(([key, valuesArray]) => (
            <ItemMetadataListScalarArrayTableRow
              title={key}
              valuesArray={valuesArray}
              key={`scalar-array-${key}`}
            />
          ))}
        {tagArrayRows.length > 0 &&
          tagArrayRows.map(([key, valuesArray]) => (
            <ItemMetadataListTagArrayTableRow
              title={key}
              valuesArray={valuesArray}
              key={`scalar-array-${key}`}
              onClickChip={tag => {
                navigate(`/advanced-search?tags=${tag}`)
              }}
            />
          ))}

        {Object.keys(nestedMetadataSections).length > 0 &&
          nestedMetadataSections.map(([key, value], index) => (
            <NestedMetadataTableRow
              title={key}
              list={Object.entries(value)}
              level={level + 1}
              key={`nested-metadata-section-${key}-${level + 1}-${index}`}
            />
          ))}
      </>
    )
  }

  const splitSections = splitMetadataSections(metadataList)

  const {presentableRows, scalarArrayRows, nestedMetadataSections, tagArrayRows} = splitSections

  return (
    <>
      <ItemMetadataHeaderAccordion header="Metadata">
        <TableContainer>
          <Table style={{minWidth: 450}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell align="left">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {presentableRows.reverse().map(([key, value], index) => {
                return (
                  <TableRow key={`metadata-table-${index}-${key}`}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="left">
                      <ItemMetadataPresentable value={value} />
                    </TableCell>
                  </TableRow>
                )
              })}
              {scalarArrayRows.length > 0 &&
                scalarArrayRows.map(([key, valuesArray]) => (
                  <ItemMetadataListScalarArrayTableRow
                    title={key}
                    valuesArray={valuesArray}
                    key={`scalar-array-${key}`}
                  />
                ))}
              {Object.keys(nestedMetadataSections).length > 0 &&
                nestedMetadataSections.map(([key, value], index) => (
                  <NestedMetadataTableRow
                    title={key}
                    list={Object.entries(value)}
                    level={1}
                    key={`nested-metadata-section-${key}-${0}-${index}`}
                  />
                ))}
              {tagArrayRows.length > 0 &&
                tagArrayRows.map(([key, valuesArray]) => (
                  <ItemMetadataListTagArrayTableRow
                    title={key}
                    valuesArray={valuesArray}
                    key={`scalar-array-${key}`}
                    onClickChip={tag => {
                      navigate(`/advanced-search?tags=${tag}`)
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default memo(ItemMetadataTableRecursive)
