import React from 'react'
import PropTypes from 'prop-types'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {TextField} from '@mui/material'

function UploaderOrthoMosaicMetadataFieldDate(props) {
  const {errorLevel, errorMessage, helperText, label, reactRef, setValue, value} = props

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div ref={reactRef}>
        <DatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          format="YYYY-MM-DD"
          value={value}
          onChange={moment => {
            setValue(new Date(moment))
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          helperText={errorMessage ?? helperText}
          label={label}
          error={errorLevel === 'error'}
          renderInput={params => <TextField {...params} />}
        />
      </div>
    </LocalizationProvider>
  )
}

UploaderOrthoMosaicMetadataFieldDate.propTypes = {
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  reactRef: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
}

UploaderOrthoMosaicMetadataFieldDate.defaultProps = {
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  value: null,
}

export default UploaderOrthoMosaicMetadataFieldDate
