import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

import {
  setMissionPackageDiscoveryConfirmDialog,
  setUploaderWorkflowStage,
} from '../actions/imageUploaderAction'

function UploaderMissionPackageDialogConfirmDiscovery() {
  const [value, setValue] = useState('')
  const expectedValue = 'this mission will not be processed'

  const dispatch = useDispatch()

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(setMissionPackageDiscoveryConfirmDialog(false))}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to upload this mission to the discovery workflow, which is only used for
          experiments and diagnosing issues.{' '}
          <strong>Missions uploaded to this workflow are not processed</strong>.
        </DialogContentText>

        <DialogContentText style={{textDecoration: 'underline'}}>
          Type the following to confirm:
        </DialogContentText>
        <DialogContentText style={{userSelect: 'none', cursor: 'not-allowed'}}>
          <pre>{expectedValue}</pre>
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          type="text"
          onKeyUp={e => {
            setValue(e.target.value)
          }}
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(setMissionPackageDiscoveryConfirmDialog(false))}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => dispatch(setUploaderWorkflowStage(2))}
          color="primary"
          disabled={value !== expectedValue}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploaderMissionPackageDialogConfirmDiscovery
