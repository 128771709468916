import React, {memo, useContext, useEffect, useReducer, useState} from 'react'
import {handleSentryError} from '../utilities/sentryErrors'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import {FormControl, IconButton, MenuItem, Select, TextField} from '@mui/material'
import styled from 'styled-components'
import {Add as AddIcon} from '@mui/icons-material'

import {imagineServiceContext} from '../context/imagineServiceContext'
import useAdvancedSearch from '../hooks/useAdvancedSearch'
import searchComparisonOperators from '../utilities/searchComparisonOperators'
import {LoadingMessage, WarningMessage} from './AdvancedSearchWarningElement'
import {sasDptUavAnalytics} from './staticSchemas'

const CollectionPropertiesSearchElement = styled.div`
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 340px 60px 1fr 29px;
  grid-column-gap: 5px;
  height: 40px;
  margin-left: 5px;

  .MuiChip-root {
    margin: 2px;
  }

  .MuiAutocomplete-root {
    width: 340px;
    height: 32px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    padding-bottom: 4px;
    height: 32px;

    .MuiSvgIcon-root {
      margin-bottom: 4px;
    }
  }

  .MuiInput-root {
    height: 32px;
  }

  .select-input {
    padding-left: 5px;
    height: 30px;
  }

  button {
    height: 35px;
  }
`

type FormState = {
  loading: boolean
  collectionProperties: FormProperty[]
  property: FormProperty
  propertyAutocompleteValue: string
  comparisonValue: string
  comparisonAutocompleteValue: string
  searchValue: string
}

const initialState: FormState = {
  loading: false,
  collectionProperties: [{name: '', type: 'text', category: ''}],
  property: null as FormProperty,
  propertyAutocompleteValue: '',
  comparisonValue: '==',
  comparisonAutocompleteValue: '',
  searchValue: '',
}

type FormAction = {
  type: string
  loading?: boolean
  properties?: FormProperty[]
  property?: FormProperty
  value?: any
}

function reducer(state: FormState, action: FormAction) {
  switch (action.type) {
    case 'set-loading':
      return {
        ...state,
        loading: action.loading,
      }
    case 'stop-loading':
      return {...state, loading: false, propertyAutocompleteValue: ''}
    case 'set-properties':
      return {
        ...state,
        loading: false,
        collectionProperties: action.properties,
        propertyAutocompleteValue: '',
      }

    case 'set-property-autocomplete-value':
      return {...state, propertyAutocompleteValue: action.value}
    case 'set-property':
      return {
        ...state,
        property: action.property,
        propertyAutocompleteValue: action.property.name,
      }
    case 'reset-property':
      return {
        ...state,
        property: null,
        propertyAutocompleteValue: '',
      }

    case 'set-comparison-value':
      return {...state, comparisonValue: action.value}
    case 'set-search-value':
      return {...state, searchValue: action.value}
    case 'reset-search-row':
      return {...initialState, collectionProperties: state.collectionProperties}
    default:
      throw new Error(`Unknown reducer type ${action.type} in Properties Form `)
  }
}

type FormProperty = {
  category: string
  dateText?: boolean
  displayLabel?: string
  name: string
  type: 'text' | 'number' | 'boolean' | 'freeSolo'
}

type FormPropertyType = {
  type: 'string' | 'number' | 'integer' | 'boolean' | 'array'
  items?: {type: 'integer' | 'number' | 'string'}
}

type FormPropertyObjectType = {
  type: 'object'
  properties: Record<string, any>
}

function propertyContainsDateTimeKeyWord(keywords: string[], property: string) {
  const propertyLowered = property.toLowerCase()
  for (const keyword of keywords) {
    if (propertyLowered.includes(keyword)) return true
  }
  return false
}

function createFormProperty(
  name: string,
  value: FormPropertyType,
  prependValue: string
): FormProperty | null {
  const excludeList = [
    'datetime', // Already used for date restriction
    'project:name', // Already used for project selector portion
    'tags', // Used in tags field in search form
  ]
  if (excludeList.indexOf(name) !== -1) return null

  // Some elements are treated as text through the stac spec, though clearly aren't
  const dateList = ['created', 'updated', 'created_at', 'updated_at', 'createdAt', 'updatedAt']
  const dateTimeKeywords = ['date', 'time', 'created', 'modified', 'uploaded']

  const displayLabel = name
  const category = prependValue ? prependValue.split('.').join(' ') : ''

  if (dateList.indexOf(name) !== -1) {
    return {
      name: `${prependValue}${name}`,
      type: 'text',
      dateText: true,
      category,
    }
  } else if (value.type === 'string') {
    const additionalProperties = propertyContainsDateTimeKeyWord(dateTimeKeywords, name)
      ? {dateText: true}
      : {displayLabel}
    return {
      name: `${prependValue}${name}`,
      type: 'text',
      category,
      ...additionalProperties,
    }
  } else if (value.type === 'integer' || value.type === 'number') {
    return {
      name: `${prependValue}${name}`,
      type: 'number',
      category,
      displayLabel,
    }
  } else if (value.type === 'array' && value?.items?.type === 'string') {
    return {
      name: `${prependValue}${name}`,
      type: 'text',
      category,
      displayLabel,
    }
  } else if (
    (value.type === 'array' && value?.items?.type === 'number') ||
    (value.type === 'array' && value?.items?.type === 'integer')
  ) {
    return {
      name: `${prependValue}${name}`,
      type: 'number',
      category,
      displayLabel,
    }
  } else if (value.type === 'boolean') {
    return {
      name: `${prependValue}${name}`,
      type: 'boolean',
      category,
      displayLabel,
    }
  }

  return null
}

function AdvancedSearchSelectorCollectionPropertiesForm(props: {setSectionWarning: any}) {
  const {setSectionWarning} = props
  const {imagineSdk} = useContext(imagineServiceContext)
  const {
    setCollectionProperties: setSearchCollectionProperties,
    searchAttributes: {collections: selectedCollections, properties: selectedProperties},
  } = useAdvancedSearch()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [toggleRender, setToggleRender] = useState(false)
  const {
    loading,
    collectionProperties,
    property: selectedProperty,
    propertyAutocompleteValue,
    comparisonValue,
    searchValue,
  } = state

  const setLoading = (loading: boolean) => dispatch({type: 'set-loading', loading})
  const setCollectionProperties = (properties: FormProperty[]) =>
    dispatch({type: 'set-properties', properties})
  const setPropertyAutocompleteValue = (value: any) =>
    dispatch({type: 'set-property-autocomplete-value', value})
  const setSelectedProperty = (property: FormProperty) => dispatch({type: 'set-property', property})
  const resetSelectedProperty = () => {
    dispatch({type: 'reset-property'})
    setToggleRender(!toggleRender)
  }
  const setComparisionValue = (value: any) =>
    dispatch({
      type: 'set-comparison-value',
      value,
    })
  const setSearchValue = (value: any) => dispatch({type: 'set-search-value', value})
  const resetSearchProperties = () => setSearchCollectionProperties([])

  function addPropertyCriteria() {
    setSearchCollectionProperties([
      {
        comparison: comparisonValue,
        property: selectedProperty.name,
        value: searchValue,
      },
      ...selectedProperties,
    ])
    resetSelectedProperty()
  }

  function createFormPropertiesFromSchemaPropertiesRecursive(
    properties: any,
    result: FormProperty[],
    key: string
  ) {
    return Object.entries(properties).forEach(([name, value]) => {
      const isObjectProperty = (value as FormPropertyObjectType).type === 'object'
      if (isObjectProperty) {
        createFormPropertiesFromSchemaPropertiesRecursive(
          properties[name].properties,
          result,
          `${key}${name}.`
        )
      } else {
        const property = createFormProperty(name, value as FormPropertyType, key)
        if (property) {
          result.push(property)
        }
      }
    })
  }

  function createFormPropertiesFromSchemaProperties(properties: any) {
    const formProperties = []
    createFormPropertiesFromSchemaPropertiesRecursive(properties, formProperties, 'properties.')
    formProperties.sort((a, b) => a.category.localeCompare(b.category))
    return formProperties
  }

  async function createFormPropertiesFromCollection(
    collectionName: string
  ): Promise<FormProperty[]> {
    const collection = await imagineSdk.fetchCollection({id: collectionName})
    const itemSchema = await collection.itemSchema()
    const jsonSchema = itemSchema.jsonSchema as any
    const schemaProperties = jsonSchema?.properties?.properties?.properties ?? {}
    return createFormPropertiesFromSchemaProperties(schemaProperties)
  }

  async function createFormProperties(collectionName: string): Promise<FormProperty[]> {
    switch (collectionName) {
      case 'sas_dpt_uav_analytics':
        return createFormPropertiesFromSchemaProperties(sasDptUavAnalytics.properties)
      default:
        return createFormPropertiesFromCollection(collectionName)
    }
  }

  function handleSelect(value: string) {
    if (value === null) {
      resetSelectedProperty()
      return
    }
    const matchingProperties = collectionProperties.filter((p: FormProperty) => p.name === value)
    if (!matchingProperties?.length) {
      setSelectedProperty({
        category: '',
        displayLabel: value,
        name: value,
        type: 'freeSolo',
      })
      return
    }
    const matchingProperty = matchingProperties[0]
    setSelectedProperty(matchingProperty)
  }

  useEffect(() => {
    if (selectedCollections?.length === 1) {
      setLoading(true)
      setSectionWarning(() => <LoadingMessage />)
      createFormProperties(selectedCollections[0])
        .then(properties => {
          setCollectionProperties(properties)
          resetSelectedProperty()
          setSectionWarning(() => <></>)
        })
        .catch(err => {
          handleSentryError(err, 'Error loading collection properties')
          setSectionWarning(() => <WarningMessage message="Error loading collection properties" />)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      resetSearchProperties()
      setSectionWarning(() => (
        <WarningMessage
          message={
            selectedCollections?.length > 1
              ? 'Select only one collection to use this section'
              : 'Select a collection in order to use this section'
          }
        />
      ))
    }
  }, [selectedCollections.length])

  let comparisonValueOperators = searchComparisonOperators
    .filter(operator => {
      if (selectedProperty?.type === 'freeSolo') {
        return true
      }
      return selectedProperty?.dateText
        ? operator.usedWith.indexOf('number') !== -1
        : operator.usedWith.indexOf(selectedProperty?.type) !== -1
    })
    .map((operator, index) => (
      <MenuItem
        value={operator.value}
        disabled={!selectedProperty}
        key={`comparison-operator-${index}`}
      >
        {operator.value} {operator.description}
      </MenuItem>
    ))

  if (comparisonValueOperators.length === 0) {
    comparisonValueOperators = [
      <MenuItem value="==" disabled key="disabled-operator">
        ==
      </MenuItem>,
    ]
  }

  return (
    <CollectionPropertiesSearchElement>
      <Autocomplete
        key={toggleRender.toString()}
        loading={loading}
        groupBy={option => option.category}
        getOptionLabel={option => option.displayLabel || option.name || option || ''}
        disabled={selectedCollections.length !== 1 || loading}
        options={loading ? [{name: '...loading'}] : collectionProperties}
        disableClearable
        size="small"
        isOptionEqualToValue={(option, value) => {
          const optionName = option.name

          return new RegExp(value).test(optionName)
        }}
        onChange={(_, value) => handleSelect(value.name)}
        onSelect={event => handleSelect((event.target as any).value)}
        // onClick={handleSelect}
        onKeyUp={event => setPropertyAutocompleteValue((event.target as any)?.value)}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            placeholder="property"
            className="autocomplete-input"
          />
        )}
        value={propertyAutocompleteValue}
        freeSolo
        clearOnBlur
        autoSelect
      />
      <FormControl>
        <Select
          defaultValue="=="
          className="select-element"
          value={comparisonValue}
          variant="standard"
          onChange={event => setComparisionValue(event.target.value)}
          disabled={
            selectedCollections.length !== 1 ||
            collectionProperties.length === 0 ||
            selectedProperty == null
          }
          renderValue={value => <div style={{paddingLeft: '2px'}}>{value}</div>}
        >
          {comparisonValueOperators}
        </Select>
      </FormControl>
      {/* Determines whether field is special date column (cannonically text as per STAC spec) */}
      {(() => {
        if (selectedProperty?.dateText) {
          return (
            <TextField
              placeholder="value"
              type="datetime-local"
              variant="standard"
              disabled={
                selectedCollections.length !== 1 ||
                collectionProperties.length === 0 ||
                selectedProperty == null ||
                comparisonValue == null
              }
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onKeyUp={event => {
                if (event.key === 'Enter' && selectedProperty && comparisonValue && searchValue) {
                  addPropertyCriteria()
                }
              }}
            />
          )
        } else if (selectedProperty?.type === 'boolean') {
          return (
            <Select
              type={selectedProperty?.type ?? 'boolean'}
              disabled={
                selectedCollections.length !== 1 ||
                collectionProperties.length === 0 ||
                selectedProperty == null ||
                comparisonValue == null
              }
              variant="standard"
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onKeyUp={event => {
                if (event.key === 'Enter' && selectedProperty && comparisonValue && searchValue) {
                  addPropertyCriteria()
                }
              }}
            >
              <MenuItem value={'true'}>true</MenuItem>
              <MenuItem value={'false'}>false</MenuItem>
            </Select>
          )
        } else {
          return (
            <TextField
              placeholder="value"
              type={selectedProperty?.type ?? 'text'}
              variant="standard"
              disabled={
                selectedCollections.length !== 1 ||
                collectionProperties.length === 0 ||
                selectedProperty == null ||
                comparisonValue == null
              }
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onKeyUp={event => {
                if (event.key === 'Enter' && selectedProperty && comparisonValue && searchValue) {
                  addPropertyCriteria()
                }
              }}
            />
          )
        }
      })()}
      <IconButton
        aria-label="add"
        color="primary"
        disabled={!(selectedProperty && comparisonValue && searchValue)}
        onClick={addPropertyCriteria}
        size="large"
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </CollectionPropertiesSearchElement>
  )
}

AdvancedSearchSelectorCollectionPropertiesForm.propTypes = {
  setSectionWarning: PropTypes.func.isRequired,
}

export default memo(AdvancedSearchSelectorCollectionPropertiesForm)
