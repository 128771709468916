import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FileCopy as FileCopyIcon, DoneOutlined as CopiedIcon} from '@mui/icons-material'
import {IconButton, Tooltip} from '@mui/material'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'

const HiddenInputContainer = styled('div')`
  width: 48px;
  height: 48px;
  overflow: hidden;

  input {
    position: relative;
    height: 0;
    width: 0;
    left: 200vw;
  }
`

/** An icon button that copies a value to the clipboard when triggered */
function ItemIconCopyButton(props) {
  const [copied, setCopied] = useState(false)

  const {value} = props

  const changeButtonIcon = () => {
    setCopied(true)
    // Return indicator to previous position in 3s
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <Tooltip title={copied ? 'Copied to Clipboard' : 'Copy to Clipboard'}>
      <HiddenInputContainer>
        <CopyToClipboard text={value} onCopy={changeButtonIcon}>
          <IconButton onClick={changeButtonIcon} size="large">
            {copied ? <CopiedIcon /> : <FileCopyIcon />}
          </IconButton>
        </CopyToClipboard>
      </HiddenInputContainer>
    </Tooltip>
  )
}

ItemIconCopyButton.propTypes = {
  /** The value of the item to be copied */
  value: PropTypes.string.isRequired,
}

export default ItemIconCopyButton
