import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts'

export default function GallerySummaryCollectionTimeline({data, message}) {
  const [timelineData, setTimelineData] = useState(data)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    data.sort((a, b) => new Date(a.date) - new Date(b.date))
    setTimelineData(data)
    setIsLoading(false)
  }, [data])

  const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
      const description = `${payload[0].value} ${payload[0].value === 1 ? 'item' : 'items'} added`
      return (
        <div style={{padding: '5px 15px', backgroundColor: 'rgba(255, 255, 255, 0.9)'}}>
          <p className="label">{`${label}`}</p>
          <p className="intro">{description}</p>
        </div>
      )
    }

    return null
  }

  if (isLoading) {
    return <div>Loading. Please wait. </div>
  } else if (!timelineData.length) {
    return <div>No timeline data available to display for this collection.</div>
  } else {
    return (
      <>
        <div>{message}</div>
        <ResponsiveContainer width="100%" aspect={5}>
          <BarChart
            width={500}
            height={300}
            data={timelineData}
            margin={{
              top: 20,
              right: 60,
              left: 10,
              bottom: 20,
            }}
          >
            <XAxis dataKey="date" />
            <YAxis
              scale="log"
              domain={[0.8, 'auto']}
              allowDataOverflow
              label={{value: 'Items Added', angle: -90, position: 'insideLeft'}}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="added" fill="var(--mdc-theme-primary)" maxBarSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  }
}

GallerySummaryCollectionTimeline.propTypes = {
  /** The data to be displayed in the timeline */
  data: PropTypes.array,
  message: PropTypes.string,
}

GallerySummaryCollectionTimeline.defaultProps = {
  message: '',
}
