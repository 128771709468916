// Actions which pertain to uploading images through the Imagine Uploader Dialog
const _setUploadType = uploadType => ({
  type: 'SET_UPLOAD_TYPE',
  uploadType,
})

export const cancelUpload = () => ({
  type: 'CANCEL_IMAGE_UPLOAD',
})

export const setUploadType = type => _setUploadType(type)

export const initializeUploader = () => ({
  type: 'OPEN_IMAGE_UPLOADER',
})

export const closeUploader = () => ({type: 'CANCEL_IMAGE_UPLOAD'})
export const resetUploader = () => ({type: 'RESET_IMAGE_UPLOAD'})

export const setUploaderWorkflowStage = stage => ({type: 'SET_UPLOAD_WORKFLOW_STAGE', stage})

export const addAvailableWorkflowStages = availableStages => ({
  type: 'ADD_AVAILABLE_WORKFLOW_STAGES',
  availableStages,
})

export const setAvailableWorkflowStages = availableWorkflowStages => ({
  type: 'SET_AVAILABLE_WORKFLOW_STAGES',
  availableWorkflowStages,
})

export const setUploadImage = assetFile => ({
  type: 'SET_ASSET_FILE',
  assetFile,
})

export const setItemMetadata = formValues => ({type: 'UPDATE_ITEM_METADATA_VALUES', formValues})

export const freezeAvailableWorkflowStages = () => ({type: 'FREEZE_AVAILABLE_WORKFLOW_STAGES'})

export const setProject = project => ({type: 'SET_SELECTED_PROJECT', project})
export const setCollection = collection => ({type: 'SET_SELECTED_COLLECTION', collection})

export const setUploadMetadataSchema = metadataSchema => ({
  type: 'SET_UPLOAD_SCHEMA',
  metadataSchema,
})

export const indicateMetadataValidationResults = metadataValidationResults => ({
  type: 'INDICATE_METADATA_VALIDATION_RESULTS',
  metadataValidationResults,
})

export const updateMetadataForm = metadataContent => ({
  type: 'UPDATE_ITEM_METADATA_VALUES',
  metadataContent,
})

export const indicateWorkflowSuccess = ({id: imageId, project: imageProject}) => ({
  type: 'INDICATE_UPLOAD_SUCCESS',
  imageId,
  imageProject,
})

export const indicateWorkflowFailure = errorMessage => ({
  type: 'INDICATE_UPLOAD_FAILURE',
  errorMessage,
})

export const setMissionPackageDiscoveryConfirmDialog = dialogState => ({
  type: 'SET_MISSION_PACKAGE_DISCOVERY_CONFIRM_DIALOG',
  dialogState,
})

export const setMissionPackageDiscoveryMode = () => ({
  type: 'SET_MISSION_PACKAGE_DISCOVERY_MODE',
})

export const setOrthoMosaicValidating = validating => ({
  type: 'SET_ORTHO_MOSAIC_VALIDATING',
  validating,
})

export const setExperimentalValidating = validating => ({
  type: 'SET_EXPERIMENTAL_VALIDATING',
  validating,
})
