import React from 'react'
import PropTypes from 'prop-types'
import {Breadcrumbs, Link, Typography} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import styled from 'styled-components'

import {useSelector, useDispatch} from 'react-redux'

import uploadWorkflows from '../utilities/uploadWorkflows'
import {setUploaderWorkflowStage} from '../actions/imageUploaderAction'

const BreadcrumbSection = styled.section`
  padding: 5px 24px;
  user-select: none;

  .MuiTypography-colorTextSecondary {
    color: var(--mdc-theme-text-disabled-on-light);
  }
`

function ImagineUploaderStageBreadcrumbs(props) {
  const {workflow} = props
  const dispatch = useDispatch()
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)
  const availableWorkflowStages = useSelector(state => state.imageUploader.availableWorkflowStages)

  const setWorkflowStage = stage => dispatch(setUploaderWorkflowStage(stage))

  const breadcrumbs = uploadWorkflows[workflow].stages
    .map(({title, hideBreadcrumb = false}) => (hideBreadcrumb ? null : title))
    .filter(i => i)

  return (
    <BreadcrumbSection>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs.map((title, index) =>
          availableWorkflowStages.indexOf(index) !== -1 ? (
            <Link
              color="inherit"
              href="#"
              onClick={() => setWorkflowStage(index)}
              key={`breadcrumb-top-${index}`}
            >
              <Typography color={index === workflowStage ? 'primary' : 'textPrimary'}>
                {title}
              </Typography>
            </Link>
          ) : (
            <Typography color="textSecondary" key={`breadcrumb-top-${index}-inactive`}>
              {title}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </BreadcrumbSection>
  )
}

ImagineUploaderStageBreadcrumbs.propTypes = {
  workflow: PropTypes.number.isRequired,
}

export default ImagineUploaderStageBreadcrumbs
