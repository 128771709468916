import {Button, FormControl, TextField, Typography} from '@mui/material'
import {Formik, FormikErrors} from 'formik'
import React from 'react'
import {red} from './UploaderImagineMetadataFieldText'

export type HyperspectralMetadataEditorValues = {geohash: string}

export function HyperspectralMetadataEditor({
  onSubmit,
}: {
  onSubmit: (values: HyperspectralMetadataEditorValues) => void
}) {
  const initialValues = {geohash: ''}

  function validate(
    values: HyperspectralMetadataEditorValues
  ): FormikErrors<HyperspectralMetadataEditorValues> {
    let errors: FormikErrors<HyperspectralMetadataEditorValues> = {}
    if (!values.geohash) {
      errors.geohash = 'Geohash is required.'
    }
    return errors
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, handleChange, handleBlur, isSubmitting, values, errors, touched}) => (
        <form
          onSubmit={handleSubmit}
          style={{display: 'flex', flexDirection: 'column', gap: '10px'}}
        >
          <FormControl style={{width: '200px'}}>
            <TextField
              label="Geohash"
              name="geohash"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.geohash}
              required
            />
            {errors.geohash && touched.geohash && (
              <Typography style={{color: red}}>{errors.geohash}</Typography>
            )}
          </FormControl>
          <Button style={{width: '200px'}} type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </form>
      )}
    </Formik>
  )
}
