import {useEffect, useState} from 'react'

/** Generates a list of visible collections for a list of items */
function useListVisibleCollections(items) {
  const [visibleCollections, setVisibleCollections] = useState([])

  useEffect(() => {
    async function retrieveCollections() {
      const collections = []
      for (const item of items) {
        const collection = (await item.collection()).id
        collections.push(collection)
      }

      setVisibleCollections([...new Set(collections)])
    }

    retrieveCollections()
  }, [items])

  return visibleCollections
}

export default useListVisibleCollections
