import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  NotInterested as NoEntitlementIcon,
  CheckCircleOutline as EntitlementIcon,
} from '@mui/icons-material'
import {Typography} from '@mui/material'

const StyledIconContainer = styled.div`
  margin-left: 5px;
  color: ${props =>
    props.$disabled === 'true'
      ? 'var(--mdc-theme-text-disabled-on-background) !important'
      : 'var(--mdc-theme-primary) !important'};
`

const EntitlementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .MuiTypography-body2 {
    margin-left: 5px;
    margin-bottom: 4px;
    color: var(--mdc-theme-text-disabled-on-background);
    margin-bottom: 6px;
  }

  .entitlement-elements {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    padding-right: 16px;
  }
`

const Entitlement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 10px;
    color: ${props =>
      props.$disabled === 'true'
        ? 'var(--mdc-theme-text-disabled-on-background)'
        : 'var(--mdc-theme-primary)'};
  }
`

const DirectoryCardContentProjectPermission = ({permitted, description}) => (
  <Entitlement $disabled={String(!permitted)}>
    <StyledIconContainer $disabled={String(!permitted)}>
      {permitted ? <EntitlementIcon /> : <NoEntitlementIcon />}
    </StyledIconContainer>
    <span>{description}</span>
  </Entitlement>
)

function DirectoryCardContentProject(props) {
  const {downloadPermission, publishPermission, deletePermission} = props

  return (
    <EntitlementsContainer>
      <Typography variant="body2">Project Entitlements:</Typography>
      <div className="entitlement-elements">
        <DirectoryCardContentProjectPermission
          permitted={publishPermission}
          description="publish"
        />
        <DirectoryCardContentProjectPermission
          permitted={downloadPermission}
          description="download"
        />
        <DirectoryCardContentProjectPermission permitted={deletePermission} description="delete" />
      </div>
    </EntitlementsContainer>
  )
}

DirectoryCardContentProject.propTypes = {
  deletePermission: PropTypes.bool.isRequired,
  downloadPermission: PropTypes.bool.isRequired,
  publishPermission: PropTypes.bool.isRequired,
}

export default DirectoryCardContentProject
