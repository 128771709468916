import React from 'react'
import PropTypes from 'prop-types'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

/**
 * A row for an array of scalar values
 */
function ItemMetadataListScalarArrayTableRow(props) {
  const {title, valuesArray} = props

  const headerRow = (
    <TableRow key={`metadata-table-array-${title}`}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="left" className="subdue">
        (list)
      </TableCell>
    </TableRow>
  )
  const scalarArrayRows = valuesArray.map((value, lowerIndex) => (
    <TableRow key={`metadata-table-array-item-${title}-${value}-${lowerIndex}`}>
      <TableCell scope="row" />
      <TableCell align="left">{value}</TableCell>
    </TableRow>
  ))

  return (
    <>
      {headerRow}
      {scalarArrayRows}
    </>
  )
}

ItemMetadataListScalarArrayTableRow.propTypes = {
  /**  The title of the row */
  title: PropTypes.string.isRequired,
  /** The array of scalar values */
  valuesArray: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
}

ItemMetadataListScalarArrayTableRow.defaultProps = {
  valuesArray: [],
}

export default ItemMetadataListScalarArrayTableRow
