import React, {createContext, useState} from 'react'

function ToastNotificationProvider({children}) {
  const [toastState, setToastState] = useState<ToastState>(initialContext.toastState)

  return <Provider value={{toastState, setToastState}}>{children}</Provider>
}

const initialContext: ToastContext = {
  toastState: {open: false, severity: 'info', timeout: null, message: ''},
  setToastState: () => console.warn('Toast Notification not initialized'),
}

const toastNotificationContext = createContext<ToastContext>(initialContext)
const {Provider} = toastNotificationContext

export type ToastState = {
  open: boolean
  severity: string
  timeout: number | null
  message: string
}

export type ToastContext = {
  toastState: ToastState
  setToastState: React.Dispatch<React.SetStateAction<ToastState>>
}

export {toastNotificationContext, ToastNotificationProvider}
