import {useContext, useEffect} from 'react'
import {
  advancedSearchContext,
} from '../context/search/advancedSearchContext'
import {
  SearchActionType,
  UpdateValuesValue
} from '../context/search/SearchAction'
import {
  Bbox, SearchKeyword,
  SearchParameters,
  SearchProperty
} from '../context/search/SearchParameters'
import {encodeAdvancedSearchQuery} from '../utilities/advancedSearchQueryString'

/**
 * Carries out all load logic, fetching and loading from advancedSearchContext.
 *
 */
function useAdvancedSearch() {
  const {
    searchAttributes,
    dispatch,
    queryStringState: [searchQueryString, setSearchQueryString],
    drawerState: [drawerOpen, setDrawerOpen],
    triggerSearch: [advancedSearchTrigger, setAdvancedSearchTrigger],
  } = useContext(advancedSearchContext)

  // Disable search trigger and update queryString on change of advanced search attributes
  useEffect(() => {
    setAdvancedSearchTrigger(false)
    const queryString = encodeAdvancedSearchQuery(searchAttributes)
    setSearchQueryString(queryString)
  }, [searchAttributes])

  const triggerAdvancedSearch = () => setAdvancedSearchTrigger(true)

  const resetAdvancedSearch = () => dispatch({type: SearchActionType.RESET})
  const updateMultipleValues = (values: UpdateValuesValue) =>
    dispatch({type: SearchActionType.UPDATE_VALUES, values: values})

  const initializeValues = (elements: SearchParameters) => {
    resetAdvancedSearch()
    updateMultipleValues(elements)
  }

  // Setters created for ease of maintainability
  const setBounds = (value: Bbox) => updateMultipleValues({bbox: value})
  const setCollections = (value: string[]) => updateMultipleValues({collections: value})
  const setCollectionProperties = (value: SearchProperty[]) =>
    updateMultipleValues({properties: value})
  const setDateRange = ({fromDate, toDate}: {fromDate: string; toDate: string}) =>
    updateMultipleValues({fromDate, toDate})
  const setKeywords = (value: SearchKeyword[]) => updateMultipleValues({keywords: value})
  const setProjects = (value: string[]) => updateMultipleValues({projects: value})
  const setTags = (value: string[]) => updateMultipleValues({tags: value})
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  return {
    advancedSearchTrigger,
    drawerOpen,
    searchAttributes,
    searchQueryString,
    toggleDrawer,
    triggerAdvancedSearch,
    updateValue: updateMultipleValues,

    // Search Attribute Setters
    initializeValues,
    resetAdvancedSearch,
    setCollections,
    setCollectionProperties,
    setProjects,
    setBounds,
    setDateRange,
    setKeywords,
    setTags,
  }
}

export default useAdvancedSearch
