import React, {useContext, useEffect, useState} from 'react'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import {TextField} from '@mui/material'
import styled from 'styled-components'

import {imagineServiceContext} from '../context/imagineServiceContext'
import useAdvancedSearch from '../hooks/useAdvancedSearch'
import SearchSelectedAttribute from './SearchSelectedAttribute'
import {handleSentryError} from '../utilities/sentryErrors'
import useToastNotification from '../hooks/useToastNotification'

const CollectionsSearchElement = styled.div`
  .MuiAutocomplete-root {
    margin-bottom: 5px;
  }

  .MuiChip-root {
    margin: 2px;
  }

  .selected-projects-list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  label {
    padding-left: 5px;
  }

  .MuiAutocomplete-endAdornment {
    margin-right: 10px;
  }
`

const ADVANCED_SEARCH_SELECTOR_COLLECTION_IDS = 'ADVANCED_SEARCH_SELECTOR_COLLECTION_IDS'

function AdvancedSearchSelectorCollections() {
  const [collectionList, setCollectionList] = useState([''])
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const {imagineSdk} = useContext(imagineServiceContext)
  const [loading, setLoading] = useState(false)
  const [toggleAutocompleteRender, setToggleAutocompleteRender] = useState(false)
  const {errorNotification} = useToastNotification()

  const {
    searchAttributes: {collections: selectedCollections},
    setCollections,
  } = useAdvancedSearch()

  useEffect(() => {
    async function loadCollections() {
      try {
        const cachedData = window.localStorage.getItem(ADVANCED_SEARCH_SELECTOR_COLLECTION_IDS)
        if (cachedData) {
          setCollectionList(JSON.parse(cachedData))
        }
      } catch (err) {
        handleSentryError(err, 'Error loading advanced selector project IDs from local storage')
      }

      const collectionListing = imagineSdk.fetchCollections()
      const collections = []
      for await (const collection of collectionListing) {
        collections.push(collection.id)
      }
      collections.sort()
      const allCollections = ['', ...collections]
      window.localStorage.setItem(
        ADVANCED_SEARCH_SELECTOR_COLLECTION_IDS,
        JSON.stringify(allCollections)
      )
      setCollectionList(allCollections)
    }
    setLoading(true)
    if (imagineSdk)
      loadCollections()
        .catch(e => {
          handleSentryError(e, 'Collection autocomplete failed to load.')
          errorNotification('Collection autocomplete failed to load.')
        })
        .finally(() => setLoading(false))
  }, [imagineSdk])

  function onChange(
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ): void {
    if (reason === 'selectOption') {
      setCollections([...selectedCollections, value])
      setAutocompleteValue('')
      setToggleAutocompleteRender(!toggleAutocompleteRender)
    } else {
      setAutocompleteValue(value)
    }
  }

  function removeCollection(collection) {
    const newArray = [...selectedCollections]
    newArray.splice(newArray.indexOf(collection), 1)
    setCollections([...newArray])
  }

  return (
    <CollectionsSearchElement>
      <Autocomplete
        key={toggleAutocompleteRender.toString()}
        loading={loading}
        options={collectionList}
        getOptionDisabled={option => selectedCollections.indexOf(option) !== -1}
        value={autocompleteValue}
        renderInput={params => <TextField {...params} label="Collections" variant="standard" />}
        defaultValue=""
        onChange={onChange}
        autoHighlight
        disableClearable
      />

      <div>
        {selectedCollections.map(collection => (
          <SearchSelectedAttribute
            key={`search-collection-chip-${collection}`}
            property={collection}
            onDelete={() => removeCollection(collection)}
          />
        ))}
      </div>
    </CollectionsSearchElement>
  )
}

export default AdvancedSearchSelectorCollections
