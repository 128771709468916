import React from 'react'
import PropTypes from 'prop-types'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  .wide-cell {
    width: 600px;
  }

  .MuiTableRow-root {
    transition: background 0.35s ease-in-out;
    cursor: pointer;

    user-select: none;
  }

  .MuiTableRow-root:hover {
    background: #ebe7e7;
  }
`

/** Repetitive basic dialog content */
function GalleryPageDialog(props) {
  const {onClose, open, children, title} = props

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <StyledDialogContent dividers>{children}</StyledDialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GalleryPageDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default GalleryPageDialog
