import React, {useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material'
const {detect} = require('detect-browser')
function DialogUnsupportedBrowser() {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  useEffect(() => {
    const acceptedBrowserNames = ['chrome', 'edge', 'firefox']
    const {name: browserName} = detect()

    if (acceptedBrowserNames.indexOf(browserName) === -1) setOpen(true)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Unsupported Browser</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          It appears that you are using an unsupported browser for the Imagine UI. At this time, the
          Imagine UI only supports Chrome, Edge and Firefox.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogUnsupportedBrowser
