import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {css} from '@emotion/react'
import PropTypes from 'prop-types'
import {
  Chip,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControl,
  Input,
  FormHelperText,
} from '@mui/material'
import {Add} from '@mui/icons-material'

// Exported for testing
export const orange = '#FFA500'
export const red = '#b00020' // current value for var(--mdc-theme-error)

const acquireValidationColor = warning => (warning ? orange : red)

const ListForm = styled('div')`
  display: flex !important;
  flex-direction: column;

  justify-self: auto;

  width: 95ch;
  background-color: white;
  padding: 2px 0;

  min-height: 50px;

  .list-display {
    padding-left: 5px;
    height: 100%;
    margin-top: 10px;
    padding: 5px;
  }

  .MuiChip-root {
    margin: 2px;
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: ${props => props.$levelColor} !important;
  }
`

const HelperTextSpan = styled.span`
  color: ${props => {
    if (props.level === 'error') {
      return red
    } else if (props.level === 'warning') {
      return orange
    } else {
      return 'rgba(0, 0, 0, 0.87)'
    }
  }};
`

/*
  A list element that allows users to add or remove lists of items.
  Currently works only with strings.

*/
function UploaderImagineMetadataFieldArray(props) {
  const {fieldName, value, errorMessage, onChange, warning} = props
  const [tags, setTags] = useState([])
  const [newTag, setNewTag] = useState('')
  const color = acquireValidationColor(warning)

  const root = css`
    & .Mui-error {
      color: ${color};
    }
    & .MuiFormHelperText-root {
      color: ${color};
    }
  `

  // Set the list of items to the initial value
  useEffect(() => {
    if (value && Array.isArray(value)) setTags(value)
  }, [])

  // Update the master value of the tags on creation or deletion of a tag
  useEffect(() => {
    onChange(tags)
  }, [tags])

  const addListItem = () => {
    if (!newTag) return
    setTags([...new Set([...tags, newTag])])
    setNewTag('')
  }

  const deleteListItem = index => () => {
    setTags(tags.filter((_i, itemIndex) => itemIndex !== index))
  }

  const errorIndication = !!errorMessage || (newTag && tags.indexOf(newTag) !== -1)

  let helperTextErrorLevel = 'ok'
  if (!!errorMessage && warning) {
    helperTextErrorLevel = 'warning'
  } else if (errorMessage) {
    helperTextErrorLevel = 'error'
  }

  return (
    <ListForm
      className="two-rows"
      $levelColor={acquireValidationColor(helperTextErrorLevel === 'warning')}
    >
      <FormControl className="list-input">
        <InputLabel htmlFor="outlined-adornment-amount" variant="standard">
          {fieldName}
        </InputLabel>

        <Input
          id="filled-adornment-weight"
          value={newTag}
          variant="standard"
          onChange={e => setNewTag(e.target.value)}
          onKeyUp={e => {
            if (e.key === 'Enter') addListItem()
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="Add item" onClick={addListItem} edge="end">
                <Add />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
          error={!!errorIndication}
        />
        <FormHelperText id="filled-weight-helper-text" variant="standard" css={root}>
          <HelperTextSpan level={helperTextErrorLevel}>
            {errorIndication || 'Press enter to add a new entry'}
          </HelperTextSpan>
        </FormHelperText>
      </FormControl>
      <div className="list-display">
        {tags.map((tag, index) => (
          <Chip
            key={`tags-${fieldName}-${tag}`}
            color="primary"
            label={tag}
            onDelete={deleteListItem(index)}
            variant="outlined"
          />
        ))}
      </div>
    </ListForm>
  )
}

UploaderImagineMetadataFieldArray.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  warning: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

UploaderImagineMetadataFieldArray.defaultProps = {
  errorMessage: '',
}

export default UploaderImagineMetadataFieldArray
