import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  DialogContent,
  Typography,
} from '@mui/material'

import workflows from '../utilities/uploadWorkflows'
import {isProd} from '../utilities/serviceBindings'

import DialogContentContainer from './styled/DialogContentContainer'
import useCurrentUserGroups from '../hooks/useCurrentUserGroups'

const CardSelectionArea = styled.section`
  /* Covers width of card area */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

const StyledCard = styled(Card)`
  height: 327px;
  width: 275px;
  cursor: pointer;
  margin: 12px;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;

      .MuiButtonBase-root {
        cursor: not-allowed !important;
      }
      .MuiTypography-root {
        color: #999;
      }
    `}
`

const StyledCardMedia = styled(CardMedia)`
  height: 195px;
`

/**
 * Card pane used to select which workflow
 */
function UploaderDialogWorkflowSelector(props) {
  const {setWorkflow} = props

  const [, ...otherWorkflows] = workflows

  const {loading, error, currentUserGroups: currentGroups} = useCurrentUserGroups()
  const [currentUserGroups, setCurrentUserGroups] = useState([])

  useEffect(() => {
    if (!loading && !error) {
      setCurrentUserGroups(currentGroups.map(g => g.id) ?? [])
    }
  }, [currentGroups, loading, error])

  if (error) return <Typography color="error">Error loading groups</Typography>

  if (loading) return <h1>Loading</h1>

  const WorkflowSelectionCards = () =>
    otherWorkflows.map((workflow, index) => {
      const {
        title,
        image,
        description,
        disabledDescription,
        visible = () => true,
        enabled = () => true,
      } = workflow

      const isVisible = visible(isProd, currentUserGroups)

      if (!isVisible) return null

      const isEnabled = enabled(isProd, currentUserGroups)

      return (
        <StyledCard
          key={`workflow-card-${title}`}
          onClick={() => {
            if (isEnabled) setWorkflow(index + 1)
          }}
          disabled={!isEnabled}
        >
          <CardActionArea>
            <StyledCardMedia
              image={image}
              title={title}
              style={{filter: `grayscale(${isEnabled ? 0 : 1})`}}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <Typography variant="body2">
                {isEnabled ? description : disabledDescription}
              </Typography>
            </CardContent>
          </CardActionArea>
        </StyledCard>
      )
    })

  return (
    <DialogContentContainer>
      <DialogContent>
        <CardSelectionArea>
          <WorkflowSelectionCards />
        </CardSelectionArea>
      </DialogContent>
    </DialogContentContainer>
  )
}

UploaderDialogWorkflowSelector.propTypes = {
  setWorkflow: PropTypes.func.isRequired,
}

export default UploaderDialogWorkflowSelector
