import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import {Map, Rectangle} from 'react-leaflet'

import styled from 'styled-components'

import BingBasemap from './BingBasemap'
import {bingLoaded} from '../utilities/bingMapService'

const ExtentSelectionElement = styled.div`
  #extentMap {
    height: ${props => props.height};
    width: ${props => props.width};
  }

  #extentMap,
  .leaflet-interactive {
    cursor: default !important;
  }
`

/**
 * Static map. Displays an optional rectangle
 *
 * Changes location and zoom based on size of rectangular bbox.
 *
 * */
function MapStaticDisplay(props) {
  const [bingReady, setBingReady] = useState(false)
  const [mapDisplay, setMapDisplay] = useState({
    mapCenter: [0, 0],
    mapZoom: 2,
    viewMapBoundingBox: false,
    rectangleBounds: null,
  })
  const {height, width, bbox} = props

  const {mapCenter, mapZoom, rectangleBounds} = mapDisplay

  const extentRef = useRef()
  const rectangleRef = useRef()

  useEffect(() => {
    bingLoaded.then(() => setBingReady(true))
  }, [])

  useEffect(() => {
    if (!bbox) {
      setMapDisplay({
        mapCenter: [0, 0],
        mapZoom: 2,
        rectangleBounds: null,
      })
    } else {
      if (bbox && bbox.xMin === 0 && bbox.xMax === 0 && bbox.yMin === 0 && bbox.yMax === 0) {
        throw new Error('The "bbox" must not be null island')
      }
      if (bbox && !(bbox.yMin && bbox.yMax && bbox.xMin && bbox.xMax)) {
        throw new Error('The "bbox" object prop must include a xMin, xMax, yMin, yMax')
      }

      const {yMin, yMax, xMin, xMax} = bbox

      setMapDisplay({
        mapCenter: [(yMin + yMax) / 2, (xMin + xMax) / 2],
        rectangleBounds: [
          [yMin, xMin],
          [yMax, xMax],
        ],
      })

      extentRef.current.leafletElement.fitBounds(
        [
          [yMin, xMin],
          [yMax, xMax],
        ],
        {padding: [25, 25]}
      )
    }
  }, [bbox])

  const Content = () =>
    rectangleBounds ? (
      <Rectangle bounds={rectangleBounds} color="#152457" weight={1} ref={rectangleRef} />
    ) : (
      <></>
    )

  if (!bingReady) {
    return <ExtentSelectionElement height={height} width={width} />
  }

  return (
    <ExtentSelectionElement height={height} width={width}>
      <Map
        id="extentMap"
        ref={extentRef}
        center={mapCenter}
        zoom={mapZoom}
        zoomControl={false}
        closePopupOnClick={false}
        dragging={false}
        zoomSnap={false}
        zoomDelta={false}
        trackResize={false}
        scrollWheelZoom={false}
        useFlyTo
      >
        <BingBasemap />
        <Content />
      </Map>
    </ExtentSelectionElement>
  )
}

MapStaticDisplay.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  bbox: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
  }),
}

MapStaticDisplay.defaultProps = {
  height: '220px',
  width: '250px',
  bbox: null,
}

export default MapStaticDisplay
