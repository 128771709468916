import React, {useContext, useEffect, useState} from 'react'
import {parse} from 'query-string'
import {useLocation} from 'react-router-dom'
import {handleSentryError} from '../utilities/sentryErrors'

import ItemPageLayout from '../layouts/ItemPageLayout'
import ItemPageLayoutLoading from '../layouts/ItemPageLayoutLoading'
import CommonLayout from '../layouts/CommonLayout'

import {itemContext} from '../context/itemContext'
import {AlbumListProvider} from '../context/albumListContext'
import FullscreenError from '../components/FullscreenError'

import useUpdatePageVisitCount from '../hooks/useUpdatePageVisitCount'

import {imagineServiceContext} from '../context/imagineServiceContext'
import {bingLoaded} from '../utilities/bingMapService'
import {imageViewerLoaded} from '../utilities/imageViewerService'
import {UploaderProvider} from '../context/uploaderContext'
import getItemThumbnailUrl from '../utilities/getItemThumbnailUrl'

const defaultItemAttributes = {
  albums: [],
  assets: null,
  bbox: null,
  collection: null,
  dataAsset: null,
  geometry: null,
  id: null,
  extensions: [],
  // Item delete function gets initialized later on.
  itemDeleteFn: async () => console.error('Item delete function not initialized'),
  metadata: {},
  permissions: {download: null, delete: null, publish: null},
  project: null,
  schema: {},
  thumbnail: null,
}

/*
 * Loads image data for the preview page, and either displays a loading state, an error state, or
 * a metadata viewer state.

*/
function ItemPage() {
  const {search} = useLocation()
  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)
  const {setItemMetadata} = useContext(itemContext)

  const [loading, setLoading] = useState(true)
  const [criticalError, setCriticalError] = useState(null)
  const [error, setError] = useState(null)

  const [itemAttributes, setItemAttributes] = useState(defaultItemAttributes)

  const {id} = parse(search)

  // Keeps track on quantity a certain item page has been visited using velocity profile client
  useUpdatePageVisitCount({
    topic: 'itemVisitCount',
    pageIdentifier: id,
    valid: !loading && criticalError === null,
  })

  // Remove all attributes on dismount
  useEffect(
    () => () => {
      setItemAttributes(defaultItemAttributes)
    },
    []
  )

  // Adds item attributes to the context
  useEffect(() => {
    setItemMetadata({...itemAttributes, error})
  }, [itemAttributes, error])

  // Provides an error message in case of a global warning error
  useEffect(() => {
    if (globalLoadingError) {
      if (/Unauthorized/.test(globalLoadingError)) {
        setCriticalError(
          'Unauthorized error. Please try refreshing and clearing cookies. If this persists please contact Location360 Support'
        )
      } else {
        setCriticalError(globalLoadingError)
      }
    }
  }, [globalLoadingError])

  useEffect(() => {
    if (!id) {
      setError('No item ID was specified.')
      return
    }

    const loadItem = async () => {
      // Wait for dependencies to initialize.
      await bingLoaded
      await imageViewerLoaded

      const item = await imagineSdk.fetchItem({
        id,
        cacheControl: 'no-cache',
      })

      if (!item) {
        setCriticalError('No item exists for the specified ID.')
        setLoading(false)

        setItemAttributes({
          ...itemAttributes,
          permissions: {download: false, delete: false, publish: false},
        })
        return
      }

      const {geometry, bbox, properties: metadata} = item

      const userEntitlements = []
      try {
        const entitlements = await item.entitlements()
        if (entitlements.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
        if (entitlements.currentUserHasEntitlement('DELETE')) userEntitlements.push('DELETE')
        if (entitlements.currentUserHasEntitlement('PUBLISH')) userEntitlements.push('PUBLISH')
        if (entitlements.currentUserHasEntitlement('DOWNLOAD')) userEntitlements.push('DOWNLOAD')
      } catch (e) {
        const additionalErrorDetails = 'Unable to load item entitlements.'
        handleSentryError(e, additionalErrorDetails)
      }

      let albums = []
      // TODO: Refactor in future. Will be implemented in a future SDK version
      // try {
      //   albums = [...(await item.albums())]
      // } catch (e) {
      //   const additionalErrorDetails = 'Unable to load item albums.'
      //   handleSentryError(e, additionalErrorDetails)
      // }

      let assets
      try {
        // if (item && item.assets && typeof item.assets === 'function') {
        //   assets = await item.assets()
        // }
        if (item) {
          assets = await item.fetchAssets()
        }
      } catch (e) {
        const additionalErrorDetails = 'Unable to load item assets due to a server error.'
        handleSentryError(e, additionalErrorDetails)
        setError('Unable to load assets due to a server error. Please contact Location360 support.')
      }

      const [projectElement, collectionElement] = await Promise.all([
        item.project(),
        item.collection(),
      ])

      const thumbnailUrl = await getItemThumbnailUrl(item)

      const project = projectElement?.id
      const collection = collectionElement?.id

      // Create a new instance of a schema object so as not to pollute the schema that lives in the cache
      // TODO: item.schema() will be implemented in a future SDK version. For now assign as empty object
      const collectionSchema = {}
      // const schema = await collectionElement.schema()
      // const collectionSchema = {...schema}

      const extensions = []
      try {
        if (item) {
          const stacExtensions = await item.stacExtensions()
          for (const {id} of stacExtensions) {
            extensions.push(id)
          }
        }
      } catch (e) {
        const additionalErrorDetails = 'Unable to load item STAC extensions due to a server error.'
        handleSentryError(e, additionalErrorDetails)
        setError(
          'Unable to load STAC extensions due to a server error. Please contact Location360 support.'
        )
      }

      const assetsWithThumbnail = []
      if (assets) {
        assets.forEach(async asset => {
          if (asset.roles?.includes('thumbnail')) {
            assetsWithThumbnail.push(asset)
          }
          metadata[`asset:${asset.id || 'unknown'}`] = {
            href: asset.href,
            name: asset.id,
            size: asset.size,
            roles:
              asset.roles && Array.isArray(asset.roles)
                ? asset.roles.map(i => `"${i}"`).join(', ')
                : asset.roles,
            title: asset.title,
            type: asset.mediaType,
          }
        })
      }

      if (!userEntitlements?.includes('DOWNLOAD')) {
        console.warn('No download permission present')
        if (projectElement) {
          const {id, title} = projectElement
          setError(
            `You do not have permission to view or download items in the "${id || title}" project`
          )
        } else {
          setError("You do not have permission to view or download assets in this item's project")
        }
      }

      let dataAsset
      for (const asset of assets) {
        if (asset.roles.includes('data')) {
          dataAsset = asset
          break
        }
      }

      setItemAttributes({
        ...itemAttributes,
        albums,
        project,
        collection,
        geometry,
        bbox,
        assets,
        metadata,
        extensions,
        thumbnail: thumbnailUrl,
        dataAsset,
        permissions: {
          publish: userEntitlements?.includes('PUBLISH'),
          download: userEntitlements?.includes('DOWNLOAD'),
          delete: userEntitlements?.includes('DELETE'),
        },
        schema: collectionSchema,
        item,
        id,
        assetsWithThumbnail,
      })

      setLoading(false)
    }

    if (!(globalLoading || globalLoadingError)) loadItem()
  }, [globalLoading, globalLoadingError])

  if (criticalError) return <FullscreenError message={criticalError} />

  if (loading) {
    return (
      <CommonLayout>
        <ItemPageLayoutLoading />
      </CommonLayout>
    )
  }

  return (
    <UploaderProvider>
      <CommonLayout>
        <AlbumListProvider>
          <ItemPageLayout />
        </AlbumListProvider>
      </CommonLayout>
    </UploaderProvider>
  )
}

export default ItemPage
