import React, {useContext, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import {CircularProgress, Typography} from '@mui/material'

import {
  setAvailableWorkflowStages,
  setUploaderWorkflowStage,
  indicateWorkflowSuccess,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'

import {imagineServiceContext} from '../context/imagineServiceContext'
import {handleSentryError} from '../utilities/sentryErrors'

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;

  .MuiTypography-root {
    margin-top: 35px;
  }
`

// Regex based off of "Throws" in the imagine-velocity-sdk's Collection.ts file
const insufficientEntitlementRe = /The PUBLISH entitlement is required/
const missingMetadataRe = /Some required metadata properties are missing or invalid/
const serverErrorRe = /Added Item could not be retrieved from the server/
const genericErrorRe = /Item addition in Project .* failed - (.*)$/
const duplicateAssetChecksumRe = /.*Checksum .* already exists on asset.*/

/*
  A loading component which invokes the SDK's loading on load.

  Also verifies for completeness of data, and returns to a prior stage
  if found lacking.

  Displays a loading spinner and nothing else.

*/
function UploaderImagineStageUploading() {
  const project = useSelector(state => state.imageUploader.selectedProject)
  const collection = useSelector(state => state.imageUploader.selectedCollection)
  const file = useSelector(state => state.imageUploader.itemAssetFile)
  const metadata = useSelector(state => state.imageUploader.metadataContent)

  const {imagineSdk} = useContext(imagineServiceContext)

  const dispatch = useDispatch()

  const setStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const setWorkflowStages = stages => dispatch(setAvailableWorkflowStages(stages))
  const setResultingId = args => dispatch(indicateWorkflowSuccess(args))
  const setDialogError = message => dispatch(indicateWorkflowFailure(message))

  const freezeStages = () => setWorkflowStages([3])
  const progressToNextStage = () => {
    setWorkflowStages([4])
    setStage(4)
  }

  // Lock available stages to current pane on load
  useEffect(() => {
    freezeStages()
  }, [])

  // Uploads on load of component
  useEffect(() => {
    const uploadImage = async () => {
      const selectedProject = await imagineSdk.fetchProject({id: project}).catch(e => {
        const additionalErrorDetails = `Unable to retrieve project ${project}.`
        handleSentryError(e, additionalErrorDetails)
      })
      const selectedCollection = await imagineSdk.fetchCollection({id: collection}).catch(e => {
        const additionalErrorDetails = `Unable to retrieve collection ${collection}.`
        handleSentryError(e, additionalErrorDetails)
      })

      // Prevent transmission of empty arrays in properties
      const sanitizedProperties = {}

      Object.entries(metadata.properties).forEach(([key, value]) => {
        if (!(Array.isArray(value) && value.length === 0)) {
          sanitizedProperties[key] = value
        }
      })

      selectedCollection
        .createItem({
          project: selectedProject,
          properties: sanitizedProperties,
          createAssets: [{file, id: 'image', roles: ['data']}],
        })
        .then(result => {
          setResultingId({id: result.id, project})
        })
        .catch(e => {
          if (insufficientEntitlementRe.test(e.message)) {
            setDialogError(
              `You do not have entitlement to publish to ${project}. Please contact Location360 Support if you believe this to be in error.`
            )
          } else if (missingMetadataRe.test(e.message)) {
            /*
               ! If a user has made it to this point, it means that something in the AssetMetadataForm validation has failed.
               ! This would require further intervention at the development level.
            */
            setDialogError(
              'The upload is missing required metadata. Please contact Location360 Support for assistance.'
            )
          } else if (serverErrorRe.test(e.message)) {
            // Something bad has happened at the server.
            setDialogError(
              'A server error has occured. Please contact Location360 Support for assistance if this continues to occur.'
            )
          } else if (duplicateAssetChecksumRe.test(e.message)) {
            setDialogError(e.message)
          } else if (genericErrorRe.test(e.message)) {
            setDialogError(e.message)
          } else {
            console.error(e)
            setDialogError('An unknown error has taken place. Please contact Location360 Support.')
          }
        })
        .finally(progressToNextStage)
    }

    if (!collection || !project || !metadata || !file) {
      // Remove all workflow stages
      // Start again
      // Add toast message to that effect
      setDialogError('An error in the uploader UI has occured. Please contact Location360 Support.')
      progressToNextStage()
    } else uploadImage()
  }, [])

  return (
    <Container>
      <CircularProgress size={120} />
      <Typography variant="body1" align="center">
        Uploading item to the Imagine Service.
      </Typography>
    </Container>
  )
}

export default UploaderImagineStageUploading
