import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import styled, {css} from 'styled-components'

import {albumExportContext} from '../context/albumExportContext'

const StyledTableCell = styled(TableCell)`
  ${props =>
    props.excluded === 'true' &&
    css`
      text-decoration: line-through;
    `}
`

function DialogExportAlbumRoleExclusion(props) {
  const {possibleRoles} = props

  const {
    excludeRoleState: [excludedRoles, setExcludedRoles],
  } = useContext(albumExportContext)

  function toggleRole(role) {
    const index = excludedRoles.indexOf(role)
    if (index === -1) {
      setExcludedRoles([...excludedRoles, role])
    } else {
      const newExcludedRoles = [...excludedRoles]
      newExcludedRoles.splice(index, 1)
      setExcludedRoles(newExcludedRoles)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell align="right">Excluded</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {possibleRoles.sort().map((role, index) => (
            <TableRow key={`${role}-${index}`} onClick={() => toggleRole(role)}>
              <StyledTableCell excluded={String(excludedRoles.indexOf(role) !== -1)}>
                <Button>{role}</Button>
              </StyledTableCell>
              <TableCell align="right">
                <Checkbox checked={excludedRoles.indexOf(role) !== -1} color="default" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DialogExportAlbumRoleExclusion.propTypes = {
  possibleRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DialogExportAlbumRoleExclusion
