import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'

function MultiSelectFormElement(props) {
  const {name, elementValue, setElementValue, title, elementBlurHandler, error, constraints} = props

  const allowedValues = constraints.values

  const handleChange = event => {
    const eventValue = event.target.value
    const values = (typeof eventValue === 'string' ? [eventValue] : eventValue) || []
    setElementValue(values)
    elementBlurHandler(values)
  }

  return (
    <FormControl error={!!error}>
      <InputLabel id="demo-multi-select-error-label">{title}</InputLabel>
      <Select
        labelId="demo-multi-select-error-label"
        id="demo-multi-select-error"
        multiple
        name={name}
        value={elementValue}
        onChange={handleChange}
        renderValue={selected => (
          <Box>
            {selected.map(value => (
              <Chip key={value} label={allowedValues.find(i => i[1] === value)[0]} />
            ))}
          </Box>
        )}
      >
        {allowedValues.map(([label, value]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

function SelectFormElement(props) {
  const {name, elementValue, setElementValue, title, elementBlurHandler, error, constraints} = props

  const {values} = constraints

  const handleChange = event => {
    setElementValue(event.target.value)
    elementBlurHandler(event.target.value)
  }

  return (
    <FormControl error={!!error}>
      <InputLabel id="demo-simple-select-error-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-error-label"
        id="demo-simple-select-error"
        name={name}
        value={elementValue}
        onChange={handleChange}
        renderValue={value => {
          const index = values.map(i => i[1]).indexOf(value)
          return index === -1 ? '' : values[index][0]
        }}
      >
        {!elementValue && <MenuItem value={elementValue} />}
        {values.map(([label, value], index) => (
          <MenuItem key={`select-${name.split(' ').join('-')}-${index}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

function TextFormElement(props) {
  const {
    name,
    elementValue,
    sdkValue,
    setElementValue,
    title,
    elementBlurHandler,
    error,
    onKeyUpHandler,
  } = props

  // Fix faulty initial value if faulty
  if (elementValue === null || elementValue === undefined) setElementValue('')

  return (
    <TextField
      label={title}
      name={name}
      value={elementValue === '' && sdkValue !== '' ? sdkValue : elementValue}
      onChange={({target: {value: target}}) => {
        setElementValue(target)
      }}
      onKeyUp={() => {
        onKeyUpHandler(elementValue)
      }}
      onBlur={() => {
        elementBlurHandler(elementValue)
      }}
      InputLabelProps={{
        shrink: true,
      }}
      error={!!error}
      helperText={error}
    />
  )
}

const clamp = ({calculatedValue, min, max}) => {
  if (calculatedValue < min) {
    return min
  } else if (calculatedValue > max) {
    return max
  } else {
    return calculatedValue
  }
}

function NumberFormElement(props) {
  const {name, elementValue, setElementValue, title, elementBlurHandler, error, constraints} = props

  const setNumericalValue = ({event, oldValue}) => {
    const {
      target: {value: newValue},
    } = event

    if (newValue === '' || newValue === null) {
      setElementValue(newValue)
      return
    }

    if (Number.isNaN(newValue)) {
      setElementValue(oldValue)
      return
    }

    const {interval = 1, min = -10000000000, max = 1000000000} = constraints

    const diff = newValue - oldValue
    // Determines whether user inputed via step or keyboard entry
    if (Math.abs(diff) === 1) {
      const steppedValue = diff * interval + oldValue

      setElementValue(clamp({calculatedValue: steppedValue, min, max}))
    } else {
      setElementValue(newValue)
    }
  }

  const numericalFieldOnBlur = () => {
    const {interval = 1, min = -10000000000, max = 1000000000} = constraints
    if (elementValue === null) {
      setElementValue(clamp({calculatedValue: 0, min, max}))
    }

    let calculatedValue = Number(elementValue)

    const remainder = calculatedValue % interval

    if (remainder !== 0) {
      // Round the value to the closest interval

      if (remainder >= interval / 2) {
        calculatedValue += interval - remainder
      } else {
        calculatedValue -= remainder
      }
    }

    const clampedValue = clamp({calculatedValue, min, max})
    setElementValue(clampedValue)
    elementBlurHandler(clampedValue)
  }

  if (elementValue === null || elementValue === undefined) setElementValue(0)
  return (
    <TextField
      type="number"
      name={name}
      value={elementValue}
      label={title}
      onChange={event => {
        setNumericalValue({event, oldValue: elementValue})
      }}
      onBlur={() => {
        numericalFieldOnBlur()
      }}
      InputLabelProps={{
        shrink: true,
      }}
      error={!!error}
      helperText={error}
    />
  )
}

function BooleanFormElement(props) {
  const {name, value, elementValue, setElementValue, title, elementBlurHandler, error} = props

  const handleChange = event => {
    setElementValue(event.target.value === 'true')
    elementBlurHandler(event.target.value === 'true')
  }

  if (elementValue === null || elementValue === undefined) setElementValue(false)
  return (
    <FormControl
      error={
        !!error &&
        !(
          error === 'A selection must be made. Select "No" if you are unsure.' &&
          typeof value !== 'boolean'
        )
      }
    >
      <InputLabel id="mission-package-boolean-label">{title}</InputLabel>
      <Select
        labelId="mission-package-boolean-label"
        id="mission-package-boolean"
        name={name}
        value={String(elementValue)}
        onChange={handleChange}
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
      {error &&
        !(
          error === 'A selection must be made. Select "No" if you are unsure.' &&
          typeof value !== 'boolean'
        ) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

/*
 *
 * Runs both visualization of metadata form elements, as well as internal
 * update logic. Triggers revalidation on change.
 *
 */
function UploaderMissionPackageMetadataFormElement(props) {
  const {name, value, elementBlurHandler, onKeyUpHandler, elementInformation} = props

  const [elementValue, setElementValue] = useState(value)

  // Hide form element if not visible
  if (!elementInformation?.visible) return <></>

  const formElementProps = {
    elementValue,
    sdkValue: value,
    name,
    setElementValue,
    elementBlurHandler,
    onKeyUpHandler,
    ...elementInformation,
  }

  const formElement = {
    domain: <SelectFormElement {...formElementProps} />,
    'domain-multiple': <MultiSelectFormElement {...formElementProps} />,
    text: <TextFormElement {...formElementProps} />,
    number: <NumberFormElement {...formElementProps} />,
    boolean: <BooleanFormElement {...formElementProps} />,
  }

  return (
    <Tooltip
      sx={{fontSize: '110%', marginTop: '-20px'}}
      title={elementInformation.description || ''}
      placement="left"
    >
      <FormControl>{formElement[elementInformation.type]}</FormControl>
    </Tooltip>
  )
}

UploaderMissionPackageMetadataFormElement.propTypes = {
  elementInformation: PropTypes.shape({
    visible: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  elementBlurHandler: PropTypes.func.isRequired,
}

export default UploaderMissionPackageMetadataFormElement
