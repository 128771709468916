import {useEffect, useReducer, useState} from 'react'
import {getPreferencesValue, setPreferencesValue} from '../utilities/userPreferences'

const initialState = {
  schema: {},
  lastCollectionModified: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-initial-values':
      return {...state, schema: action.initialSchema}
    case 'reset':
      return initialState

    case 'set-visible-collection-schema':
      return {
        lastCollectionModified: action.collection,
        schema: {...state.schema, [action.collection]: action.value},
      }
    default:
      throw new Error()
  }
}

/** Manages and provides a list of visible Metadata */
function useManageVisibleMetadata(visibleCollections) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {schema, lastCollectionModified} = state

  const [loading, setLoading] = useState(true)

  // * Retrieves preferences for a given collection on initial load
  useEffect(() => {
    // Sets loading to true whenever other collections become visible in gallery
    // Forces a pause on updating the preferences via API
    setLoading(true)

    async function retrieveSchemaElements() {
      const initialSchema = {}

      visibleCollections.forEach(async collection => {
        initialSchema[collection] = (
          (await getPreferencesValue(`viewable-schema-elements-collection${collection}`)) ?? []
        ).filter(i => i)
      })

      dispatch({type: 'set-initial-values', initialSchema})
      setLoading(false)
    }

    retrieveSchemaElements()
  }, [visibleCollections])

  // * Saves preferences for future use on a given collection
  useEffect(() => {
    // Prevent changes until initial load complete
    if (loading || !lastCollectionModified) return

    setPreferencesValue(
      `viewable-schema-elements-collection${lastCollectionModified}`,
      schema[lastCollectionModified]
    )
  }, [schema, lastCollectionModified])

  /**
   * Removes item from visible schema
   * @param {string} collection collection to make schema element visible
   * @param {string} element element in the display one no longer wishes to see
   */
  function addSchemaElement(collection, element) {
    const updatedSchema = new Set(schema[collection])
    updatedSchema.add(element)

    dispatch({type: 'set-visible-collection-schema', collection, value: [...updatedSchema]})
  }

  /**
   * Removes item from visible schema
   * @param {string} collection collection to remove visibility from schema element
   * @param {string} element element in the display one no longer wishes to see
   */
  function removeSchemaElement(collection, element) {
    const updatedSchema = new Set(schema[collection])
    updatedSchema.delete(element)

    dispatch({type: 'set-visible-collection-schema', collection, value: [...updatedSchema]})
  }

  return {addSchemaElement, removeSchemaElement, visibleSchemaElements: schema}
}

export default useManageVisibleMetadata
