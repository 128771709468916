import React from 'react'
import PropTypes from 'prop-types'
import GallerySummarySearchResult from './GallerySummarySearchResult'

/**
 * A summary of results for the advanced search route
 *
 */
function GallerySummaryAdvancedSearch(props) {
  const {numberItems} = props
  const searchText = `The advanced search returned
          ${
            isFinite(numberItems)
              ? ` ${new Intl.NumberFormat('en-US').format(numberItems)}`
              : ' more than 10,000'
          }
          results`

  return <GallerySummarySearchResult searchText={searchText} />
}

GallerySummaryAdvancedSearch.propTypes = {
  /** The number of resulting items */
  numberItems: PropTypes.number.isRequired,
}

export default GallerySummaryAdvancedSearch
