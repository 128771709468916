import PropTypes from 'prop-types'
import React from 'react'
import styled, {css} from 'styled-components'

import fallBackImage from '../assets/fallback-image.png'
import fallBackImageSmall from '../assets/fallback-image-sm.png'

const ImageContainer = styled('div')`
  align-items: flex-start;
  background-color: #d0d0d0;
  display: flex;
  // height: 100%;
  justify-content: center;
  width: 100%;
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;

  img {
    width: 100%;
    // height: 100%;

    ${props =>
      props.useBorder &&
      css`
        border: 1px solid white;
      `}
  }

  img:before {
    content: ' ';
    display: block;
    height: 31%;
    width: 31%;
    filter: invert(0.54);
    background-size: cover;
    position: relative;
    margin-top: -31%;
    top: 63%;
    left: 32%;
  }
`

function FallbackImage({small, src, useBorder, width}) {
  const fallback = small ? fallBackImageSmall : fallBackImage

  return (
    <ImageContainer useBorder={useBorder} height={width}>
      <img
        src={src ?? fallback}
        alt=""
        onError={e => {
          e.target.onerror = null
          e.target.src = fallback
        }}
      />
    </ImageContainer>
  )
}

FallbackImage.displayName = 'FallbackImage'

FallbackImage.propTypes = {
  src: PropTypes.string,
  /** Display a border around the image */
  useBorder: PropTypes.bool,
  /** Uses a 80px sq image rather than a 230px sq image */
  small: PropTypes.bool,
}

FallbackImage.defaultProps = {
  src: '',
  useBorder: false,
  small: false,
}

export default FallbackImage
