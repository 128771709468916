import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Button} from '@mui/material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import AdvancedSearchSelectorExtentDrawDialog from './AdvancedSearchSelectorExtentDrawDialog'
import AdvancedSearchSelectorExtentNumberInput from './AdvancedSearchSelectorExtentNumberInput'
import MapStaticDisplay from './MapStaticDisplay'

const ExtentSelectionElement = styled.div`
  display: grid;
  grid-template-rows: 230px 50px;
  grid-template-columns: 1fr 1fr;

  .number-inputs {
    grid-row: 1 / 3;
    align-self: end;
  }

  .action-buttons {
    width: 320px;
    grid-column: 2 / 3;
    display: flex;
    justify-content: space-between;
  }
`

export default function AdvancedSearchSelectorExtent() {
  const [bbox, setBbox] = useState(null)

  const {
    setBounds,
    searchAttributes: {bbox: selectedBounds},
  } = useAdvancedSearch()

  // Sets initial bbox if exists in central state, unless it's set to the default of null island
  useEffect(() => {
    if (selectedBounds?.length !== 4) return
    const [xMin, yMin, xMax, yMax] = selectedBounds

    if (xMin === 0 && xMax === 0 && yMin === 0 && yMax === 0) return
    setBbox({xMin, yMin, xMax, yMax})
  }, [])

  // Sets bbox on change
  useEffect(() => {
    if (!bbox) setBounds(null)

    const differentBounds = (a, b) => JSON.stringify(a) !== JSON.stringify(b)

    if (bbox && differentBounds(selectedBounds, [bbox.xMin, bbox.yMin, bbox.xMax, bbox.yMax])) {
      setBounds([bbox.xMin, bbox.yMin, bbox.xMax, bbox.yMax])
    }
  }, [bbox])

  return (
    <ExtentSelectionElement>
      <AdvancedSearchSelectorExtentNumberInput className="number-input" bbox={bbox} />
      <MapStaticDisplay width="320px" height="220px" bbox={bbox} />
      <div className="action-buttons">
        <AdvancedSearchSelectorExtentDrawDialog bbox={bbox} setBbox={setBbox} />
        <Button color="inherit" variant="outlined" onClick={() => setBbox(null)}>
          Clear
        </Button>
      </div>
    </ExtentSelectionElement>
  )
}
