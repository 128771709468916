import {useEffect} from 'react'
import {getPreferencesValue, setPreferencesValue} from '../utilities/userPreferences'

function useUpdatePageVisitCount({topic, valid, pageIdentifier}) {
  useEffect(() => {
    async function updatePageVisitCount() {
      const topicVisitStats = (await getPreferencesValue(topic)) ?? {}
      let updatedVisitStats = topicVisitStats

      // Covers an old version of the setup where the histogram was the entire thing
      if (
        Object.keys(topicVisitStats).length !== 2 &&
        !Array.isArray(topicVisitStats.lastTenVisited) &&
        Object.keys(topicVisitStats).indexOf('visitsHistogram') === -1
      ) {
        updatedVisitStats = {lastTenVisited: [], visitationHistogram: topicVisitStats}
      }

      updatedVisitStats.lastTenVisited = [
        ...new Set([pageIdentifier, ...updatedVisitStats.lastTenVisited]),
      ]
      if (updatedVisitStats.lastTenVisited.length > 10) updatedVisitStats.lastTenVisited.pop()

      if (updatedVisitStats.visitationHistogram[pageIdentifier]) {
        updatedVisitStats.visitationHistogram[pageIdentifier] += 1
      } else {
        updatedVisitStats.visitationHistogram[pageIdentifier] = 1
      }

      setPreferencesValue(topic, updatedVisitStats)
    }

    if (topic && pageIdentifier && valid) {
      updatePageVisitCount()
    }
  }, [topic, pageIdentifier, valid])
}

export default useUpdatePageVisitCount
