import {useDispatch, useSelector} from 'react-redux'
import {setExperimentalValidating as setExperimentalValidatingAction} from '../actions/imageUploaderAction'

/** Triggers the validation function in UploaderExperimentalMetadataForm */
function useExperimentalValidateTrigger() {
  const {experimentalValidating} = useSelector(state => state.imageUploader)
  const dispatch = useDispatch()

  const setExperimentalValidating = state => dispatch(setExperimentalValidatingAction(state))

  return [experimentalValidating, setExperimentalValidating]
}

export default useExperimentalValidateTrigger
