import styled from 'styled-components'

const DropInputLabel = styled('label')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

export default DropInputLabel
