import React, {memo} from 'react'
import styled from 'styled-components'
import { Skeleton } from '@mui/material';

const Tile = styled('div')`
  display: grid;
  grid-template-rows: 54px 1fr;
  grid-template-columns: 1fr 175px;

  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  height: 175px;
  width: 530px;
  overflow: hidden;

  .title {
    padding-top: 8px;
    padding-left: 5px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .thumbnail {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
`

/** Illustrates that another element is loading in the UI */
const DirectoryCardLoadingSkeleton = memo(function() {
  return (
    <Tile>
      <div className="title">
        <Skeleton variant="rectangular" height={34} width={312} animation="wave" />
      </div>

      <div className="thumbnail">
        <Skeleton variant="rectangular" width={175} height={175} animation="wave" />
      </div>
    </Tile>
  );
})

export default DirectoryCardLoadingSkeleton
