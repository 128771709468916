import React, {useEffect, useReducer} from 'react'
import formatBytes from 'pretty-bytes'
import {duration} from 'moment'
import LinearProgress from '@mui/material/LinearProgress'
import {red} from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled, {css} from 'styled-components'
import CardGenericError from './CardGenericError'
import {handleSentryError} from '../utilities/sentryErrors'
import {Upload, UploadProgress, Uploader, initialProgress} from './initializeHyperspectralUploader'

export default function UploaderMissionPackageUploadingPane(props: {uploader: Uploader}) {
  const {uploader} = props
  const data = useUploader(uploader)

  if (data.uploaderComplete) {
    return <UploadComplete {...data} />
  }

  if (data.fatalError) {
    return <UploadError {...data} />
  }

  return <UploadInProgress {...data} />
}

function useUploader(uploader: Uploader): UseUploaderData {
  const [state, dispatch] = useReducer(reducer, initialState)

  function onProgress(update: UploadProgress) {
    const {error} = update

    if (error) {
      handleSentryError(error, 'Error occurred during upload progress')
    }

    dispatch({type: 'set-progress', progress: update})
  }

  useEffect(() => {
    if (!uploader) return

    try {
      const upload = uploader.upload(onProgress)

      dispatch({type: 'set-target-path', targetPath: upload.targetPath})

      return () => {
        cleanupUpload(upload)
      }
    } catch (err) {
      handleSentryError(err, 'Error occurred during uploader upload call')
    }
  }, [uploader])

  const {progress, fatalError, uploaderComplete, fileErrors} = state
  const {bytesDoneFormatted, totalBytesFormatted, timeRemainingFormatted, percentage} =
    formatProgress(progress)
  const isUploading = !(fatalError || uploaderComplete)
  if (fatalError && fileErrors.length > 0) {
    handleSentryError(JSON.stringify(fileErrors), 'Uploader file errors.')
  }

  return {
    ...state,
    bytesDoneFormatted,
    totalBytesFormatted,
    timeRemainingFormatted,
    percentage,
    isUploading,
    missionKey: uploader?.missionKey,
  }
}

function formatProgress(progress: UploadProgress) {
  try {
    const {bytesTotal, bytesDone, timeRemaining, rate} = progress
    const totalBytesFormatted = formatBytes(bytesTotal)
    const bytesDoneFormatted = formatBytes(bytesDone)

    const percentage = Math.min(bytesTotal ? Math.floor((bytesDone / bytesTotal) * 100) : 0, 100)
    let timeRemainingFormatted = 'Calculating time remaining...'
    if (Number.isFinite(timeRemaining)) {
      const timeFormatted = duration(Math.max(60e3, timeRemaining)).humanize()
      const rateFormatted = formatBytes(rate * 8, {bits: true})
      timeRemainingFormatted = `about ${timeFormatted} remaining (${rateFormatted}/s)`
    }
    if (percentage === 100) {
      timeRemainingFormatted = '0s'
    }

    return {totalBytesFormatted, bytesDoneFormatted, timeRemainingFormatted, percentage}
  } catch (err) {
    handleSentryError(err, 'Error formatting progress')
    throw err
  }
}

function cleanupUpload(upload: Upload) {
  if (upload.status === 'ongoing') {
    try {
      upload.suspend()
    } catch (err) {
      handleSentryError(err, 'Error suspending uploader during effect cleanup')
    }
  }
}

function UploadStats(props: UseUploaderData) {
  const {bytesDoneFormatted, totalBytesFormatted, percentage, timeRemainingFormatted, progress} =
    props
  const {filesTotal, filesDone} = progress
  const progressText =
    percentage === 100 ? `${percentage}%` : `${percentage}% – ${timeRemainingFormatted}`

  return (
    <div className="row-status-bar">
      <div className="indicator-zero">
        <Typography variant="caption"> {filesDone} files</Typography>
        <Typography variant="caption"> {bytesDoneFormatted}</Typography>
      </div>
      <div className="indicator-total">
        <Typography variant="caption"> {filesTotal} files</Typography>
        <Typography variant="caption"> {totalBytesFormatted}</Typography>
      </div>

      <Box display="flex" alignItems="center" className="progress-bar">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={percentage} color="primary" />
        </Box>
      </Box>
      <Typography className="progress-indicator" variant="body2">
        {progressText}
      </Typography>
    </div>
  )
}

function UploadInProgress(props: UseUploaderData) {
  return (
    <UploaderContainer>
      <div className="spacer" />
      <Box display="flex" justifyContent="space-evenly" justifySelf="center">
        <Typography variant="h6">Uploading mission {props.missionKey}</Typography>
      </Box>
      <UploadStats {...props} />
      <Box display="flex" flexDirection="column" alignItems="center" className="row-message-area">
        <Typography variant="body2">
          Your mission package is now uploading. Please do not refresh the page or close the
          browser.
        </Typography>
      </Box>
    </UploaderContainer>
  )
}

function UploadComplete(props: UseUploaderData) {
  const {targetPath, progress, missionKey} = props
  const {message} = progress

  return (
    <UploaderContainer>
      <div className="spacer" />
      <Box display="flex" justifyContent="space-evenly" justifySelf="center">
        <Typography variant="h6">Finished uploading mission {missionKey}</Typography>
      </Box>
      <UploadStats {...props} />
      <Box display="flex" flexDirection="column" alignItems="center" className="row-message-area">
        <Typography variant="body1" style={{flex: '1', textAlign: 'center'}}>
          {message}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth="100%">
          <Typography variant="overline">Mission Location</Typography>
          <Path>{targetPath}</Path>
        </Box>
      </Box>
    </UploaderContainer>
  )
}

function UploadError(props: UseUploaderData) {
  const {fatalError, progress} = props

  const {message} = progress

  return (
    <UploaderContainer fatalError={fatalError}>
      <div className="spacer" />
      <UploadStats {...props} />
      <div className="spacer" />
      <Box display="flex" flexDirection="column" alignItems="center" className="row-message-area">
        <CardGenericError noMargin title="Upload Error" description={message} level="error" />
      </Box>
    </UploaderContainer>
  )
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'set-file-errors':
      return {...state, fileErrors: action.errors}
    case 'set-fatal-error':
      return {...state, fatalError: action.fatalError}
    case 'set-uploader-complete':
      return {...state, uploaderComplete: action.uploaderComplete}
    case 'set-progress':
      return handleProgressUpdate(state, action)
    case 'set-target-path':
      return {...state, targetPath: action.targetPath}
    default:
      const p = JSON.stringify(action)
      console.warn(`Uploader mission package uploading pane unhandled action with payload: ${p}`)
      return state
  }
}

function handleProgressUpdate(state: State, action: ProgressAction) {
  const {progress} = action
  const {status, message} = progress

  let newState: State = {...state, progress}

  switch (status) {
    case 'error-file':
      const errors = [...state.fileErrors, message]
      return {...newState, errors}
    case 'complete-partial':
    case 'error-fatal':
      return {...newState, fatalError: true}
    case 'complete':
      return {...newState, uploaderComplete: true}
    case 'ongoing':
    case 'evaluating':
    case 'suspended':
    default:
      return newState
  }
}

type State = {
  fileErrors: any[]
  fatalError: boolean
  uploaderComplete: boolean
  progress: UploadProgress
  targetPath: string
}

type ProgressAction = {
  type: 'set-progress'
  progress: UploadProgress
}

type Action =
  | {
      type: 'set-file-errors'
      errors: any[]
    }
  | {
      type: 'set-fatal-error'
      fatalError: boolean
    }
  | {
      type: 'set-uploader-complete'
      uploaderComplete: boolean
    }
  | ProgressAction
  | {
      type: 'set-target-path'
      targetPath: string
    }

type UseUploaderData = {
  missionKey: string
  bytesDoneFormatted: string
  totalBytesFormatted: string
  timeRemainingFormatted: string
  percentage: number
  isUploading: boolean
  fileErrors: any[]
  fatalError: boolean
  uploaderComplete: boolean
  progress: UploadProgress
  targetPath: string
}

const initialState: State = {
  fileErrors: [],
  fatalError: false,
  uploaderComplete: false,
  progress: initialProgress,
  targetPath: '',
}

const UploaderContainer = styled.section`
  display: grid;

  height: 550px;

  grid-template-rows: 20px 50px 110px 1fr;
  grid-row-gap: 15px;

  .row-status-bar,
  .row-message-area {
    margin: 0 auto;
    min-width: 0;
    width: 90%;
  }

  .row-status-bar {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 20px 40px;

    .indicator-zero {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      justify-self: start;
      align-self: end;
      align-self: end;
      border-left: 1px solid #222;
      position: relative;
      padding: 0 0 4px 4px;
      top: 9px;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
      }
    }

    .indicator-total {
      justify-self: end;
      align-self: end;
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      border-right: 1px solid #222;
      position: relative;
      top: 9px;
      left: -8px;
      padding: 0 4px 4px 0;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
        text-align: right;
      }
    }

    .progress-bar {
      align-self: start;
      padding-top: 7px;
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      align-self: start;
      padding-top: 7px;
    }

    .progress-indicator {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      text-align: center;
    }
  }

  ${props =>
    props.fatalError &&
    css`
      /* Controls main color of bar */
      .MuiLinearProgress-barColorPrimary {
        background-color: ${red[600]};
      }

      /* Controls secondary color of bar */
      .MuiLinearProgress-colorPrimary {
        background-color: ${red[200]};
      }
    `}
`

const Path = styled('pre')`
  background-color: #f0f0f0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 0.5em;
`
