import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

function DialogExportAlbumStepper(props) {
  const {stage} = props
  return (
    <Stepper activeStep={stage}>
      <Step completed={stage > 0}>
        <StepLabel>Select Attributes</StepLabel>
      </Step>
      <Step completed={stage > 1}>
        <StepLabel>Exclude Data Roles</StepLabel>
      </Step>
      <Step>
        <StepLabel>Download</StepLabel>
      </Step>
    </Stepper>
  )
}

DialogExportAlbumStepper.propTypes = {
  stage: PropTypes.number.isRequired,
}

export default DialogExportAlbumStepper
