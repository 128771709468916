import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material'

/**
 * Boolean input for uploader used for the Imagine API (v3)
 */
function UploaderImagineMetadataFieldBoolean({fieldName, value, onChange}) {
  // Initializes value of dropdown as per metadata
  useEffect(() => {
    onChange({target: {value: false}})
  }, [])

  return (
    <FormControl style={{width: '95ch'}}>
      <InputLabel>{fieldName}</InputLabel>
      <Select value={value} onChange={onChange} variant="standard">
        <MenuItem value={true}>True</MenuItem>
        <MenuItem value={false}>False</MenuItem>
      </Select>
    </FormControl>
  )
}

UploaderImagineMetadataFieldBoolean.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

UploaderImagineMetadataFieldBoolean.defaultProps = {
  value: false,
}

export default UploaderImagineMetadataFieldBoolean
