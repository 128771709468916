import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {handleSentryError} from '../utilities/sentryErrors'

import GallerySummaryProject from '../components/GallerySummaryProject'
import GalleryViewErrorCard from '../components/GalleryViewErrorCard'
import GalleryViewGrid from '../components/GalleryViewGrid'
import LoadingSpinner from '../components/LoadingSpinner'
import GalleryVisibleSchemaDialog from '../components/GalleryVisibleSchemaDialog'

import {AlbumListProvider} from '../context/albumListContext'
import {GalleryPageProvider} from '../context/galleryPageContext'
import {imagineServiceContext} from '../context/imagineServiceContext'

import useGalleryPageState from '../hooks/useGalleryPageState'
import useUpdatePageVisitCount from '../hooks/useUpdatePageVisitCount'

import CommonLayout from '../layouts/CommonLayout'
import GalleryPageLayout from '../layouts/GalleryPageLayout'

import noItemsAvailableCopy from '../helpers/noItemsAvailableCopy'

const GalleryPageProject = () => {
  const {project: projectName} = useParams()
  const [project, setProject] = useState(null)
  const [entitlements, setEntitlements] = useState([])
  const [schemaDialog, setSchemaDialog] = useState(false)
  const [itemListing, setItemListing] = useState(null)

  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)

  const {
    error,
    items,
    initialLoading,
    loading,
    numberResultingItems,
    removeItem,
    setNotFoundError,
    setServerError,
    hasMore,
    next,
    setNumberItemsPossible,
  } = useGalleryPageState(itemListing)

  useUpdatePageVisitCount({
    topic: 'projectVisitCount',
    pageIdentifier: projectName,
    valid: project !== null,
  })

  useEffect(() => {
    async function retrieveProject() {
      try {
        const retrievedProject = await imagineSdk.fetchProject({id: projectName})

        if (retrievedProject) {
          setProject(retrievedProject)
        } else {
          setNotFoundError(projectName)
        }
      } catch (e) {
        const additionalErrorDetails = `Unable to retrieve project ${projectName}.`
        handleSentryError(e, additionalErrorDetails)
        setServerError()
      }
    }

    if (!globalLoading && !globalLoadingError) retrieveProject()
    if (globalLoadingError) setServerError()
  }, [globalLoading, globalLoadingError, projectName])

  useEffect(() => {
    async function getItemListingAndCount() {
      try {
        const itemListing = project.fetchItems({limit: Infinity})
        setItemListing(itemListing)
        const count = await itemListing.count()
        setNumberItemsPossible(count)
      } catch (e) {
        const additionalErrorDetails = `Error in retrieving the itemListing or count for project ${project.id}`
        handleSentryError(e, additionalErrorDetails)
      }
    }
    if (project) getItemListingAndCount()
  }, [project])

  useEffect(() => {
    async function getProjectEntitlements() {
      try {
        if (initialLoading) {
          const projectEntitlements = await project.entitlements()
          const userEntitlements = []
          if (projectEntitlements.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
          if (projectEntitlements.currentUserHasEntitlement('DELETE'))
            userEntitlements.push('DELETE')
          if (projectEntitlements.currentUserHasEntitlement('PUBLISH'))
            userEntitlements.push('PUBLISH')
          if (projectEntitlements.currentUserHasEntitlement('DOWNLOAD'))
            userEntitlements.push('DOWNLOAD')
          setEntitlements(userEntitlements)
        }
      } catch (e) {
        const additionalErrorDetails = 'Error in retrieving project entitlements'
        handleSentryError(e, additionalErrorDetails)
        setServerError()
      }
    }

    if (project) getProjectEntitlements()
  }, [project])

  useEffect(() => {
    if (itemListing && next) next('down')
  }, [itemListing])

  if (error && error.title !== noItemsAvailableCopy) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <GalleryViewErrorCard {...error} />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  if (globalLoading || initialLoading) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <LoadingSpinner message="Loading project..." />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  const summary = (
    <>
      <GallerySummaryProject
        projectName={projectName}
        numberItems={numberResultingItems}
        entitlements={entitlements}
        description=""
        openSchemaDialog={() => setSchemaDialog(true)}
        loading={loading}
      />
      <GalleryVisibleSchemaDialog onClose={() => setSchemaDialog(false)} open={schemaDialog} />
    </>
  )

  return (
    <CommonLayout>
      <AlbumListProvider>
        <GalleryPageProvider
          type="project"
          project={project}
          loading={loading}
          items={items}
          removeItem={removeItem}
          hasMore={hasMore}
          next={next}
        >
          <GalleryPageLayout summaryComponent={summary}>
            <GalleryViewGrid />
          </GalleryPageLayout>
        </GalleryPageProvider>
      </AlbumListProvider>
    </CommonLayout>
  )
}

export default GalleryPageProject
