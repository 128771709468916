import React from 'react'
import PropTypes from 'prop-types'
import {useLocation, Link} from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: ${props => (props.selected ? 'var(--mdc-theme-secondary)' : 'var(--mdc-theme-primary)')};

  display: flex;
  align-items: center;
  text-decoration: ${props => (props.selected ? 'underline' : 'none')};
  font-weight: ${props => (props.selected ? '600' : '400')};

  .selected-icon {
    width: 18px !important;

    margin: 2px 5px !important;
    color: ${props =>
      props.selected ? 'var(--mdc-theme-secondary)' : 'var(--mdc-theme-primary)'} !important;
  }
`

/** A link that's fed into the gallery summary histogram */
function GallerySummaryHistogramLink(props) {
  const {elementType, entryName, searchTerm, children} = props
  const location = useLocation().search

  const searchParams = new URLSearchParams(location)

  let existingValue = searchParams.get(elementType) ?? ''

  const valuesArr = existingValue.split(',')

  if (existingValue) {
    existingValue += `,${entryName}`

    searchParams.set(elementType, existingValue)
  } else {
    searchParams.set(elementType, entryName)
  }

  const selected = valuesArr.indexOf(entryName) !== -1

  return (
    <StyledLink selected={selected} to={`/search/${searchTerm}?${searchParams.toString()}`}>
      {children}
    </StyledLink>
  )
}

GallerySummaryHistogramLink.propTypes = {
  /** The type of link being generated. One of "Project", "Collection", or "tag" */
  elementType: PropTypes.string.isRequired,
  /** The string value of this link */
  entryName: PropTypes.string.isRequired,
  /** The search term of the gallery page in question */
  searchTerm: PropTypes.string.isRequired,
}

export default GallerySummaryHistogramLink
