import React from 'react'
import PropTypes from 'prop-types'
import {Card} from '@mui/material'
import {LockOpen, PhotoLibrary, Label} from '@mui/icons-material'
import styled from 'styled-components'

import Histogram from './Histogram'

const Container = styled.div`
  width: 560px;

  h5 {
    font-size: 1.2rem;
  }

  .MuiSvgIcon-root {
    color: var(--mdc-theme-primary);
    margin-top: 5px;
    margin-left: 4px;
  }

  .MuiPaper-root {
    width: calc(600px - (2 * 24px));
    padding: 5px;
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: center;
  }

  .no-tags .MuiSvgIcon-root {
    color: var(--mdc-theme-text-secondary-on-light) !important;
  }
`

/** A histogram-like display of item counts for a specific search */
function GallerySummaryHistogram(props) {
  const {numberItems, searchTerm, projectsHistogram, collectionsHistogram, tagsHistogram} = props

  return (
    <Container>
      <Card elevation={1}>
        <div>
          <LockOpen />
        </div>
        <div>Distribution of projects</div>
      </Card>

      <Histogram
        elements={projectsHistogram}
        searchTerm={searchTerm}
        elementType="projects"
        numberElements={numberItems}
      />

      <Card elevation={1}>
        <div>
          <PhotoLibrary />
        </div>
        <div>Distribution of collections</div>
      </Card>

      <Histogram
        elements={collectionsHistogram}
        searchTerm={searchTerm}
        elementType="collections"
        numberElements={numberItems}
      />

      {Object.keys(tagsHistogram).length > 0 ? (
        <>
          <Card elevation={1}>
            <div>
              <Label />
            </div>
            <div>Distribution of tags</div>
          </Card>

          <Histogram
            elements={tagsHistogram}
            searchTerm={searchTerm}
            elementType="tags"
            numberElements={numberItems}
          />
        </>
      ) : (
        <Card elevation={1}>
          <div className="no-tags">
            <Label />
          </div>
          <div>No tags were found in this search</div>
        </Card>
      )}
    </Container>
  )
}

GallerySummaryHistogram.propTypes = {
  projectsHistogram: PropTypes.objectOf(
    PropTypes.shape({
      numResultingItems: PropTypes.number,
      numBaselineResultingItems: PropTypes.number,
    })
  ).isRequired,

  collectionsHistogram: PropTypes.objectOf(
    PropTypes.shape({
      numResultingItems: PropTypes.number,
      numBaselineResultingItems: PropTypes.number,
    })
  ).isRequired,
  tagsHistogram: PropTypes.objectOf(
    PropTypes.shape({
      numResultingItems: PropTypes.number,
      numBaselineResultingItems: PropTypes.number,
    })
  ).isRequired,

  /** Number items retrieved in search. Used for determining relative bar lengths */
  numberItems: PropTypes.number.isRequired,

  /** Used for generating links in each of the histogram bars */
  searchTerm: PropTypes.string.isRequired,
}

export default GallerySummaryHistogram
