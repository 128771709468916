import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'

const Container = styled.div`
  margin-left: 10px;
`

/**
 * Displays and handles fade effects for advaned search results preview
 *
 */
function AdvancedSearchResultsPreviewText(props) {
  const {label, containerStyles} = props

  return (
    <Container style={containerStyles}>
      <Typography variant="body2">{label}</Typography>
    </Container>
  )
}

AdvancedSearchResultsPreviewText.propTypes = {
  /** The content that will be displayed */
  label: PropTypes.string.isRequired,
}

export default AdvancedSearchResultsPreviewText
