import React, {useEffect} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {AppBar, Button, Drawer, Typography} from '@mui/material'
import {Close as CloseIcon} from '@mui/icons-material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import AdvancedSearchLayout from '../layouts/AdvancedSearchLayout'
import {decodeAdvancedSearchQuery} from '../utilities/advancedSearchQueryString'

const StyledAppBar = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const DrawerSection = styled.section`
  padding-left: 30px;
  width: 100vw;
`

/**
 * Contains both the drawer and the button which toggles the drawer
 */
function AdvancedSearchDrawer() {
  const {drawerOpen, toggleDrawer, initializeValues} = useAdvancedSearch()
  const {search} = useLocation()

  // Used only in the basic search page
  const {searchTerm: basicSearchPageSearchTerm = ''} = useParams()

  // Loads advanced search results upon initial page load
  useEffect(() => {
    const searchTerms = decodeAdvancedSearchQuery(search, basicSearchPageSearchTerm)
    initializeValues(searchTerms)
  }, [])

  return (
    <>
      <Button
        size="small"
        className="advanced-search-toggle"
        variant="outlined"
        style={{height: '53.625px'}}
        onClick={toggleDrawer}
        color="primary"
        id="test-e2e-button-toggle-advanced-search-drawer"
      >
        <Typography variant="button">Advanced</Typography>
      </Button>
      <Drawer open={drawerOpen} onClose={toggleDrawer} anchor="left">
        <StyledAppBar>
          <Typography variant="h6" sx={{flexGrow: 1, padding: '15px'}}>
            Imagine Advanced Search
          </Typography>
          <Button color="inherit" onClick={toggleDrawer} startIcon={<CloseIcon />}>
            Close
          </Button>
        </StyledAppBar>
        <DrawerSection>
          <AdvancedSearchLayout />
        </DrawerSection>
      </Drawer>
    </>
  )
}

export default AdvancedSearchDrawer
