import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {blue} from '@mui/material/colors/'

const Bar = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  width: 750px;
  height: 30px;

  .label {
    text-align: end;
    padding-right: 10px;
    border-right: 1px solid #222;
    padding-top: 4px;
    height: 30px;
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
  }

  .bar {
    width: calc(${props => props.$width} * 450px);
    min-width: ${props => (props.$width > 0 ? '5px' : '0')};

    background-color: ${blue[800]};
  }

  .bar,
  .baseline-bar {
    height: 25px;
  }

  .baseline-bar {
    min-width: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .bar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: middle;
  }

  ${props =>
    props.$baselineWidth > 0 &&
    css`
      .baseline-bar {
        width: calc(${props.$baselineWidth} * 450px);

        background-color: ${blue[300]};
      }
    `}

  .value {
    padding: 0 5px;
  }

  .baseline-value {
    padding-left: 5px;
    padding-top: 2px;
    font-style: italic;
  }

  .value,
  .baseline-value {
    text-decoration: none;
  }
`

/**
 * Doubles as a histogram bar as well as link content to an enhanced search term
 */
function HistogramBar(props) {
  const {value, baselineValue, numberElements, elementName} = props

  const activeBaselineLessThanObserved = baselineValue && baselineValue < value
  const backupBaselineValue = activeBaselineLessThanObserved ? value : baselineValue

  if (activeBaselineLessThanObserved) {
    console.warn(`Warning: Baseline value for ${elementName} less than enhanced search value`)
  }

  const hideValue =
    (backupBaselineValue && backupBaselineValue === value) ||
    (value === 0 &&
      backupBaselineValue !== 0 &&
      parseInt((100 * baselineValue) / numberElements) < 5)

  return (
    <Bar $width={value / numberElements} $baselineWidth={backupBaselineValue / numberElements}>
      <div className="label">{elementName}</div>
      <div className="bar-container">
        <div className="baseline-bar">
          <div className="bar"></div>
          {hideValue ? <></> : <div className="value">{value}</div>}
        </div>
        {backupBaselineValue ? <div className="baseline-value">{backupBaselineValue}</div> : <></>}
      </div>
    </Bar>
  )
}

HistogramBar.propTypes = {
  value: PropTypes.number,
  baselineValue: PropTypes.number,
  numberElements: PropTypes.number.isRequired,
  /** The name of this histogram bar */
  elementName: PropTypes.string.isRequired,
}

HistogramBar.defaultProps = {
  value: 0,
  baselineValue: 0,
}

export default HistogramBar
