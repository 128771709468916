import React from 'react'
import styled from 'styled-components'

import CardGenericError from '../components/CardGenericError'

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

function Page404() {
  return (
    <NotFoundContainer>
      <CardGenericError
        title="404 - Page not found"
        description="You visited a route that does not exist"
        caption="Please contact Location 360 support if you believe this is in error"
      />
    </NotFoundContainer>
  )
}

export default Page404
