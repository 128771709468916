import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material'
import {HighlightOff, Edit, Done} from '@mui/icons-material'

import DialogMultiTagAddFormEditField from './DialogMultiTagAddFormEditField'

const TagRows = styled.div`
  display: grid;
  margin-top: 10px;
  padding-left: 5px;
  grid-auto-rows: 45px;
  grid-template-columns: 1fr 40px 40px;
  align-items: center;
  grid-column-gap: 5px;

  .form {
    grid-column: 1 / 2;
    padding-left: 5px;
  }

  .edit,
  .confirm {
    grid-column: 2 / 3;
  }

  .cancel,
  .remove {
    grid-column: 3 / 4;
  }
`

/**
 * Provides a table for all the items that won't allow tag adding
 */
function DialogMultiTagAddForm(props) {
  const {handleClose, loading, setTags} = props
  const [proposedTags, setProposedTags] = useState([])
  const [newProposedTag, setNewProposedTag] = useState('')
  const inputEl = useRef(null)
  const proceed = () => setTags([...proposedTags.map(i => i.tag), newProposedTag])

  const disableAddProposedTag =
    newProposedTag === '' || proposedTags.map(i => i.tag).indexOf(newProposedTag) !== -1

  function addProposedTag() {
    if (disableAddProposedTag) return

    setProposedTags([...proposedTags, {tag: newProposedTag, editing: false}])
    setNewProposedTag('')

    inputEl.current.focus()
    inputEl.current.scrollIntoView()
  }

  function removeProposedTag(index) {
    const newTags = [...proposedTags]
    newTags.splice(index, 1)
    setProposedTags(newTags)
  }

  function toggleEditingProposedTag(index) {
    const newTags = [...proposedTags]
    newTags[index].editing = !newTags[index].editing
    setProposedTags(newTags)
  }

  function editProposedTag(index, value) {
    const newTags = [...proposedTags]
    newTags[index].tag = value
    newTags[index].editing = false
    setProposedTags(newTags)
  }

  return (
    <>
      <DialogContent
        sx={{
          width: '600px',
          height: '340px',
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '26px',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{marginLeft: '10px'}}>
          Add the tags you wish to add to the selection, and proceed when you are ready to add them.
        </Typography>
        <TagRows>
          {proposedTags.map(({tag, editing}, index) =>
            editing ? (
              <DialogMultiTagAddFormEditField
                key={`tag-${tag}-${index}`}
                value={tag}
                setValue={updatedTag => editProposedTag(index, updatedTag)}
              />
            ) : (
              <React.Fragment key={`tag-${tag}-${index}`}>
                <TextField
                  value={tag}
                  onChange={event => editProposedTag(index, event.target.value)}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.key === 'esc') {
                      toggleEditingProposedTag(index)
                    }
                  }}
                  disabled={!editing}
                  className="form"
                />
                <IconButton
                  className="edit"
                  onClick={() => toggleEditingProposedTag(index)}
                  size="large"
                >
                  <Edit color="primary" />
                </IconButton>
                <IconButton
                  className="remove"
                  onClick={() => removeProposedTag(index)}
                  size="large"
                >
                  <HighlightOff color="primary" />
                </IconButton>
              </React.Fragment>
            )
          )}
          <TextField
            value={newProposedTag}
            onChange={event => setNewProposedTag(event.target.value)}
            onKeyUp={event => {
              if (event.key === 'Enter') addProposedTag()
            }}
            className="form"
            ref={inputEl}
            focused
          />
          <IconButton
            className="confirm"
            disabled={disableAddProposedTag}
            onClick={addProposedTag}
            size="large"
          >
            <Done color={disableAddProposedTag ? 'disabled' : 'primary'} />
          </IconButton>
        </TagRows>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button color="primary" onClick={proceed} disabled={loading}>
          {loading ? 'updating' : 'proceed'}
        </Button>
      </DialogActions>
    </>
  )
}

DialogMultiTagAddForm.propTypes = {
  /** Closes the dialog */
  handleClose: PropTypes.func.isRequired,
  /** Indicates when tags are loading */
  loading: PropTypes.bool.isRequired,
  /** function that adds tags to the selected items */
  setTags: PropTypes.func.isRequired,
}

export default DialogMultiTagAddForm
