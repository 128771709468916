import React from 'react'
import {Button, DialogActions, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {
  closeUploader,
  setUploaderWorkflowStage,
  resetUploader,
} from '../actions/imageUploaderAction'
import useImageUploaderValidate from '../hooks/useImageUploaderValidate'

const IconGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
  margin-right: -5px;

  span {
    font-size: 14px !important;
  }
`

/**
 * Controls the apperance and actions of the Imagine Uploader based on the workflow and the stage.
 *
 * Also used for the Mission Package Uploader
 */
function UploaderDialogFooterImagine() {
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  const availableWorkflowStages = useSelector(state => state.imageUploader.availableWorkflowStages)

  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeUploader())
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const resetDialog = () => dispatch(resetUploader())
  const {ready: imagineUploaderValidateReady, validate} = useImageUploaderValidate()

  const canUpload = availableWorkflowStages.indexOf(3) !== -1

  return (
    <DialogActions style={{flexDirection: 'row'}}>
      {workflowStage === 0 && (
        <>
          <Button
            disabled={availableWorkflowStages.indexOf(1) === -1}
            onClick={() => setUploaderStage(1)}
          >
            Confirm Image
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
      {workflowStage === 1 && (
        <>
          <Button
            disabled={availableWorkflowStages.indexOf(2) === -1}
            onClick={() => setUploaderStage(2)}
          >
            Next
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
      {workflowStage === 2 && (
        <>
          <Button onClick={validate} disabled={!imagineUploaderValidateReady}>
            <Typography color="primary" variant="button">
              Validate
            </Typography>
          </Button>
          <Button
            disabled={availableWorkflowStages.indexOf(3) === -1}
            onClick={() => setUploaderStage(3)}
          >
            <IconGroup>
              <CloudUploadIcon color={canUpload ? 'primary' : 'inherit'} />
              <Typography color={canUpload ? 'primary' : 'inherit'} variant="button">
                Upload
              </Typography>
            </IconGroup>
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
      {workflowStage === 3 && (
        <>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
            disabled
          >
            Uploading...
          </Button>
        </>
      )}
      {workflowStage === 4 && (
        <>
          <Button onClick={resetDialog} color="primary">
            Upload again
          </Button>
          <Button
            onClick={e => {
              handleClose(e)
            }}
            color="primary"
          >
            Close
          </Button>
        </>
      )}
    </DialogActions>
  )
}

export default UploaderDialogFooterImagine
