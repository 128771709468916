import * as Sentry from '@sentry/react'

export function handleSentryError(error, additionalErrorDetails = '') {
  const combinedError = additionalErrorDetails
    ? new Error(additionalErrorDetails, {cause: error})
    : error
  console.error(...flattenErrors(combinedError))
  Sentry.captureException(combinedError)
}

function flattenErrors(error) {
  const output = []
  let currentError = error
  while (currentError) {
    output.push(currentError)
    currentError = currentError?.cause
  }
  return output
}
