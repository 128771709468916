import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import HistogramLink from './HistogramLink'
import HistogramBar from './HistogramBar'

const Container = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
  max-width: 800px;
`

const sortValues = ([, {numResultingItems: valueA}], [, {numResultingItems: valueB}]) =>
  valueB - valueA

function Histogram(props) {
  const {searchTerm, elements, elementType, numberElements} = props

  return (
    <Container>
      {Object.entries(elements)
        .sort(sortValues)
        .map(([entryName, {numResultingItems, numBaselineResultingItems}]) => (
          <HistogramLink
            elementType={elementType}
            entryName={entryName}
            searchTerm={searchTerm}
            key={`histogram-link-${entryName}`}
          >
            <HistogramBar
              value={numResultingItems}
              baselineValue={numBaselineResultingItems}
              numberElements={numberElements}
              elementName={entryName}
            />
          </HistogramLink>
        ))}
    </Container>
  )
}

Histogram.propTypes = {
  /** A syntheized set of data which feeds the histogram */
  elements: PropTypes.objectOf(
    PropTypes.shape({
      numResultingItems: PropTypes.number,
      numBaselineResultingItems: PropTypes.number,
    })
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  /** One of "projects", "collections", "tags" */
  elementType: PropTypes.string.isRequired,
  numberElements: PropTypes.number.isRequired,
}

Histogram.defaultProps = {comparedEntries: []}

export default Histogram
