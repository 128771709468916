import React, {useContext, useEffect, useState} from 'react'

import {Breadcrumbs, Typography} from '@mui/material'
import {useLocation, useParams, Link} from 'react-router-dom'
import styled from 'styled-components'
import {
  Error as ErrorIcon,
  Collections as CollectionsIcon,
  Home as HomeIcon,
  ImageSearch as ImageSearchIcon,
  Photo as PhotoIcon,
  Folder as FolderIcon,
} from '@mui/icons-material'

import {imagineServiceContext} from '../context/imagineServiceContext'
import {handleSentryError} from '../utilities/sentryErrors'

const BreadcrumbContainer = styled.div`
  padding-bottom: 12px;
  padding-left: 10px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);

  .link-row {
    display: flex;
    align-items: center;
  }

  .MuiSvgIcon-root {
    margin-left: 5px;
    margin-right: 5px;
  }
`

/** ***************** REGEX EXPLAINER ********************************
 *
 *  /album\/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})(\?page=\d{1,5})?$/
 *
 *   album\/
 *   | * Looks for an "album/" route
 *
 *          ([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})
 *          | * Captures a valid UUID that is the core of the item
 *
 *                                                                                 (\?page=\d{1,5})?$
 *                                              | * Allows for a optional page query param at the end
 */

const albumIdRe =
  /album\/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})(\?page=\d{1,5})?$/

/*
 * App-wide breadcrumbs. Digests the location through window.location and determines
 * the current state of the locaton. Requires the query-params to play nice.
 *
 *

*/

function getParent(url) {
  const urlParams = new URLSearchParams(url.search)
  const parent = urlParams.get('parent')
  const parentType = parent && parent.split('/')[1]
  const itemName = parent && parent.match(/.*\/(.*)/)[1]
  return {parent, parentType, itemName, urlParams}
}

function LayoutBreadcrumbs() {
  const [albumTitle, setAlbumTitle] = useState(null)
  const location = useLocation()
  const params = useParams()
  const {imagineSdk, loading} = useContext(imagineServiceContext)

  useEffect(() => {
    const albumId = params.album || location.search.match(albumIdRe)?.[1]

    if (!albumId) {
      return
    }

    async function retrieveAlbumName() {
      try {
        const album = await imagineSdk.fetchAlbum({id: albumId})
        if (album) {
          setAlbumTitle(album.title)
        }
      } catch (err) {
        const additionalErrorDetails = 'There was an error with retrieving the albums.'
        handleSentryError(err, additionalErrorDetails)
      }
    }
    if (albumId && !loading) retrieveAlbumName()
  }, [loading])

  function HomeBreadcrumb() {
    return (
      <Link color="inherit" to="/" className="link-row" aria-label="nav-breadcrumb">
        <HomeIcon />
        <Typography aria-label="link-breadcrumb">Imagine UI</Typography>
      </Link>
    )
  }

  function ItemViewBreadcrumb() {
    return (
      <div className="link-row" aria-label="nav-breadcrumb">
        <PhotoIcon />
        <Typography aria-label="normal-breadcrumb">Item</Typography>
      </div>
    )
  }

  const SurroundingElements = ({children}) => (
    <BreadcrumbContainer>
      <Breadcrumbs>
        <HomeBreadcrumb />
        {children}
      </Breadcrumbs>
    </BreadcrumbContainer>
  )

  function DirectoryBreadcrumb({directory}) {
    return (
      <div className="link-row" aria-label="nav-breadcrumb">
        <Link className="link-row" to={`/directory/${directory.toLowerCase()}`}>
          <FolderIcon />
          <Typography aria-label="link-breadcrumb">{directory}</Typography>
        </Link>
      </div>
    )
  }

  if (/^\/project/.test(location.pathname)) {
    const {project} = params
    return (
      <SurroundingElements>
        <DirectoryBreadcrumb directory="Projects" />
        <div className="link-row" aria-label="nav-breadcrumb">
          <CollectionsIcon />
          <Typography aria-label="normal-breadcrumb">{project}</Typography>
        </div>
      </SurroundingElements>
    )
  } else if (/^\/collection/.test(location.pathname)) {
    const {collection} = params
    return (
      <SurroundingElements>
        <DirectoryBreadcrumb directory="Collections" />
        <div className="link-row" aria-label="nav-breadcrumb">
          <CollectionsIcon />
          <Typography aria-label="normal-breadcrumb">{collection}</Typography>
        </div>
      </SurroundingElements>
    )
  } else if (/^\/album/.test(location.pathname)) {
    const {album} = params
    return (
      <SurroundingElements>
        <DirectoryBreadcrumb directory="Albums" />
        <div className="link-row" aria-label="nav-breadcrumb">
          <CollectionsIcon />
          <Typography aria-label="normal-breadcrumb">{albumTitle ?? album}</Typography>
        </div>
      </SurroundingElements>
    )
  } else if (/^\/search/.test(location.pathname)) {
    return (
      <SurroundingElements>
        <div className="link-row" aria-label="nav-breadcrumb">
          <ImageSearchIcon />
          <Typography aria-label="normal-breadcrumb">Search</Typography>
        </div>
      </SurroundingElements>
    )
  } else if (/\/advanced-search/.test(location.pathname)) {
    return (
      <SurroundingElements>
        <div className="link-row" aria-label="nav-breadcrumb">
          <ImageSearchIcon />
          <Typography aria-label="normal-breadcrumb">Advanced Search</Typography>
        </div>
      </SurroundingElements>
    )
  } else if (/\/item/.test(location.pathname)) {
    const {parent, parentType, itemName, urlParams} = getParent(window.location)
    const search = urlParams.get('search')

    if (!parent) {
      const itemId = urlParams.get('id')
      return (
        <SurroundingElements>
          <div className="link-row" aria-label="nav-breadcrumb">
            <ImageSearchIcon />
            <Typography aria-label="normal-breadcrumb">{itemId}</Typography>
          </div>
        </SurroundingElements>
      )
    } else if (parentType === 'project') {
      return (
        <SurroundingElements>
          <DirectoryBreadcrumb directory="Projects" />
          <div className="link-row" aria-label="nav-breadcrumb">
            <Link className="link-row" to={`${parent}${search ? search : ''}`}>
              <CollectionsIcon />
              <Typography aria-label="link-breadcrumb">{itemName}</Typography>
            </Link>
          </div>
          <ItemViewBreadcrumb />
        </SurroundingElements>
      )
    } else if (parentType === 'collection') {
      return (
        <SurroundingElements>
          <DirectoryBreadcrumb directory="Collections" />
          <div className="link-row" aria-label="nav-breadcrumb">
            <Link className="link-row" to={`${parent}${search ? search : ''}`}>
              <>
                <CollectionsIcon />
                <Typography aria-label="link-breadcrumb">{itemName}</Typography>
              </>
            </Link>
          </div>
          <ItemViewBreadcrumb />
        </SurroundingElements>
      )
    } else if (parentType === 'album') {
      return (
        <SurroundingElements>
          <DirectoryBreadcrumb directory="Albums" />
          <div className="link-row" aria-label="nav-breadcrumb">
            <Link className="link-row" to={`${parent}${search ? search : ''}`}>
              <CollectionsIcon />
              <Typography aria-label="link-breadcrumb">{albumTitle ?? itemName}</Typography>
            </Link>
          </div>
          <ItemViewBreadcrumb />
        </SurroundingElements>
      )
    } else if (parentType === 'search') {
      return (
        <SurroundingElements>
          <div className="link-row" aria-label="nav-breadcrumb">
            <Link className="link-row" to={`${parent}${search ? search : ''}`}>
              <>
                <ImageSearchIcon />
                <Typography aria-label="link-breadcrumb">Search</Typography>
              </>
            </Link>
          </div>
          <ItemViewBreadcrumb />
        </SurroundingElements>
      )
    } else if (parentType === 'advanced-search') {
      const lastAdvancedSearch = window.localStorage.getItem('LAST_ADVANCED_SEARCH')
      return (
        <SurroundingElements>
          <div className="link-row" aria-label="nav-breadcrumb">
            <Link className="link-row" to={`${parent}?${lastAdvancedSearch}`}>
              <>
                <ImageSearchIcon />
                <Typography aria-label="link-breadcrumb">Advanced Search</Typography>
              </>
            </Link>
          </div>
          <ItemViewBreadcrumb />
        </SurroundingElements>
      )
    }
  }

  const StaticDirectoryBreadcrumb = ({directory}) => (
    <div className="link-row">
      <FolderIcon />
      <Typography>{directory}</Typography>
    </div>
  )

  const StaticGalleryBreadcrumb = ({type, name}) => (
    <div className="link-row">
      <CollectionsIcon />
      <Typography>
        {type}: {name}
      </Typography>
    </div>
  )

  const SearchBreadcrumb = ({type = 'Search'}) => (
    <div className="link-row">
      <ImageSearchIcon />
      <Typography>{type}</Typography>
    </div>
  )

  const PageNotFoundCrumb = () => (
    <div className="link-row">
      <ErrorIcon />
      <Typography>(404) Not Found</Typography>
    </div>
  )

  return (
    <BreadcrumbContainer>
      <Breadcrumbs>
        <HomeBreadcrumb />
        {/^\/$/.test(location.pathname) && <StaticDirectoryBreadcrumb directory="Projects" />}
        {/* Second Position Crumbs */}
        {/^\/directory\/projects/.test(location.pathname) && (
          <StaticDirectoryBreadcrumb directory="Projects" />
        )}
        {/^\/directory\/collections/.test(location.pathname) && (
          <StaticDirectoryBreadcrumb directory="Collections" />
        )}
        {/^\/directory\/albums/.test(location.pathname) && (
          <StaticDirectoryBreadcrumb directory="Albums" />
        )}
        {params.project && <StaticGalleryBreadcrumb name={params.project} type="Project" />}
        {params.collection && (
          <StaticGalleryBreadcrumb name={params.collection} type="Collection" />
        )}
        {params.album && <StaticGalleryBreadcrumb name={albumTitle ?? params.album} type="Album" />}
        {params.searchTerm && <SearchBreadcrumb type="Search" />}
        {location.pathname === '/advanced-search' && <SearchBreadcrumb type="Advanced Search" />}
        {!(
          params.collection ||
          params.album ||
          params.project ||
          params.searchTerm ||
          [
            '/advanced-search',
            '/item',
            '/',
            '/directory/collections',
            '/directory/projects',
            '/directory/albums',
          ].indexOf(location.pathname) !== -1
        ) && <PageNotFoundCrumb />}
        {/* Second and Third Position Crumbs */}
        {location.pathname === '/item' && <ItemViewBreadcrumb />}
      </Breadcrumbs>
    </BreadcrumbContainer>
  )
}

export default LayoutBreadcrumbs
