import React from 'react'
import {FormControl} from '@mui/material'
import {LocalizationProvider, DateTimePicker} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {TextField} from '@mui/material'

function UploaderImagineMetadataFieldTime({field, onChange, value, warning}) {
  const date = value || null

  return (
    <FormControl className="full-width">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          margin="normal"
          id={`datepicker-${field.attribute}`}
          label={`Select ${field.attribute}`}
          value={date}
          onChange={e => {
            if (e) {
              onChange({
                [field.attribute]: e.toISOString(),
              })
            } else {
              onChange({
                [field.attribute]: undefined,
              })
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              error={warning ? true : false}
              helperText={warning?.message ? warning.message : ''}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export default UploaderImagineMetadataFieldTime
