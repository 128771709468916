import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import styled, {css} from 'styled-components'

const SpinnerElement = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  ${props => {
    if (props.fullHeight) {
      return css`
        height: 100vh;
        width: 100vw;
      `
    } else if (props.dialogMode) {
      return css``
    } else if (props.$height) {
      return css`
        height: ${props.$height};
        width: 100vw;
      `
    } else {
      return css`
        height: 70vh;
        width: 100vw;
      `
    }
  }}
`

const StyledCircularProgress = styled(CircularProgress)`
  color: #90caf9;
  display: block;
`

function LoadingSpinner({message, fullHeight, height, dialogMode}) {
  return (
    <SpinnerElement fullHeight={fullHeight} $height={height} dialogMode={dialogMode}>
      <StyledCircularProgress size={120} />
      <Typography variant="body2" align="center" style={{marginTop: '15px'}}>
        {message}
      </Typography>
    </SpinnerElement>
  )
}

LoadingSpinner.displayName = 'LoadingSpinner'

LoadingSpinner.propTypes = {
  dialogMode: PropTypes.bool,
  fullHeight: PropTypes.bool,
  height: PropTypes.string,
  message: PropTypes.string,
}

LoadingSpinner.defaultProps = {
  dialogMode: false,
  fullHeight: false,
  height: '',
  message: '',
}

export default LoadingSpinner
