import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FormControl, FormHelperText, Input, InputLabel} from '@mui/material'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  padding-left: 15px;
`

function UploaderOrthoMosaicMetadataFieldInteger(props) {
  const {
    helperText,
    label,
    interval,
    minimum,
    maximum,
    reactRef,
    setValue,
    value,
    errorLevel,
    errorMessage,
  } = props
  const [currentValue, setCurrentValue] = useState(value)

  if (minimum > maximum)
    throw new Error(
      'minumum prop must be less than maximum prop in UploaderOrthoMosaicMetadataFieldInteger'
    )

  function updateCentralValue() {
    const num = parseInt(currentValue)
    if (isNaN(minimum) || isNaN(maximum)) setValue(num ?? 0)
    const remainder = num % interval

    if (num < minimum) {
      setValue(minimum)
      setCurrentValue(minimum)
    } else if (num > maximum) {
      setValue(maximum)
      setCurrentValue(maximum)
    } else if (remainder / interval >= 0.5) {
      setValue(num - remainder + interval)
      setCurrentValue(num - remainder + interval)
    } else {
      setValue(num - remainder)
      setCurrentValue(num - remainder)
    }
  }

  return (
    <FormControl key={`dropdown-field-${label}`} error={errorLevel === 'error'} ref={reactRef}>
      <InputLabel>{label}</InputLabel>
      <StyledInput
        value={currentValue}
        type="number"
        onChange={event => setCurrentValue(event.target.value)}
        onBlur={updateCentralValue}
      />
      {errorLevel === 'error' ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

UploaderOrthoMosaicMetadataFieldInteger.propTypes = {
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  interval: PropTypes.number,
  label: PropTypes.string.isRequired,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  reactRef: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number,
}

UploaderOrthoMosaicMetadataFieldInteger.defaultProps = {
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  interval: 1,
  minimum: null,
  maximum: null,
  value: 0,
}

export default UploaderOrthoMosaicMetadataFieldInteger
