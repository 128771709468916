import React, {createContext} from 'react'

/**
 * The following is the list of expected parameters of this state
 *
 */
const initialState = {
  album: null,
}

const galleryAlbumContext = createContext(initialState)
const {Provider} = galleryAlbumContext

/**
 * Album Instance Store
 * Provides a store to hone down on prop drilling for updating and deleting
 * */
function GalleryAlbumProvider({children, album, removeItemsFromList}) {
  return <Provider value={{album, removeItemsFromList}}>{children}</Provider>
}

export {galleryAlbumContext, GalleryAlbumProvider}
