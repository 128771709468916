import React, {useState, useContext, useEffect} from 'react'

import {itemContext} from '../context/itemContext'

import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'
import ItemMetadataSummaryCarousel from './ItemMetadataSummaryCarousel'

/**
 * Component that wraps the thumbnail carousel. Allows user to expand/collapse thumbnail header
 */
function ItemMetadatThumbnailPreview() {
  const context = useContext(itemContext)

  const {
    itemMetadata: {assetsWithThumbnail},
  } = context

  const [assetThumbnailUrls, setAssetThumbnailUrls] = useState([])

  useEffect(() => {
    async function getAssetThumbnailUrls() {
      const urls = []
      for (const asset of assetsWithThumbnail) {
        const thumbnailUrl = await asset.fetchDownloadUrl()
        urls.push({
          assetName: asset.name,
          thumbnailUrl,
        })
      }

      return urls
    }

    if (assetsWithThumbnail) {
      getAssetThumbnailUrls()
        .then(results => {
          setAssetThumbnailUrls(results)
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [assetsWithThumbnail])

  if (assetThumbnailUrls.length <= 1) {
    return null
  }

  return (
    <ItemMetadataHeaderAccordion header="Thumbnails">
      <ItemMetadataSummaryCarousel assetThumbnailUrls={assetThumbnailUrls} />
    </ItemMetadataHeaderAccordion>
  )
}

export default ItemMetadatThumbnailPreview
