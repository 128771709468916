import styled from 'styled-components'

const DialogContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 400px;

  .MuiDialogContent-root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
`

export default DialogContentContainer
