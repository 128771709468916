import React from 'react'
import PropTypes from 'prop-types'
import {FormControl, FormHelperText, Input, InputLabel} from '@mui/material'

function UploaderOrthoMosaicMetadataFieldString(props) {
  const {helperText, label, setValue, value, errorLevel, reactRef, errorMessage} = props

  return (
    <FormControl key={`dropdown-field-${label}`} error={errorLevel === 'error'} ref={reactRef}>
      <InputLabel>{label}</InputLabel>
      <Input defaultValue={value} type="text" onBlur={event => setValue(event.target.value)} />
      {errorLevel === 'error' ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

UploaderOrthoMosaicMetadataFieldString.propTypes = {
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  reactRef: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
}

UploaderOrthoMosaicMetadataFieldString.defaultProps = {
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  reactRef: null,
  value: '',
}

export default UploaderOrthoMosaicMetadataFieldString
