import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {IconButton, TextField, Typography} from '@mui/material'
import {Clear as CancelIcon, Check as SubmitIcon, Create as EditIcon} from '@mui/icons-material'

import {galleryAlbumContext} from '../context/galleryAlbumContext'
import useToastNotification from '../hooks/useToastNotification'
import {handleSentryError} from '../utilities/sentryErrors'

/**
 * Displays and provides editing for gallery summary's description field
 *
 */
const GallerySummaryAlbumDescriptionField = function (props) {
  const {editable, description} = props
  const [editing, setEditing] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [editedDescription, setEditedDescription] = useState(description)
  const {album} = useContext(galleryAlbumContext)
  const {errorNotification, successNotification} = useToastNotification()

  // Updates the value of the description on the SDK on completion of editing
  useEffect(() => {
    function updateDescription() {
      album
        .updateMetadata({description: editedDescription})
        .then(() => {
          successNotification('Description updated')
        })
        .catch(e => {
          const additionalErrorDetails = 'There was an error in updating the album description.'
          handleSentryError(e, additionalErrorDetails)
          errorNotification(e.message)
          setEditedDescription(description)
        })
    }

    if (!(editing || initialLoad) && editedDescription !== description) updateDescription()
    if (initialLoad) setInitialLoad(false)
  }, [editing])

  const StaticMode = () => (
    <>
      {editedDescription && <Typography variant="body2">{editedDescription}</Typography>}
      {!editedDescription && editable && (
        <Typography
          variant="body2"
          sx={{
            letterSpacing: '2px',
            fontWeight: 600,
            color: 'rgba(0,0,0,0.30)',
            paddingLeft: '1px',
          }}
        >
          Add description
        </Typography>
      )}
      {editable && (
        <IconButton onClick={() => setEditing(true)} size="large">
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </>
  )

  const EditingMode = () => (
    <>
      <TextField
        defaultValue={editedDescription}
        onBlur={e => setEditedDescription(e.target.value)}
        onKeyUp={event => {
          if (event.key === 'Escape') setEditing(false)
          if (event.key === 'Enter') {
            setEditedDescription(event.target.value)
            setEditing(false)
          }
        }}
        style={{minWidth: 540}}
        variant="standard"
      />
      <IconButton onClick={() => setEditing(false)} size="large">
        <SubmitIcon fontSize="small" color="primary" />
      </IconButton>
      <IconButton
        onClick={() => {
          setEditedDescription(description)
          setEditing(false)
        }}
        size="large"
      >
        <CancelIcon fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <>
      {/* {description && <Typography variant="body2">{description}</Typography>} */}
      {editing ? <EditingMode /> : <StaticMode />}
    </>
  )
}

GallerySummaryAlbumDescriptionField.propTypes = {
  /** Text Description fed from the SDK */
  description: PropTypes.string,
  /** Check on whether user can edit */
  editable: PropTypes.bool,
}

GallerySummaryAlbumDescriptionField.defaultProps = {
  description: '',
  editable: false,
}

export default GallerySummaryAlbumDescriptionField
