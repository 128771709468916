import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Paper, Typography} from '@mui/material'

import useToastNotification from '../hooks/useToastNotification'

import DialogMultiTagRemoveSelectionTable from './DialogMultiTagRemoveSelectionTable'
import {handleSentryError} from '../utilities/sentryErrors'

/**
 * Trigger button and workflows for removing multiple tags to items
 */
function DialogMultiTagRemove(props) {
  const {handleClose, selectedItems, untaggableIds} = props

  const [tagHistogram, setTagHistogram] = useState({})

  const {errorNotification, successNotification, warningNotification} = useToastNotification()

  // Loads a histogram of tags based on selected items
  useEffect(() => {
    async function loadTags() {
      const tagCount = {}

      for await (const item of selectedItems) {
        // Skip the item if a user can't alter it
        if (untaggableIds.indexOf(item.id) === -1) {
          const properties = await item.properties()

          const tags = properties?.tags ?? []

          for (const tag of tags) {
            tagCount[tag] = tagCount[tag] ? tagCount[tag] + 1 : 1
          }
        }
      }
      setTagHistogram(tagCount)
    }

    loadTags()
  }, [selectedItems])

  async function removeTags(tagsForRemoval) {
    let errorsCount = 0
    const removeTagPromises = selectedItems.map(async item => {
      try {
        const metadata = await item.properties()
        const tags = metadata.tags ?? []

        if (tags.length === 0) return
        for (const tagForRemoval of tagsForRemoval) {
          const index = tags.indexOf(tagForRemoval)
          if (index !== -1) tags.splice(index, 1)
        }

        await item.updateMetadata({properties: {tags}})
      } catch (e) {
        const additionalErrorDetails = `Error in updating tags for item ${item.id}.`
        handleSentryError(e, additionalErrorDetails)
        errorsCount++
      }
    })

    await Promise.allSettled(removeTagPromises)

    if (errorsCount === 0) {
      successNotification('All tag removal successful')
    } else if (errorsCount < selectedItems.length) {
      warningNotification('Not all tags were successfully removed')
    } else {
      errorNotification('No tags were succesfully removed')
    }

    setTagHistogram({})
    handleClose()
  }

  return Object.keys(tagHistogram).length > 0 ? (
    <DialogMultiTagRemoveSelectionTable tableHistogram={tagHistogram} removeTags={removeTags} />
  ) : (
    <Paper style={{margin: 10, padding: 20}} level={1}>
      <Typography>No tags were detected for this selection</Typography>
    </Paper>
  )
}

DialogMultiTagRemove.propTypes = {
  /** List of selected items to carry out operations upon */
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Subset of selected items which cannot be tagged */
  untaggableIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DialogMultiTagRemove
