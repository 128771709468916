/**
 * Validates a bounding box on the following conditions:
 *
 * 1. It's not null
 * 2. It has four coordinates
 * 3. It's not null island [0,0,0,0]
 *
 *
 * @param {Array} bbox
 */
function validBoundingBox(bbox) {
  if (!bbox) {
    // console.warn('bounding box not valid - no bbox provided')
    return false
  }

  if (!bbox.length === 4) {
    console.warn('bounding box not valid - requires four coordinates')
    return false
  }

  const set = new Set(bbox)
  // * ![...set][0] -> 0 is falsey, as is null
  if (set.length === 1 && ![...set][0]) {
    console.warn('bounding box not valid - must not be null island')
    return false
  }

  if (set.length === 1) {
    console.warn('bounding box not valid - coordinates should not be the same')
    return false
  }

  return true
}

export default validBoundingBox
