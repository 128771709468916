import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/react'
import {TextField} from '@mui/material'

// Exported for testing
export const orange = `#FFA500`
export const red = `#b00020` // current value for var(--mdc-theme-error)

const acquireValidationColor = warning => (warning ? orange : red)

/**
 * Text input or uploader used for the Imagine API (c3)
 */
function UploaderImagineMetadataFieldText({fieldName, value, error, onChange, warning}) {
  const color = acquireValidationColor(warning)
  const root = css`
    & .Mui-error {
      color: ${color};
    }
    & .MuiFormHelperText-root {
      color: ${color};
    }
    & .MuiInput-underline.Mui-error:after {
      borderbottomcolor: ${color};
    }
  `

  return (
    <TextField
      id={`textfield-${fieldName}`}
      label={fieldName}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={warning ? `(warning) ${error}` : error}
      css={root}
      variant="standard"
    />
  )
}

UploaderImagineMetadataFieldText.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  warning: PropTypes.bool,
}

UploaderImagineMetadataFieldText.defaultProps = {
  warning: false,
  error: '',
  value: '',
}

export default UploaderImagineMetadataFieldText
