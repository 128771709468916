import React from 'react'
import PropTypes from 'prop-types'

import {ButtonBase, Typography} from '@mui/material'
import styled, {css, keyframes} from 'styled-components'

const pulsate = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`

const NavigationButton = styled(ButtonBase)`
  width: 15vw;
  max-width: 350px;
  height: 50px;
  margin-top: 15px !important;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 15px !important;

  background: ${props =>
    props.$disabledStyle ? 'rgba(0, 0, 0, 0.12)' : 'var(--mdc-theme-primary)'} !important;
  color: var(--mdc-theme-on-primary);

  div {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: border 1.35s ease-in-out;
  }

  .MuiTypography-body1 {
    color: ${props =>
      props.$disabledStyle === 'true'
        ? 'var(--mdc-theme-text-disabled-on-light)'
        : 'var(--mdc-theme-on-primary)'} !important;
    font-size: 1.1rem;
  }

  ${props =>
    props.loading === 'true' &&
    css`
      .MuiTypography-root {
        animation-name: ${pulsate};
        animation-duration: 2.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        opacity: 0.5;
      }
    `}
`

/**
 * Button that is used to navigate through items.
 * Pulses while loading
 *
 * @param {function} onClick Action which is executed on click
 * @param {boolean} loading Is the next set of items loading?
 * @param {boolean} disabled Is the button disabled?
 * @param {string} buttonLabel The text that appears on top of the button
 */
function GalleryNavigationButtons(props) {
  const {onClick, loading, disabled, buttonLabel} = props

  return (
    <NavigationButton
      type="button"
      onClick={onClick}
      disabled={loading || disabled}
      loading={String(loading)}
      style={disabled ? {visibility: 'hidden'} : {}}
    >
      <div>
        <Typography variant="body1">{buttonLabel}</Typography>
      </div>
    </NavigationButton>
  )
}

GalleryNavigationButtons.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
}

GalleryNavigationButtons.defaultProps = {
  onClick: () => {},
}

export default GalleryNavigationButtons
