import React from 'react'

import Typography from '@mui/material/Typography'
import styled from 'styled-components'

import ItemMetadataTableRecursive from './ItemMetadataTableRecursive'
import ItemMetadataListGeographyDisplay from './ItemMetadataListGeographyDisplay'
import ItemMetadataSummaryInfo from './ItemMetadataSummaryInfo'
import ItemMetadataAssetList from './ItemMetadataAssetList'
import ItemMetadatThumbnailPreview from './ItemMetadataThumbnailPreview'

import ItemMetadataAlbums from './ItemMetadataAlbums'

import ItemMetadataTags from './ItemMetadataTags'
import ItemMetadataExtensions from './ItemMetadataExtensions'

const MetadataTableSection = styled.section`
  padding-left: 15px !important;
  padding-right: 10px;
  color: var(--mdc-theme-text-primary-on-background);

  dt {
    margin-top: 4px;
  }

  h6 {
    font-size: 15px;
  }
  h5 {
    margin: 10px auto 5px -3px;
    padding-left: 10px;
    background: white;
  }

  .sub-list {
    border-left: 5px solid var(--mdc-theme-primary);
    padding-left: 15px;

    dd > p {
      overflow-wrap: break-word;
    }
  }

  .sub-list-title {
    margin-top: 12px;
  }

  .section-title {
    position: sticky;
    /* Ensures that ItemImageViewer is still able to go on top */
    z-index: 201;
    top: 0px;
    border-bottom: 1px solid #777;
    background: white;
  }

  .section-content {
    margin-right: 20px;
  }

  .disclaimer,
  .subdue {
    color: var(--mdc-theme-text-secondary-on-light);
    font-style: oblique;
    font-size: 0.75rem;
  }

  .cell-fixed-width {
    max-width: 400px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
  }
`

/*
 * The entry point for displaying Item metadata as a table.

 * uses recursion to obtain sub-properties.
*/
function ItemMetadataLayout() {
  return (
    <MetadataTableSection>
      <ItemMetadataSummaryInfo />
      <ItemMetadatThumbnailPreview />
      <ItemMetadataAssetList />
      <ItemMetadataTableRecursive />
      <ItemMetadataExtensions />
      <ItemMetadataAlbums />
      <ItemMetadataTags />

      <ItemMetadataListGeographyDisplay />

      <hr />
      <Typography variant="body1" className="disclaimer">
        Some nested attributes may not be visible. To view, please copy or download the item's
        metadata
      </Typography>
    </MetadataTableSection>
  )
}

export default ItemMetadataLayout
