import {initialSearchParameters} from './advancedSearchContext'
import {SearchAction, SearchActionType} from './SearchAction'
import {SearchParameters} from './SearchParameters'

export function searchReducer(state: SearchParameters, action: SearchAction) {
  const {type} = action
  switch (type) {
    case SearchActionType.UPDATE_VALUES:
      return {...state, ...action.values}
    case SearchActionType.RESET:
      return initialSearchParameters
    default:
      throw new Error(
        `AvancedSearchContext encoundered unknown action type "${type}" with payload: ${JSON.stringify(
          action
        )}.`
      )
  }
}
