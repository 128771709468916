// Old schema taken from non prod since no others seem to exist for sas dpt uav analytics
export const sasDptUavAnalytics = {
  type: 'object',
  id: 'urn:jsonschema:com:planet:staccato:sas_dpt_uav_analytics:Sas_dpt_uav_analyticsItemProperties',
  properties: {
    datetime: {type: 'string'},
    created: {type: 'string'},
    updated: {type: 'string'},
    title: {type: 'string'},
    license: {type: 'string'},
    providers: {
      type: 'array',
      items: {
        type: 'object',
        id: 'urn:jsonschema:com:planet:staccato:model:Provider',
        properties: {
          name: {type: 'string'},
          description: {type: 'string'},
          url: {type: 'string'},
          roles: {
            type: 'array',
            items: {type: 'string', enum: ['licensor', 'producer', 'processor', 'host']},
          },
        },
      },
    },
    'project:name': {type: 'string'},
    'gh:geohash': {type: 'string'},
    tags: {type: 'array', items: {type: 'string'}},
    'exif:data': {type: 'string'},
    'sasdptuav:analytics': {
      type: 'object',
      properties: {
        ccop: {type: 'string'},
        crop: {type: 'string'},
        range: {type: 'integer'},
        sdplt: {type: 'integer'},
        column: {type: 'integer'},
        set_id: {type: 'integer'},
        heading: {type: 'number'},
        plot_id: {type: 'string'},
        entry_id: {type: 'integer'},
        field_id: {type: 'integer'},
        group_id: {type: 'string'},
        is_latest: {type: 'boolean'},
        originator: {type: 'string'},
        ortho_name: {type: 'string'},
        uavidcmask: {
          type: 'object',
          properties: {
            ndvi: {type: 'number'},
            idc_idp: {type: 'number'},
            idc_idp_reason: {type: 'string'},
            uavidcmask_version: {type: 'string'},
          },
        },
        modified_dt: {type: 'string'},
        plot_source: {type: 'string'},
        sub_site_id: {type: 'string'},
        capture_date: {type: 'string'},
        growth_stage: {type: 'string'},
        organization: {type: 'string'},
        tpt_trial_id: {type: 'string'},
        trait_recipe: {type: 'string'},
        uavcottonmat: {
          type: 'object',
          properties: {
            ibop_raw: {type: 'number'},
            white_per: {type: 'number'},
            white_ndvi: {type: 'number'},
            ibop_raw_dl: {type: 'number'},
            mean_ndvi_dl: {type: 'number'},
            white_b_ndvi_dl: {type: 'string'},
            uavcottonmat_version: {type: 'string'},
          },
        },
        uavgaplength: {
          type: 'object',
          properties: {
            gapdst: {type: 'number'},
            gap_cnt: {type: 'integer'},
            max_gap: {type: 'number'},
            row_gapdst: {type: 'array', items: {type: 'number'}},
            row_gap_cnt: {type: 'array', items: {type: 'integer'}},
            row_max_gap: {type: 'array', items: {type: 'number'}},
            counted_rows: {type: 'integer'},
            gap_veg_pcnt: {type: 'number'},
            observed_rows: {type: 'integer'},
            gap_thresh_cnt: {type: 'integer'},
            deactivate_plot: {type: 'boolean'},
            heading_applied: {type: 'number'},
            pixels_per_inch: {type: 'number'},
            row_gap_thresh_cnt: {type: 'array', items: {type: 'integer'}},
            row_gap_percent_veg: {type: 'array', items: {type: 'number'}},
            uavgaplength_version: {type: 'string'},
            plot_deactivation_reason: {type: 'string'},
          },
        },
        uavimageqaqc: {
          type: 'object',
          properties: {
            entropy: {type: 'number'},
            entropy_bin: {type: 'integer'},
            signal_ratio: {type: 'number'},
            confidence_score: {type: 'number'},
            plot_mask_is_outlier: {type: 'boolean'},
            uavimageqaqc_version: {type: 'string'},
          },
        },
        midas_plot_id: {type: 'string'},
        rows_per_plot: {type: 'integer'},
        uavmetricqaqc: {
          type: 'object',
          properties: {
            field_qaqc_msg: {type: 'string'},
            deactivate_ccvr: {type: 'boolean'},
            deactivate_stand: {type: 'boolean'},
            deactivate_gapdst: {type: 'boolean'},
            field_qaqc_status: {type: 'string'},
            deactivate_ccvr_cv: {type: 'boolean'},
            uavmetricqaqc_version: {type: 'string'},
            ccvr_deactivation_reason: {type: 'string'},
            stand_deactivation_reason: {type: 'string'},
            gapdst_deactivation_reason: {type: 'string'},
            ccvr_cv_deactivation_reason: {type: 'string'},
          },
        },
        uavstandcount: {
          type: 'object',
          properties: {
            stand: {type: 'integer'},
            deepstand: {type: 'integer'},
            row_stand: {type: 'array', items: {type: 'integer'}},
            counted_rows: {type: 'integer'},
            stand_reason: {type: 'string'},
            observed_rows: {type: 'integer'},
            row_deepstand: {type: 'array', items: {type: 'integer'}},
            heading_applied: {type: 'number'},
            uavstandcount_version: {type: 'string'},
            deepstand_counted_rows: {type: 'integer'},
          },
        },
        uavuniformity: {
          type: 'object',
          properties: {
            ccvr_cv: {type: 'number'},
            row_ccvr_cv: {type: 'array', items: {type: 'number'}},
            row_1_ccvr_cv: {type: 'number'},
            row_2_ccvr_cv: {type: 'number'},
            row_3_ccvr_cv: {type: 'number'},
            row_4_ccvr_cv: {type: 'number'},
            heading_applied: {type: 'number'},
            uavuniformity_version: {type: 'string'},
          },
        },
        workflow_type: {type: 'string'},
        trait_recipe_id: {type: 'string'},
        uavplotinsetting: {
          type: 'object',
          properties: {uavplotinsetting_version: {type: 'string'}},
        },
        target_seed_count: {type: 'integer'},
        uavcanopycoverage: {
          type: 'object',
          properties: {
            ccvr: {type: 'number'},
            row_ccvr: {type: 'array', items: {type: 'number'}},
            dlmask_iou: {type: 'number'},
            row_1_ccvr: {type: 'number'},
            row_2_ccvr: {type: 'number'},
            row_3_ccvr: {type: 'number'},
            row_4_ccvr: {type: 'number'},
            dlmask_crop: {type: 'string'},
            dlmask_eval: {type: 'string'},
            dlmask_egpcc: {type: 'number'},
            dlmask_thresh: {type: 'number'},
            heading_applied: {type: 'number'},
            geometry_heading: {type: 'number'},
            metric_align_row: {type: 'number'},
            metric_align_width: {type: 'number'},
            metric_align_length: {type: 'number'},
            uavcanopycoverage_version: {type: 'string'},
          },
        },
        uavgeneralmetrics: {
          type: 'object',
          properties: {
            evi_avg: {type: 'number'},
            hue_avg: {type: 'number'},
            nir_avg: {type: 'number'},
            red_avg: {type: 'number'},
            sat_avg: {type: 'number'},
            val_avg: {type: 'number'},
            blue_avg: {type: 'number'},
            ndvi_avg: {type: 'number'},
            vari_avg: {type: 'number'},
            green_avg: {type: 'number'},
            rgbvi_avg: {type: 'number'},
            value_avg: {type: 'number'},
            row_evi_avg: {type: 'array', items: {type: 'number'}},
            row_hue_avg: {type: 'array', items: {type: 'number'}},
            row_nir_avg: {type: 'array', items: {type: 'number'}},
            row_red_avg: {type: 'array', items: {type: 'number'}},
            row_sat_avg: {type: 'array', items: {type: 'number'}},
            evi_avg_mask: {type: 'number'},
            hue_avg_mask: {type: 'number'},
            nir_avg_mask: {type: 'number'},
            red_avg_mask: {type: 'number'},
            row_blue_avg: {type: 'array', items: {type: 'number'}},
            row_ndvi_avg: {type: 'array', items: {type: 'number'}},
            row_vari_avg: {type: 'array', items: {type: 'number'}},
            sat_avg_mask: {type: 'number'},
            val_avg_mask: {type: 'number'},
            blue_avg_mask: {type: 'number'},
            ndvi_avg_mask: {type: 'number'},
            row_1_hue_avg: {type: 'number'},
            row_1_red_avg: {type: 'number'},
            row_1_sat_avg: {type: 'number'},
            row_2_hue_avg: {type: 'number'},
            row_2_red_avg: {type: 'number'},
            row_2_sat_avg: {type: 'number'},
            row_3_hue_avg: {type: 'number'},
            row_3_red_avg: {type: 'number'},
            row_3_sat_avg: {type: 'number'},
            row_4_hue_avg: {type: 'number'},
            row_4_red_avg: {type: 'number'},
            row_4_sat_avg: {type: 'number'},
            row_green_avg: {type: 'array', items: {type: 'number'}},
            row_rgbvi_avg: {type: 'array', items: {type: 'number'}},
            row_value_avg: {type: 'array', items: {type: 'number'}},
            vari_avg_mask: {type: 'number'},
            green_avg_mask: {type: 'number'},
            rgbvi_avg_mask: {type: 'number'},
            row_1_blue_avg: {type: 'number'},
            row_1_vari_avg: {type: 'number'},
            row_2_blue_avg: {type: 'number'},
            row_2_vari_avg: {type: 'number'},
            row_3_blue_avg: {type: 'number'},
            row_3_vari_avg: {type: 'number'},
            row_4_blue_avg: {type: 'number'},
            row_4_vari_avg: {type: 'number'},
            value_avg_mask: {type: 'number'},
            heading_applied: {type: 'number'},
            row_1_green_avg: {type: 'number'},
            row_1_rgbvi_avg: {type: 'number'},
            row_1_value_avg: {type: 'number'},
            row_2_green_avg: {type: 'number'},
            row_2_rgbvi_avg: {type: 'number'},
            row_2_value_avg: {type: 'number'},
            row_3_green_avg: {type: 'number'},
            row_3_rgbvi_avg: {type: 'number'},
            row_3_value_avg: {type: 'number'},
            row_4_green_avg: {type: 'number'},
            row_4_rgbvi_avg: {type: 'number'},
            row_4_value_avg: {type: 'number'},
            row_evi_avg_mask: {type: 'array', items: {type: 'number'}},
            row_hue_avg_mask: {type: 'array', items: {type: 'number'}},
            row_nir_avg_mask: {type: 'array', items: {type: 'number'}},
            row_red_avg_mask: {type: 'array', items: {type: 'number'}},
            row_sat_avg_mask: {type: 'array', items: {type: 'number'}},
            row_blue_avg_mask: {type: 'array', items: {type: 'number'}},
            row_ndvi_avg_mask: {type: 'array', items: {type: 'number'}},
            row_vari_avg_mask: {type: 'array', items: {type: 'number'}},
            row_1_hue_avg_mask: {type: 'number'},
            row_1_red_avg_mask: {type: 'number'},
            row_1_sat_avg_mask: {type: 'number'},
            row_2_hue_avg_mask: {type: 'number'},
            row_2_red_avg_mask: {type: 'number'},
            row_2_sat_avg_mask: {type: 'number'},
            row_3_hue_avg_mask: {type: 'number'},
            row_3_red_avg_mask: {type: 'number'},
            row_3_sat_avg_mask: {type: 'number'},
            row_4_hue_avg_mask: {type: 'number'},
            row_4_red_avg_mask: {type: 'number'},
            row_4_sat_avg_mask: {type: 'number'},
            row_green_avg_mask: {type: 'array', items: {type: 'number'}},
            row_rgbvi_avg_mask: {type: 'array', items: {type: 'number'}},
            row_value_avg_mask: {type: 'array', items: {type: 'number'}},
            row_1_blue_avg_mask: {type: 'number'},
            row_1_vari_avg_mask: {type: 'number'},
            row_2_blue_avg_mask: {type: 'number'},
            row_2_vari_avg_mask: {type: 'number'},
            row_3_blue_avg_mask: {type: 'number'},
            row_3_vari_avg_mask: {type: 'number'},
            row_4_blue_avg_mask: {type: 'number'},
            row_4_vari_avg_mask: {type: 'number'},
            row_1_green_avg_mask: {type: 'number'},
            row_1_rgbvi_avg_mask: {type: 'number'},
            row_1_value_avg_mask: {type: 'number'},
            row_2_green_avg_mask: {type: 'number'},
            row_2_rgbvi_avg_mask: {type: 'number'},
            row_2_value_avg_mask: {type: 'number'},
            row_3_green_avg_mask: {type: 'number'},
            row_3_rgbvi_avg_mask: {type: 'number'},
            row_3_value_avg_mask: {type: 'number'},
            row_4_green_avg_mask: {type: 'number'},
            row_4_rgbvi_avg_mask: {type: 'number'},
            row_4_value_avg_mask: {type: 'number'},
            uavgeneralmetrics_version: {type: 'string'},
          },
        },
        left_adj_plot_id_1: {type: 'integer'},
        left_adj_plot_id_2: {type: 'integer'},
        plot_length_inches: {type: 'number'},
        row_spacing_inches: {type: 'number'},
        uavcanolaflowering: {
          type: 'object',
          properties: {
            yellow_per: {type: 'number'},
            yellow_b_cc: {type: 'number'},
            uavcanolaflowering_version: {type: 'string'},
          },
        },
        uavleafdamageindex: {
          type: 'object',
          properties: {ldi: {type: 'number'}, uavleafdamageindex_version: {type: 'string'}},
        },
        uavsoyrmprediction: {
          type: 'object',
          properties: {
            rm_comments: {type: 'string'},
            rm_use_date: {type: 'boolean'},
            rm_use_plot: {type: 'boolean'},
            rm_plot_used: {type: 'boolean'},
            rm_use_lc_ts: {type: 'boolean'},
            rm_prediction: {type: 'number'},
            rm_qc_category: {type: 'string'},
            rm_qc_pass_flag: {type: 'boolean'},
            rm_pred_variance: {type: 'number'},
            rm_plot_is_ndvi_increasing: {type: 'boolean'},
            uavsoyrmprediction_version: {type: 'string'},
            rm_lc_ts_is_ndvi_increasing: {type: 'boolean'},
            rm_confidence_max_85_percent: {type: 'number'},
            rm_confidence_max_90_percent: {type: 'number'},
            rm_confidence_min_85_percent: {type: 'number'},
            rm_confidence_min_90_percent: {type: 'number'},
          },
        },
        planting_session_id: {type: 'string'},
        right_adj_plot_id_1: {type: 'integer'},
        right_adj_plot_id_2: {type: 'integer'},
        uavlidarplantheight: {
          type: 'object',
          properties: {
            pht: {type: 'number'},
            pht_max: {type: 'number'},
            row_pht: {type: 'array', items: {type: 'number'}},
            lu_reason: {type: 'string'},
            lu_thresh: {type: 'array', items: {type: 'number'}},
            pht_reason: {type: 'string'},
            lu_gap_perc: {type: 'number'},
            lu_thresh_1: {type: 'number'},
            lu_thresh_2: {type: 'number'},
            lu_thresh_3: {type: 'number'},
            lu_thresh_4: {type: 'number'},
            row_pht_max: {type: 'array', items: {type: 'number'}},
            lu_emer_perc: {type: 'number'},
            row_lu_thresh: {type: 'array', items: {type: 'array', items: {type: 'number'}}},
            deactivate_pht: {type: 'boolean'},
            lu_gap_in_rows: {type: 'string'},
            heading_applied: {type: 'number'},
            row_lu_gap_perc: {type: 'array', items: {type: 'number'}},
            row_lu_emer_perc: {type: 'array', items: {type: 'number'}},
            lu_lt_thresh_perc: {type: 'array', items: {type: 'number'}},
            lu_perc_lt_thresh_1: {type: 'number'},
            lu_perc_lt_thresh_2: {type: 'number'},
            lu_perc_lt_thresh_3: {type: 'number'},
            lu_perc_lt_thresh_4: {type: 'number'},
            row_lu_lt_thresh_perc: {type: 'array', items: {type: 'array', items: {type: 'number'}}},
            row_lu_wrt_sdplt_perc: {type: 'array', items: {type: 'array', items: {type: 'number'}}},
            uavlidarplantheight_version: {type: 'string'},
          },
        },
        harvest_rows_per_plot: {type: 'integer'},
        planted_length_inches: {type: 'number'},
      },
    },
  },
}
