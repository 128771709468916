import React, {useContext, useEffect, useRef} from 'react'

import DirectoryLayout from '../layouts/DirectoryLayout'
import CommonLayout from '../layouts/CommonLayout'
import {imagineServiceContext} from '../context/imagineServiceContext'

import useDirectoryPageState from '../hooks/useDirectoryPageState'
import {handleSentryError} from '../utilities/sentryErrors'
import {asyncThrottle} from '../utilities/async'
import getItemThumbnailUrl from '../utilities/getItemThumbnailUrl'

const DIRECTORY_PAGE_ALBUMS_CACHE_KEY = `DIRECTORY_PAGE_ALBUMS_CACHE_KEY`

/**
 * Data loading for albums
 */
function DirectoryPageAlbums() {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    retrieveAlbums,
  } = useContext(imagineServiceContext)

  const {
    state,
    dispatch,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  } = useDirectoryPageState()

  const mounted = useRef(true)
  const abortController = useRef(new AbortController())
  const {filter} = state

  useEffect(function () {
    mounted.current = true
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
      abortController?.current?.abort()
    }
  }, [])

  // Generates a list of parseable albums
  useEffect(() => {
    const gatherAlbumElements = async () => {
      let albumsArray
      let cachedData

      try {
        cachedData = window.localStorage.getItem(DIRECTORY_PAGE_ALBUMS_CACHE_KEY)
        if (cachedData) {
          cachedData = JSON.parse(cachedData)
          dispatch({
            type: 'set-max-count',
            maxCount: cachedData.cnt,
          })
          if (mounted.current) {
            setData(cachedData.albumElements)
            incrementNumberItemsPopulated()
          }
        }

        albumsArray = await retrieveAlbums()
      } catch (e) {
        const additionalErrorDetails =
          'There was a problem connecting to Imagine. If this issue persists, contact Location360 Support.'
        handleSentryError(e, additionalErrorDetails)
        console.warn('Initial load error with albums')
        if (mounted.current) {
          setError(e)
        }

        return
      }

      try {
        const albumElements = []

        if (albumsArray.length > 0 && mounted.current)
          dispatch({
            type: 'set-max-count',
            maxCount: albumsArray.length,
          })

        // eslint-disable-next-line
        async function expandalbumsArray(album, index) {
          const getEntitlements = await album.entitlements().catch(e => {
            const additionalErrorDetails = `There was an error fetching entitlements for ${album.id}.`
            handleSentryError(e, additionalErrorDetails)
          })
          const userEntitlements = []
          if (getEntitlements.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
          if (getEntitlements.currentUserHasEntitlement('DELETE')) userEntitlements.push('DELETE')
          if (getEntitlements.currentUserHasEntitlement('PUBLISH')) userEntitlements.push('PUBLISH')
          if (getEntitlements.currentUserHasEntitlement('DOWNLOAD'))
            userEntitlements.push('DOWNLOAD')

          const albumDisplayElement = {
            name: album.id,
            title: album.title,
            entitlements: userEntitlements,
            description: album.description || '',
            thumbnail: null,
            createdBy: album.createdBy,
            count: await album.itemCount().catch(e => {
              const additionalErrorDetails = `Unable to retrieve count for collection "${album.title}".`
              handleSentryError(e, additionalErrorDetails)
            }),
          }

          const numDesiredItems = 1
          const itemsIterator = await album?.fetchItems({limit: numDesiredItems})
          for await (const item of itemsIterator) {
            albumDisplayElement.thumbnail = getItemThumbnailUrl(item)
          }

          albumElements.push(albumDisplayElement)

          if (!cachedData && mounted.current) {
            setData(albumElements)
            incrementNumberItemsPopulated()
          }
        }

        const albumQueryFns = albumsArray.map(album => {
          return () => expandalbumsArray(album)
        })
        const albumQueries = await asyncThrottle(albumQueryFns, {
          limit: 20,
          abortController: abortController.current,
          checkDelay: 20,
        })
        Promise.allSettled(albumQueries)
          .then(() => {
            if (mounted.current) {
              setData(albumElements)
              itemPopulatingComplete()
              window.localStorage.setItem(
                DIRECTORY_PAGE_ALBUMS_CACHE_KEY,
                JSON.stringify({
                  cnt: albumsArray.length,
                  albumElements,
                })
              )
            }
          })
          .catch(e => handleSentryError(e))
      } catch (e) {
        if (mounted.current) {
          const additionalErrorDetails =
            'There was an error in loading albums. Please contact Location360 Support.'
          handleSentryError(e, additionalErrorDetails)
          setError(e)
        }
      }
    }

    if (!(globalLoading || globalLoadingError) && mounted.current) gatherAlbumElements()
  }, [globalLoading, globalLoadingError])

  const filteredContent = state.data.filter(
    ({name, title}) =>
      title?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase()) ||
      name?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase())
  )

  return (
    <CommonLayout>
      <DirectoryLayout
        filteredContent={filteredContent}
        loading={state.loading}
        error={state.error || globalLoadingError}
        populating={state.itemsPopulated < state.maxCount}
        numPossibleElements={state.data.length}
        maxCount={state.maxCount}
        contentType="albums"
      />
    </CommonLayout>
  )
}

export default DirectoryPageAlbums
