import styled from 'styled-components'

const DropArea = styled('section')`
  align-items: center;
  background-color: #fff;
  border: dashed;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  font-size: 20px;
  justify-content: center;
  position: relative;
  user-select: none;
  width: 100%;

  transition: border 0.35s ease-in-out;
  &:hover {
    border-color: #888;
  }

  input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .icon-container {
    height: 50px;
  }
`

export default DropArea
