import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {ImageListItem, Tooltip} from '@mui/material'
import {grey, orange} from '@mui/material/colors'
import {
  AddPhotoAlternate as AddPhotoAlternateIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import DialogDeleteItemGalleryPage from './DialogDeleteItemGalleryPage'
import DialogRemoveItemFromAlbumGallery from './DialogRemoveItemFromAlbumGallery'
import DialogAssetDownload from './DialogAssetDownload'
import CardGalleryViewItemCustomList from './CardGalleryViewItemCustomList'
import FallbackImage from './FallbackImage'
import StyledIconButton from './styled/StyledIconButton'

import {galleryItemContext} from '../context/galleryItemContext'
import {galleryPageContext} from '../context/galleryPageContext'

const StyledImageListItem = styled(ImageListItem)`
  width: ${props => props.$width}px;
  height: ${props => props.$width}px !important;
  min-height: ${props => props.$width}px !important;

  border: 2px solid ${() => grey['200']};

  &:hover {
    border: 2px solid ${() => orange['200']};
  }

  transition: border 0.35s ease-in-out;
  overflow: hidden;
  font-size: 1rem;

  .tile-bottom {
    position: relative;
    bottom: 49px;
    width: calc(${props => props.$width}px - 4px);
    height: ${props => props.$width}px;
    min-height: ${props => props.$width}px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 400;
    font-size: 90%;
    line-height: 1.2;
    transition: bottom 0.35s ease-in-out, background-color 0.35s ease-in-out,
      color 0.35s ease-in-out;

    display: grid;
    grid-template-rows: 1fr 35px;
  }

  .tile-bottom-information {
    display: grid;
    grid-template-rows: 45px 1fr;
    color: white !important;
    transition: color 0.35s ease-in-out;
    height: calc(${props => props.$width}px - 45px);
    overflow-y: scroll;
  }

  .tile-bottom-icons {
    border-top: 1px solid #222;
    height: 34px;
    width: ${props => props.$width}px;

    display: flex;
    justify-content: space-evenly;
    margin: 0;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
  }

  ${props =>
    props.$pin === 'true' &&
    css`
      .tile-bottom {
        background-color: rgba(255, 255, 255, 0.8);
        color: #222 !important;
        bottom: ${props => props.$width}px;
      }

      .tile-bottom-information {
        color: #222 !important;
      }
    `};

  .title {
    font-size: 1rem;
    line-height: 24px;
    white-space: nowrap;
    font-weight: 400;
    letter-spacing: 0.01071em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  a {
    font-size: 1.1rem;
  }
`

/**
 * Displays an item on the gallery page when multiple select is disengaged
 */
function CardGalleryViewItem(props) {
  const {addItemToAlbum, canRemoveAlbumItem, width, fullSearchTerms} = props
  const {visibleSchemaElements, itemsPinned} = useContext(galleryPageContext)
  const {thumbnail, href, title, subtitle, collectionName, properties} =
    useContext(galleryItemContext)

  // ? This is a band-aid to an unknown effect that prevents render of custom properties
  const [keys, setKeys] = useState([])

  useEffect(() => {
    const visibleCollections = Object.keys(visibleSchemaElements)
    if (visibleCollections.indexOf(collectionName) !== -1) {
      setKeys(visibleSchemaElements[collectionName])
    }
  }, [visibleSchemaElements])

  const LinkWrapper = ({children, active}) =>
    active ? <Link to={href}>{children}</Link> : <>{children}</>

  const buildSearchTooltipText = fullSearchTerms => {
    const keysArray = []
    for (const searchTerm of fullSearchTerms) {
      for (const [key, value] of Object.entries(properties)) {
        if (value.toString().includes(searchTerm)) {
          keysArray.push(`${key}: ${value}`)
        }
      }
    }

    return keysArray.join('\n')
  }

  return (
    <LinkWrapper active={!itemsPinned}>
      <StyledImageListItem className="gallery-view-tile" $width={width} $pin={String(itemsPinned)}>
        <FallbackImage src={thumbnail} width={width} />
        <div className="tile-bottom">
          <LinkWrapper active={itemsPinned}>
            <div className="tile-bottom-information">
              <div>
                <div className="title">{title}</div>
                <div className="subtitle">{subtitle}</div>
              </div>

              <CardGalleryViewItemCustomList schemaKeys={keys} />
            </div>
          </LinkWrapper>
          <div className="tile-bottom-icons">
            {canRemoveAlbumItem && <DialogRemoveItemFromAlbumGallery />}
            {addItemToAlbum && (
              <Tooltip title="Add Item to Album">
                <span>
                  <StyledIconButton
                    aria-label="add item to album"
                    onClick={addItemToAlbum}
                    size="small"
                    edge="end"
                    color="primary"
                  >
                    <AddPhotoAlternateIcon />
                  </StyledIconButton>
                </span>
              </Tooltip>
            )}

            <DialogDeleteItemGalleryPage />

            <DialogAssetDownload />

            {fullSearchTerms.length > 0 && (
              <Tooltip
                title={
                  <span style={{whiteSpace: 'pre-line'}}>
                    {buildSearchTooltipText(fullSearchTerms)}
                  </span>
                }
              >
                <span>
                  <StyledIconButton
                    aria-label="search match criteria"
                    size="small"
                    edge="end"
                    color="primary"
                  >
                    <HelpOutlineIcon />
                  </StyledIconButton>
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </StyledImageListItem>
    </LinkWrapper>
  )
}

CardGalleryViewItem.propTypes = {
  width: PropTypes.number.isRequired,
  canRemoveAlbumItem: PropTypes.bool,
  addItemToAlbum: PropTypes.func,
  fullSearchTerms: PropTypes.string,
}

CardGalleryViewItem.defaultProps = {
  thumbnail: '',
  canRemoveAlbumItem: false,
  addItemToAlbum: null,
  fullSearchTerms: '',
}

export default CardGalleryViewItem
