/**
 *
 * List of operators used in the Imagine JS SDK
 *
 * See https://github.platforms.engineering/pages/location-360/imagine-javascript-sdk/globals.html#itemsearchcomparison
 * for list of current operators
 */

const operators = [
  {value: '>=', usedWith: ['number'], description: 'Greater or equal to', enum: 'GREATER_THAN_OR_EQUAL_TO'},
  {value: '<=', usedWith: ['number'], description: 'Less or equal to', enum: 'LESS_THAN_OR_EQUAL_TO'},
  {value: '>', usedWith: ['number'], description: 'Greater than', enum: 'GREATER_THAN'},
  {value: '<', usedWith: ['number'], description: 'Less than', enum: 'LESS_THAN'},
  {value: '==', usedWith: ['number', 'text', 'boolean'], description: 'Equal to', enum: 'EQUAL_TO'},
  {value: '!=', usedWith: ['number', 'text'], description: 'Not equal to', enum: 'NOT_EQUAL_TO'},
  {value: '!', usedWith: ['number', 'text', 'boolean'], description: 'Is null', enum: 'isNull'},
]

export default operators
