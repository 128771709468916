import React, {useContext} from 'react'
import {Typography, Table, TableBody, TableRow, TableCell} from '@mui/material'
import {itemContext} from '../context/itemContext'
import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'

function ItemMetadataExtensions() {
  const {
    itemMetadata: {extensions},
  } = useContext(itemContext)

  return (
    <>
      <ItemMetadataHeaderAccordion header="Extensions">
        <Table>
          <TableBody>
            {extensions?.length > 0 ? (
              extensions.map(extension => (
                <TableRow key={`table-extension-${extension}`}>
                  <TableCell>{extension}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{color: '#888', fontStyle: 'italic'}}>
                  (No STAC extensions were enabled for this item&apos;s collection )
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default ItemMetadataExtensions
