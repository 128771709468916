import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {TableCell, TextField, TableRow, IconButton} from '@mui/material'
import {blue, grey} from '@mui/material/colors'
import {Cancel} from '@mui/icons-material'

import styled from 'styled-components'

const StyledTableRow = styled(TableRow)`
  user-select: none;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
  height: 45px;

  background-color: ${props => (props.$selected ? blue[300] : 'inherit')};
  color: ${props => (props.$disabled ? grey[200] : 'inherit')};

  .MuiTableCell-root {
    padding: 3px 10px;
  }

  .MuiIconButton-root {
    padding: 8px 12px;
  }

  .MuiInputBase-root .MuiInput-root {
    width: 450px;
  }

  .cancel-alteration-cell {
    width: 68px;
  }

  .tag-cell {
    width: 450px;
  }

  .count-cell {
    width: 45px;
  }

  &:hover {
    background-color: ${props => (props.$selected ? blue[200] : grey[200])};
  }
`

/**
 * Table row that allows for tagging
 */
function DialogEditTagsSelectionTableRow(props) {
  const {originalValue, alteredValue, count, alterValue, editing, setEditing} = props

  const [tempValue, setTempValue] = useState(alteredValue || originalValue)

  function saveValue() {
    setEditing(false)
  }

  function handleEnterOrEscape(event) {
    if (event.key === 'Enter') saveValue()
  }

  // Save value when editing set to false. Processes parent to this component can turn off editing here
  useEffect(() => {
    if (!editing) alterValue({originalValue, alteredValue: tempValue})
  }, [editing])

  return (
    <StyledTableRow key={originalValue}>
      <TableCell className="cancel-alteration-cell">
        {alteredValue && (
          <IconButton
            onClick={() => alterValue({originalValue, alteredValue: null})}
            size="large">
            <Cancel />
          </IconButton>
        )}
      </TableCell>
      <TableCell className="tag-cell" onClick={() => setEditing(true)}>
        {editing ? (
          <TextField
            originalValue={alteredValue || originalValue}
            onKeyUp={handleEnterOrEscape}
            onChange={event => setTempValue(event.target.value)}
            value={tempValue}
            fullWidth
            autoFocus
          />
        ) : (
          <>{alteredValue || originalValue}</>
        )}
      </TableCell>
      <TableCell className="count-cell" onClick={() => setEditing(true)}>
        {count}
      </TableCell>
    </StyledTableRow>
  );
}

DialogEditTagsSelectionTableRow.propTypes = {
  /** Value of the tag before editing */
  originalValue: PropTypes.string.isRequired,
  /** The value based on the alteration */
  alteredValue: PropTypes.string,
  /** The number of items in the selection that use the tag */
  count: PropTypes.number.isRequired,
  /** Actions on when the tag value is selected */
  alterValue: PropTypes.func.isRequired,
}

DialogEditTagsSelectionTableRow.defaultProps = {
  alteredValue: '',
}

export default DialogEditTagsSelectionTableRow
