import useWindowSize from './useWindowSize'

function useGalleryCardWidth() {
  const {width} = useWindowSize()

  const maxCardWidthPx = 240

  const numberColumns = parseInt(width ? width / maxCardWidthPx : 5)
  const cardWidth = parseInt(
    width ? (width - 10 * (numberColumns + 1)) / numberColumns : maxCardWidthPx
  )

  return {numberColumns, cardWidth}
}

export default useGalleryCardWidth
