import React, {useState} from 'react'
import styled from 'styled-components'
import {Button, Paper, Typography} from '@mui/material'

import {Info as InfoIcon, PersonOutline as PersonIcon} from '@mui/icons-material'

import GallerySummaryAlbumDescriptionField from './GallerySummaryAlbumDescriptionField'
import GallerySummaryAlbumTitleField from './GallerySummaryAlbumTitleField'
import DialogDeleteAlbum from './DialogDeleteAlbum'

const SummaryContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;

  grid-template-rows: 28px 25px 25px;
  grid-template-columns: 35px 1fr 150px 135px;
  grid-column-gap: 15px;

  .icon {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    display: flex;

    color: var(--mdc-theme-primary);

    justify-self: center;
    align-self: center;
  }

  .title-row {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    font-weight: 300;
    letter-spacing: 1.2px;

    display: flex;
    align-items: center;
  }

  .description {
    grid-row: 2 / 3;
    grid-column: 2 / 4;

    color: ${props => props.theme?.palette?.subduedText};

    display: flex;
    flex-direction: row;

    align-items: center;
  }

  .creator-info {
    grid-column: 2 / 3;

    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      margin-left: 3px;
    }
  }

  .triggers {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    display: flex;
    justify-content: flex-end;
  }

  .export-trigger {
    grid-column: 4 / 5, 3 / 4;
  }

  .export-trigger,
  .delete-trigger {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: flex-end;
    align-content: center;
  }

  .delete-trigger {
    color: var(--mdc-theme-error) !important;
  }
  .dialog-trigger {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
    justify-self: flex-end;
    align-self: center;
  }

  button {
    grid-column: 2 / 3;
  }

  p {
    margin-right: 5px;
  }
`

/**
 * The summary of results for a given album.
 */
function GallerySummaryAlbum(props) {
  const {album, canAlter, count, description, title, triggerExportDialog, openSchemaDialog} = props

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const UsernameIcon = ({color}) => (
    <PersonIcon fontSize="small" color={color} style={{marginRight: '3px'}} />
  )

  return (
    <>
      {deleteDialogOpen && (
        <DialogDeleteAlbum
          album={album}
          title={title}
          handleClose={() => setDeleteDialogOpen(false)}
        />
      )}
      <SummaryContainer elevation={0}>
        <div className="icon">
          <InfoIcon />
        </div>
        <div className="title-row">
          <GallerySummaryAlbumTitleField title={title} count={count} editable={canAlter} />
        </div>
        <div className="description">
          <GallerySummaryAlbumDescriptionField description={description} editable={canAlter} />
        </div>
        <div className="creator-info">
          <UsernameIcon color={canAlter ? 'primary' : 'inherit'} />
          <Typography variant="overline">{album.createdBy}</Typography>
        </div>

        <div className="triggers">
          <Button onClick={triggerExportDialog} color="primary" className="export-trigger">
            Export Album
          </Button>

          {canAlter && (
            <Button
              onClick={() => setDeleteDialogOpen(true)}
              disabled={!canAlter}
              className="delete-trigger"
            >
              Delete Album
            </Button>
          )}
        </div>

        <div className="dialog-trigger">
          <Button onClick={openSchemaDialog}>View and Select Schema</Button>
        </div>
      </SummaryContainer>
    </>
  )
}

export default GallerySummaryAlbum
