export type Bbox = [number, number, number, number]

export type SearchProperty = {property: any; comparison: any; value: any}

export enum SearchKeywordOperator {
  INCLUDES = '',
  MUST_INCLUDE = '+',
  MUST_NOT_INCLUDE = '-',
}

export type SearchKeyword = {operator: SearchKeywordOperator; value: any}

export type SearchParameters = {
  bbox?: Bbox
  collections?: string[]
  projects?: string[]
  properties?: SearchProperty[]
  keywords?: SearchKeyword[]
  tags?: string[]
  fromDate?: string
  toDate?: string
}
