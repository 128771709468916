import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {ImageListItem, ImageListItemBar, Checkbox} from '@mui/material'
import {grey, orange} from '@mui/material/colors'
import styled from 'styled-components'

import {galleryItemContext} from '../context/galleryItemContext'
import FallbackImage from './FallbackImage'

const StyledGridTile = styled(ImageListItem)`
  width: ${props => props.width}px;
  height: ${props => props.width}px !important;
  border: 2px solid ${() => grey['200']};

  transition: border 0.35s ease-in-out;

  &:hover {
    border: 2px solid ${() => orange['200']};
  }
`

const ImageListItemBottom = styled(ImageListItemBar)`
  height: auto !important;
  background: rgba(0, 0, 0, 0.7) !important;
  user-select: none !important;

  padding: 0 0.2em 0.3em 0.4em;
  & > .MuiImageListItemBar-titleWrap {
    margin: 0 0.2rem 0 0;
    padding: 0;
    > .MuiGImageListItemBar-subtitle {
      font-size: 90%;
      line-height: 1.2;
      opacity: 0.9;
    }
  }
`

const ImageListItemSelectTileTop = styled(ImageListItemBar)`
  background: transparent !important;
`

/**
 * Displays a gallery grid item. visible only when multiple select is engaged
 */
function CardGalleryViewItemMultipleSelect(props) {
  const {onToggle, width, selected} = props

  const {subtitle, thumbnail, title} = useContext(galleryItemContext)

  return (
    <StyledGridTile
      selected={selected}
      width={width}
      className="gallery-view-tile"
      onClick={onToggle}
    >
      <div>
        <ImageListItemSelectTileTop
          position="top"
          actionPosition="right"
          actionIcon={<Checkbox checked={selected} color="primary" />}
        />
        <ImageListItemBottom title={<span>{title}</span>} subtitle={<span>{subtitle}</span>} />
        <FallbackImage src={thumbnail} />
      </div>
    </StyledGridTile>
  )
}

CardGalleryViewItemMultipleSelect.propTypes = {
  /** Action taken when item is selected in the context of multiple select */
  onToggle: PropTypes.func.isRequired,
  /** Is the item selected? */
  selected: PropTypes.bool,
  /** The Subtitle. Optional*/
  subtitle: PropTypes.string,
  /** The title of the item. Optional.  */
  title: PropTypes.string,
  /** The width of the card. Feeds into the styled-component for optimization */
  width: PropTypes.number.isRequired,
}

CardGalleryViewItemMultipleSelect.defaultProps = {
  selected: false,
  subtitle: '',
  title: '',
}

export default CardGalleryViewItemMultipleSelect
