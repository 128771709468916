import React, {useContext, useEffect, useState} from 'react'
import {saveAs} from 'file-saver'
import styled from 'styled-components'
import {Button, Paper, Typography, Tooltip, IconButton} from '@mui/material'
import {
  CloudDownload as CloudDownloadIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  AddPhotoAlternate as AddToAlbumIcon,
} from '@mui/icons-material'

import {itemContext} from '../context/itemContext'

import DialogAddToAlbum from '../components/DialogAddToAlbum'
import CardGenericError from '../components/CardGenericError'
import ItemImageViewer from '../components/ItemImageViewer'
import ItemMetadataLayout from '../components/ItemMetadataLayout'
import ItemLayoutCopyButton from '../components/ItemLayoutCopyButton'
import ItemLayoutDeleteButton from '../components/ItemLayoutDeleteButton'
import ItemGeometryDisplay from '../components/ItemGeometryDisplay'
import {useNavigate} from 'react-router-dom'

const StyledIconButton = styled(IconButton)`
  color: ${props =>
    props.disabledcolor === 'true'
      ? 'var(--mdc-theme-text-disabled-on-background) !important'
      : 'var(--mdc-theme-primary) !important'};
`

const ButtonTitle = styled(Typography)`
  margin-left: 10px;
  margin-bottom: 8px !important;
`

const ItemInformationSection = styled.section`
  display: grid;

  // Height minus searchbar minus breadcrumbs
  height: calc(95vh - 120px - 20px);
  grid-template-rows: 50px 155px 1fr 1fr 60px 36px 36px;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: 1fr minmax(300px, 680px) 1fr minmax(300px, 560px) 1fr;
  overflow: hidden;
  padding-bottom: 10px;

  .item-metadata-information {
    grid-column: 2;
    grid-row: 1 / 8;
    overflow-y: scroll;
    padding: 0 5px 5px 5px;
  }

  .metadata-section {
    grid-column: 4;
    grid-row: 1 / 4;

    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }

    .button-row {
      display: grid;

      grid-template-columns: 1fr 1fr;
      padding: 0 10px;
      grid-column-gap: 8px;

      .tooltip-element {
        width: 100%;
        height: 100%;
      }
    }
  }

  .item-loading-errors {
    grid-column: 4;
    grid-row: 4 / 5;
    margin-top: 10px;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .item-user-entitlements {
    grid-column: 4;
    grid-row: 5 / 6;
    padding: 10px;
    align-self: center;

    h5 {
      text-align: center;
    }

    .user-entitlement-row {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .add-item-to-album-button {
    background: white;
    grid-column: 4;
    grid-row: 6 / 7;
  }

  .delete-item-button {
    grid-column: 4;
    grid-row: 7 / 8;
  }

  .retrieval-pane {
    margin-bottom: 10px;
    padding: 5px;
  }
`

function ItemPageLayout() {
  const {
    itemMetadata: {error: globalError, ...itemAttributes},
    itemAssetViewerLocation,
    setItemAssetViewerLocation,
  } = useContext(itemContext)
  const navigate = useNavigate()

  const {permissions, geometry, project, collection, id, item, metadata, viewableAssets} =
    itemAttributes

  const [addToAlbumDialog, setAddToAlbumDialog] = useState(false)
  const [error, setError] = useState(null)

  // Coalesce errors
  useEffect(() => {
    setError(error)
  }, [globalError])

  const triggerError = message => {
    setItemAssetViewerLocation(null)
    setError(message)
  }

  const sharingMetadata = {
    id,
    project,
    collection,
    geometry,
    ...metadata,
  }

  const downloadItemMetadata = () => {
    saveAs(
      new Blob([JSON.stringify(sharingMetadata)], {
        type: 'text/plain;charset=utf-8',
      }),
      `metadata-${project}-${id}.json`
    )
  }

  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const params = new URLSearchParams(currentURL.search)
    const currentAssetId = params.get('preview')
    if (!itemAssetViewerLocation && currentAssetId) {
      params.delete('preview')
      navigate(`?${params}`)
    }
  }, [itemAssetViewerLocation])

  return (
    <>
      {addToAlbumDialog && (
        <DialogAddToAlbum handleClose={() => setAddToAlbumDialog(false)} items={[item]} />
      )}
      <ItemInformationSection>
        <Paper className="item-metadata-information">{metadata && <ItemMetadataLayout />}</Paper>
        <div className="metadata-section">
          <Paper elevation={1} className="retrieval-pane">
            <ButtonTitle variant="h5">Retrieve Metadata</ButtonTitle>
            <div className="button-row">
              <ItemLayoutCopyButton metadata={sharingMetadata}>Copy</ItemLayoutCopyButton>

              <Button
                onClick={downloadItemMetadata}
                variant="outlined"
                color="primary"
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </Button>
            </div>
          </Paper>

          <ItemGeometryDisplay geometry={geometry} />
        </div>

        {/* ! Displays error card in event of data loading error  */}
        {error && (
          <div className="item-loading-errors">
            <CardGenericError
              title={
                /You do not have permission/.test(error) ? 'Insufficient entitlement' : 'Warning'
              }
              description={error}
              caption={
                /You do not have permission/.test(error)
                  ? 'If you require this entitlement to carry out your work, please contact Location360 Support.'
                  : 'If believe this is an error, please contact Location360 Support.'
              }
              noMargin
              level={/You do not have permission/.test(error) ? 'warning' : 'error'}
            />
          </div>
        )}

        <Paper className="item-user-entitlements" level={1}>
          <div className="user-entitlement-row">
            <Typography variant="body1">User Entitlements</Typography>
            <Tooltip
              title={permissions.download ? 'Can download' : 'Cannot download'}
              style={{gridColumn: '4 / 5'}}
            >
              <StyledIconButton disabledcolor={permissions.download ? 'false' : 'true'}>
                <CloudDownloadIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip
              title={permissions.publish ? 'Can edit' : 'Cannot edit'}
              style={{gridColumn: '5 / 6'}}
            >
              <StyledIconButton disabledcolor={permissions.publish ? 'false' : 'true'}>
                <EditIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip
              title={permissions.delete ? 'Can delete' : 'Cannot delete'}
              style={{gridColumn: '5 / 6'}}
            >
              <StyledIconButton disabledcolor={permissions.delete ? 'false' : 'true'}>
                <DeleteForeverIcon />
              </StyledIconButton>
            </Tooltip>
          </div>
        </Paper>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddToAlbumIcon />}
          onClick={() => setAddToAlbumDialog(true)}
          className="add-item-to-album-button"
        >
          Add Item to Album
        </Button>
        <div className="delete-item-button">
          <ItemLayoutDeleteButton />
        </div>
      </ItemInformationSection>

      {itemAssetViewerLocation && (
        <ItemImageViewer
          itemAttributes={itemAttributes}
          imageAsset={itemAssetViewerLocation}
          triggerError={triggerError}
          closeDrawer={() => {
            setItemAssetViewerLocation(null)
          }}
          permissions={permissions}
          viewableAssets={viewableAssets}
        />
      )}
    </>
  )
}

export default ItemPageLayout
