import React from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'

import useToastNotification from '../hooks/useToastNotification'

/**
 * A "yes/no" dialog that allows users to delete their albums
 */
function DialogDeleteAlbum(props) {
  const {album, title, handleClose} = props
  const {errorNotification, successNotification} = useToastNotification()
  const navigate = useNavigate()

  function handleDelete() {
    album
      .delete()
      .then(() => {
        successNotification(`The Album "${title}" has been deleted`)
        navigate('/?section=albums', {replace: true})
      })
      .catch(e => {
        console.error(e)
        errorNotification(`Unable to delete album "${title}"`)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Album</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          cancel
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDeleteAlbum.propTypes = {
  /** The Imagine SDK Album type */
  album: PropTypes.any.isRequired, // eslint-disable-line
  /** The readble name of the album */
  title: PropTypes.string.isRequired,
  /** The function that closes the dialog */
  handleClose: PropTypes.func.isRequired,
}

export default DialogDeleteAlbum
