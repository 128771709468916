import React from 'react'
import PropTypes from 'prop-types'
import {ImageList, Paper, Typography} from '@mui/material'
import styled from 'styled-components'
import {GalleryItemProvider} from '../context/galleryItemContext'
import CardGalleryViewItem from './CardGalleryViewItem'
import useGalleryCardWidth from '../hooks/useGalleryCardWidth'

const SectionPaper = styled(Paper)`
  padding: 10px;
`

const Container = styled.div`
  margin-top: 5px;
`

function BasicSearchSectionExactMatch(props) {
  const {exactMatch} = props

  const {cardWidth} = useGalleryCardWidth()

  return (
    <SectionPaper elevation="0">
      <Typography variant="h5" color="primary">
        Exact Match
      </Typography>
      <Container>
        <GalleryItemProvider item={exactMatch}>
          <ImageList>
            <CardGalleryViewItem width={(cardWidth / 3) * 2} />
          </ImageList>
        </GalleryItemProvider>
      </Container>
    </SectionPaper>
  )
}

export default BasicSearchSectionExactMatch
