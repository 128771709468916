import React, {memo} from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import {red} from '@mui/material/colors'

import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'

import {Button} from '@mui/material'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import styled, {css} from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import useToastNotification from '../hooks/useToastNotification'
import {useTheme} from '@emotion/react'

const StyledCard = styled(Card)`
  width: 700px;
  margin: ${props => props.top} auto 0 auto;
  padding: 20px;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  display: grid;
  grid-template-rows: 2rem 1fr ${props => (props.$extraRow === 'true' ? '1fr' : '')};
  grid-template-columns: 40px 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;

  .icon {
    align-self: center;
    justify-self: center;
    grid-row: 1 / 3;
  }

  .title,
  .copy {
    grid-column: 2 / 3;
  }

  .title {
    justify-self: center;
    padding-right: 20px;
  }

  p.caption {
    margin-top: 1rem;
    text-align: center;
  }
`

const AvatarIcon = ({level}) => {
  switch (level) {
    case 'error':
      return <ErrorIcon fontSize="large" />
    case 'warning':
      return <WarningIcon fontSize="large" />
    case 'info':
      return <InfoIcon fontSize="large" style={{color: 'blue'}} />
    default:
      return <></>
  }
}

function CardGenericError(props) {
  const {title, description, caption, fullErrorTrace, height, noMargin, level} = props
  const styles = {
    warning: {
      color: '#ff5722',
      backgroundColor: '#ff5722',
    },
    error: {
      color: '#fff',
      backgroundColor: red[400],
    },
    info: {
      color: '#fff176',
      backgroundColor: '#fff',
    },
  }
  const {infoNotification} = useToastNotification()

  return (
    <StyledCard top={noMargin ? '0' : '50px'} height={height} $extraRow={Boolean(fullErrorTrace)}>
      <Avatar style={styles[level]} className="icon" aria-label={level} level={level}>
        <AvatarIcon level={level} />
      </Avatar>
      <Typography variant="h6" className="title">
        {title}
      </Typography>

      <CardContent className="copy">
        <Typography variant="body1" color="textPrimary" component="p">
          {description}
        </Typography>
        {caption && (
          <Typography variant="body2" color="textSecondary" component="p" className="caption">
            {caption}
          </Typography>
        )}
      </CardContent>
      {fullErrorTrace && (
        <CardActions>
          <CopyToClipboard
            text={fullErrorTrace}
            onCopy={() => infoNotification('Detailed error message copied to clipboard')}
          >
            <Button color="primary" size="small">
              copy detailed message to clipboard
            </Button>
          </CopyToClipboard>
        </CardActions>
      )}
    </StyledCard>
  )
}

CardGenericError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  fullErrorTrace: PropTypes.string,
  caption: PropTypes.string,
  height: PropTypes.string,
  noMargin: PropTypes.bool,
  level: PropTypes.string,
}

CardGenericError.defaultProps = {
  caption: '',
  description: '',
  fullErrorTrace: '',
  height: '',
  level: 'error',
  noMargin: false,
}

export default memo(CardGenericError)
