import React from 'react'
import PropTypes from 'prop-types'
import {Button, Paper, Typography} from '@mui/material'
import {
  CloudUploadOutlined as Publish,
  CloudDownloadOutlined as Download,
  Delete,
  Info as InfoIcon,
} from '@mui/icons-material'
import {grey} from '@mui/material/colors'
import styled, {css} from 'styled-components'

import groupingItemCountCopy from '../helpers/groupingItemCountCopy'

const SummaryContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 45px 80px 390px 1fr;
  grid-column-gap: 20px;
  grid-template-rows: 24px 20px 28px;

  .icon {
    display: flex;
    color: var(--mdc-theme-primary);
    justify-self: center;
    align-self: center;

    grid-row: 1 / 4;
    grid-column: 1 / 2;
  }

  .title {
    grid-row: 1 / 2;
    grid-column: 2 / 4;
  }

  .description {
    grid-row: 2 / 3;
    grid-column: 2 / 4;
    color: ${props => props.theme?.palette?.subduedText};
  }

  .entitlements {
    grid-row: 3 / 4;
    grid-column: 2 / 4;
    display: flex;
    justify-content: flex-start;
    .entitlement {
      margin-left: 30px;
    }
  }

  .action {
    grid-row: 3 / 4;
    grid-column: 4 / 5;
    justify-self: flex-end;
  }
`

const Entitlement = styled.div`
  display: flex;

  ${props =>
    props.enabled
      ? css`
          p {
            color: ${() => 'var(--mdc-theme-primary)'};
          }
          .MuiSvgIcon-root {
            color: ${() => 'var(--mdc-theme-primary)'};
          }
        `
      : css`
          p {
            text-decoration: line-through;
            color: ${() => grey['300']};
          }
          .MuiSvgIcon-root {
            color: ${() => grey['300']};
          }
        `}

  p {
    user-select: none;
  }

  .MuiSvgIcon-root {
    margin-right: 10px;
  }

  .cloud-icon {
    position: relative;
    bottom: 2px;
  }

  .delete-icon {
    position: relative;
    bottom: 2px;
    margin-right: 4px;
  }
`

/**
 * The summary of results, entitlements and the description for a given project.
 *
 */
function GallerySummaryProject(props) {
  const {projectName, entitlements, numberItems, description, openSchemaDialog} = props

  // SDK provides no array when no entitlements present
  const anyEntitlements = Array.isArray(entitlements)

  const canDelete = anyEntitlements && entitlements.indexOf('DELETE') !== -1
  const canPublish = anyEntitlements && entitlements.indexOf('PUBLISH') !== -1
  const canDownload = anyEntitlements && entitlements.indexOf('DOWNLOAD') !== -1

  return (
    <SummaryContainer elevation={0}>
      <div className="icon">
        <InfoIcon />
      </div>
      <div className="title">
        <Typography variant="body1">
          {groupingItemCountCopy({grouping: 'project', name: projectName, count: numberItems})}
        </Typography>
      </div>
      <div className="description">
        {description && <Typography variant="body2">{description}</Typography>}
      </div>

      <div className="entitlements">
        <Typography variant="body1">Entitlements:</Typography>
        <Entitlement enabled={canDownload} className="entitlement">
          <Download className="cloud-icon" />
          <Typography variant="body2">Download</Typography>
        </Entitlement>
        <Entitlement enabled={canPublish} className="entitlement">
          <Publish className="cloud-icon" />
          <Typography variant="body2">Publish</Typography>
        </Entitlement>
        <Entitlement enabled={canDelete} className="entitlement">
          <Delete className="delete-icon" />
          <Typography variant="body2">Delete</Typography>
        </Entitlement>
      </div>
      <div className="action">
        <Button color="primary" onClick={openSchemaDialog}>
          View and Select Schema
        </Button>
      </div>
    </SummaryContainer>
  )
}

GallerySummaryProject.propTypes = {
  /** Name of the project */
  projectName: PropTypes.string.isRequired,
  /** Array of entitlements which a user has in the project */
  entitlements: PropTypes.arrayOf(PropTypes.string),
  /** Count of number items in project */
  numberItems: PropTypes.number.isRequired,
  /** Description of project (to be implemented in the future via API/SDK) */
  description: PropTypes.string,
  /** Action that allows the modification of schema element visibility in a gallery */
  openSchemaDialog: PropTypes.func.isRequired,
}

GallerySummaryProject.defaultProps = {
  entitlements: [],
  description: '',
}

export default GallerySummaryProject
