import {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {
  addAvailableWorkflowStages,
  indicateMetadataValidationResults,
  setAvailableWorkflowStages,
} from '../actions/imageUploaderAction'
import useToastNotification from './useToastNotification'
import {handleSentryError} from '../utilities/sentryErrors'

/**
 * Carries out validation process. Sends results to the app state
 * through redux
 *
 * Returns:
 * ready: Used to indicate whether user can validate
 * validate: trigger function
 */
function useImageUploaderValidate() {
  const [collections, setCollections] = useState(null)
  const [validating, setValidating] = useState(false)
  const {selectedCollection, metadataContent} = useSelector(state => state.imageUploader)
  const {errorNotification} = useToastNotification()
  const dispatch = useDispatch()
  const {imagineSdk, retrieveCollections} = useContext(imagineServiceContext)

  /** Retrieve collections on load */
  useEffect(() => {
    async function retrieveStacCollections() {
      const stacCollections = retrieveCollections()
      setCollections(stacCollections)
    }
    retrieveStacCollections()
  }, [])

  /** Engages validation process */
  useEffect(() => {
    async function validateMetadata() {
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})

      const propertiesToValidate = {}

      Object.entries(metadataContent.properties).forEach(([key, value]) => {
        if (!(Array.isArray(value) && value.length === 0)) {
          propertiesToValidate[key] = value
        }
      })

      try {
        const collectionSchema = await collection.itemSchema()

        const validationResults = await collectionSchema.validateProperties({
          ...metadataContent.properties,
          ...propertiesToValidate,
        })

        dispatch(indicateMetadataValidationResults(validationResults))

        if (!validationResults.length) {
          dispatch(addAvailableWorkflowStages([1, 2, 3]))
        } else {
          setAvailableWorkflowStages([1, 2])
        }
        setValidating(false)
      } catch (e) {
        const additionalErrorDetails =
          'Unable to validate data due to an error. Please contact Location360 Support.'
        handleSentryError(e, additionalErrorDetails)
        errorNotification(e.message)
        setValidating(false)
      }
    }

    if (validating) validateMetadata()
  }, [validating])

  /** Triggers validation */
  function validate() {
    setValidating(true)
  }

  const ready = !!collections && !validating && !!selectedCollection

  return {ready, validate}
}

export default useImageUploaderValidate
