import React from 'react'
import PropTypes from 'prop-types'
import {FormControl, InputLabel, MenuItem, Select, FormHelperText} from '@mui/material/'

function UploaderOrthoMosaicMetadataFieldBoolean(props) {
  const {errorLevel, errorMessage, helperText, label, reactRef, setValue, value} = props

  return (
    <FormControl error={errorLevel === 'error'} ref={reactRef}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={String(value)}
        onChange={event => {
          setValue(event.target.value === 'true')
        }}
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
      {errorLevel === 'error' ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

UploaderOrthoMosaicMetadataFieldBoolean.propTypes = {
  errorLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.bool,
}

UploaderOrthoMosaicMetadataFieldBoolean.defaultProps = {
  errorLevel: null,
  errorMessage: '',
  helperText: '',
  value: false,
}

export default UploaderOrthoMosaicMetadataFieldBoolean
