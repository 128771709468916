import React, {memo, useState} from 'react'
import styled from 'styled-components'

import AdvancedSearchSelectorCollectionPropertiesForm from './AdvancedSearchSelectorCollectionPropertiesForm'
import SearchSelectedAttribute from './SearchSelectedAttribute'
import useAdvancedSearch from '../hooks/useAdvancedSearch'

const CollectionPropertiesSearch = styled.div`
  .selection-chip {
    margin: 3px;
  }
`

function AdvancedSearchSelectorCollectionProperties() {
  const [sectionWarning, setSectionWarning] = useState(() => <></>)

  const {
    setCollectionProperties,
    searchAttributes: {properties: selectedProperties},
  } = useAdvancedSearch()

  function removeProperty(index) {
    const value = [...selectedProperties]
    value.splice(index, 1)
    setCollectionProperties(value)
  }

  return (
    <CollectionPropertiesSearch>
      <AdvancedSearchSelectorCollectionPropertiesForm setSectionWarning={setSectionWarning} />

      <div className="selected-criteria">
        {selectedProperties.map(({property, comparison, value}, index) => (
          <SearchSelectedAttribute
            className="selection-chip"
            key={`search-property-chip-${index}`}
            property={property}
            operator={comparison}
            value={value}
            onDelete={() => removeProperty(index)}
          />
        ))}
      </div>

      <>{sectionWarning}</>
    </CollectionPropertiesSearch>
  )
}

export default memo(AdvancedSearchSelectorCollectionProperties)
