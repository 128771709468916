import React from 'react'
import {Button} from '@mui/material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'

/*
  Contains the button for triggering a search as well as the logic
  that determines whether it should be disabled.

*/
function AdvancedSearchExecuteButton() {
  const {searchQueryString, triggerAdvancedSearch, toggleDrawer} = useAdvancedSearch()

  const triggerRedirect = () => {
    toggleDrawer()
    triggerAdvancedSearch()
  }

  return (
    <Button
      icon="search"
      color="primary"
      onClick={triggerRedirect}
      disabled={searchQueryString === ''}
      id="test-e2e-trigger-advanced-search"
    >
      Search
    </Button>
  )
}

export default AdvancedSearchExecuteButton
