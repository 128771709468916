import React, {useContext} from 'react'
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material'
import {Link} from 'react-router-dom'

import {itemContext} from '../context/itemContext'

export default function ItemMetadataAlbums() {
  const context = useContext(itemContext)
  const {
    itemMetadata: {albums},
  } = context

  if (!albums || albums.length === 0) return <></>

  return (
    <>
      <Typography variant="h5" color="primary" className="section-title">
        Albums
      </Typography>

      <TableContainer>
        <Table style={{minWidth: 450}} aria-label="simple table">
          <TableBody>
            {albums.map((album, index) => (
              <TableRow key={`album-row-${album.name}-${index}`}>
                <TableCell style={{padding: 16}} component="th" scope="row">
                  <Link to={`/album/${album.name}`}>{album.title}</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
