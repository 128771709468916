import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CardGenericError from './CardGenericError'

const ErrorCardContainer = styled('section')`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

/**
 * Shared error card across all gallery view variants
 */
function GalleryViewErrorCard(props) {
  const {title, description, level} = props
  return (
    <ErrorCardContainer>
      <CardGenericError title={title} caption={description} level={level} noMargin />
    </ErrorCardContainer>
  )
}

GalleryViewErrorCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  level: PropTypes.string,
}

GalleryViewErrorCard.defaultProps = {
  level: 'error',
}

export default GalleryViewErrorCard
