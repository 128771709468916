import {useContext, useEffect} from 'react'
import {toastNotificationContext} from '../context/toastNotificationContext'

const timeout = 10000

function useToastNotification() {
  const {toastState, setToastState} = useContext(toastNotificationContext)

  const resetNotification = () => setToastState({...toastState, message: '', open: false})

  useEffect(() => {
    if (toastState.open && toastState.timeout) {
      const tm = setTimeout(resetNotification, toastState.timeout)
      return () => clearTimeout(tm)
    }
  }, [toastState])

  const errorNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'error', ...options})
  }
  const warningNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'warning', ...options})
  }

  const infoNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'info', ...options})
  }

  const successNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'success', ...options})
  }

  return {
    errorNotification,
    warningNotification,
    infoNotification,
    successNotification,
    resetNotification,
    toastState,
  }
}

type NotificationOptions = {timeout?: number}

export default useToastNotification
